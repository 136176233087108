
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { NHint, NTag, NDateTimeLabel } from '@/uikit';
import { RemoteMonitoringRecord } from '@/api/models/RemoteMonitoringRecord';

@Options({
  name: 'CardMonitoringStatus',
  components: { NHint, NTag, NDateTimeLabel }
})
export default class CardMonitoringStatus extends Vue {
  @Prop({ type: Object, required: true })
  readonly item!: RemoteMonitoringRecord;
}
