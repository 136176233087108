import { IFormLayout, IFormLayoutItem, IFormLayoutRow, IFormOptionalLayout } from '@/uikit/forms/NForm.vue';

type IFormOptionalLayoutItem = IFormLayoutItem | null | undefined;

export function findLayoutItemByPath(layout: IFormLayout | IFormOptionalLayout, name: string): IFormOptionalLayoutItem {
  function reduceItems(m: IFormOptionalLayoutItem, v: IFormLayoutRow | null): IFormOptionalLayoutItem {
    let result: IFormOptionalLayoutItem = m;

    if (!result) {
      if (Array.isArray(v)) {
        result = findLayoutItemByPath(v, name);
      } else if (v && (v.path === name || v.name === name)) {
        result = v as any as IFormLayoutItem;
      }
    }
    return result;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return layout.reduce<IFormOptionalLayoutItem>(reduceItems, undefined);
}
