
import { viewModelRepository } from '@/api/common';
import NRadio from '@/uikit/radio/NRadio.vue';
import NTable, { ITableColumn, ITableModel, ITableRow } from '@/uikit/table/NTable.vue';
import NTableColumnCell from '@/uikit/table/NTableColumnCell.vue';
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import HeaderBar from '@/components/common/HeaderBar.vue';
import { ItemsActionNames } from '@/definitions/app/item.actions.name';
import { MultisidebarItemTypes } from '@/store/multisidebar/types';
import { actionHandler } from '@/store/data/ActionHandler';
import { NButton } from '@/uikit';

@Options({
  name: 'GroupCameraGroups',
  components: { HeaderBar, NRadio, NTable }
})
export default class GroupCameraGroups extends Vue {
  @Prop({ type: Number, required: true })
  readonly modelValue!: number;

  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean;

  columns: ITableColumn[] = [
    {
      header: {
        classes: ['bottom-border'],
        component: NTableColumnCell,
        props: { i18n_label: 'common.column_name' }
      },
      content: {
        path: 'name',
        component: NButton,
        props: (item: any) => ({ label: item.name, type: 'link', align: 'left' }),
        on: {
          click(this: any) {
            actionHandler.run(ItemsActionNames.ShowItem, { type: MultisidebarItemTypes.CameraGroups, rawItem: this.item.id });
          }
        }
      }
    },
    {
      width: '100px',
      header: {
        classes: ['align-center', 'bottom-border'],
        component: NTableColumnCell,
        props: { i18n_label: 'common.column_off' }
      },
      content: {
        path: 'permission',
        classes: ['align-center'],
        component: NRadio,
        props: (item: any) => ({ name: 'group_' + item.id, selectValue: 'off' })
      }
    },
    {
      width: '100px',
      header: {
        classes: ['align-center', 'bottom-border'],
        component: NTableColumnCell,
        props: { i18n_label: 'common.column_view' }
      },
      content: {
        path: 'permission',
        classes: ['align-center'],
        component: NRadio,
        props: (item: any) => ({ name: 'group_' + item.id, selectValue: 'view' })
      }
    },
    {
      width: '100px',
      header: {
        classes: ['align-center', 'bottom-border'],
        component: NTableColumnCell,
        props: { i18n_label: 'common.column_change' }
      },
      content: {
        path: 'permission',
        classes: ['align-center'],
        component: NRadio,
        props: (item: any) => ({ name: 'group_' + item.id, selectValue: 'edit' })
      }
    }
  ];
  header: ITableRow = { checked_all: false };
  content: ITableModel = [];
  searchText = '';

  readonly module = viewModelRepository.getPermissionsCameraGroupsListViewModel();

  get offCount() {
    return this.cameraGroups.filter((v) => v['permission'] === 'off').length;
  }

  get cameraGroups() {
    return this.content.filter((item: any) => item.name.toLowerCase().includes(this.searchText));
  }

  async actionHandler({ type, row, path, value }: { type: string; row: any; path: string; value: any }) {
    if (type === 'change' && path === 'permission') {
      value = value === 'off' ? null : value;
      if (!row.permissions.toString().includes(this.modelValue.toString())) Object.assign(row.permissions, { [this.modelValue]: value });
      await this.module.update(row);
      await this.module.get({ resetState: false });
    }
  }

  async mounted() {
    this.module.filter.current.limit = 1000;
    await this.module.get();
    this.updateTable();
  }

  @Watch('modelValue')
  updateTable() {
    this.content = this.module.items.map((item) => ({
      ...item,
      permission: item.permissions && item.permissions[this.modelValue] ? item.permissions[this.modelValue] : 'off'
    }));
  }
}
