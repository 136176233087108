import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "vms-sidebar-cameras-tab-filters" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NInput = _resolveComponent("NInput")!
  const _component_VmsSidebarCamerasTabFiltersCameraGroup = _resolveComponent("VmsSidebarCamerasTabFiltersCameraGroup")!
  const _component_VmsSidebarCamerasTabFiltersStatus = _resolveComponent("VmsSidebarCamerasTabFiltersStatus")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_NInput, {
      placeholder: _ctx.$t('common.search', 'f'),
      class: "vms-sidebar-cameras-tab-filters__name-contains",
      "suffix-icon": "search",
      modelValue: _ctx.model.nameContains,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.nameContains) = $event))
    }, null, 8, ["placeholder", "modelValue"]),
    _createVNode(_component_VmsSidebarCamerasTabFiltersCameraGroup, {
      "camera-groups": _ctx.cameraGroups,
      class: "vms-sidebar-cameras-tab-filters__camera-group",
      modelValue: _ctx.model.groupContains,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.groupContains) = $event))
    }, null, 8, ["camera-groups", "modelValue"]),
    _createVNode(_component_VmsSidebarCamerasTabFiltersStatus, {
      class: "vms-sidebar-cameras-tab-filters__status",
      modelValue: _ctx.model.added,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.added) = $event))
    }, null, 8, ["modelValue"])
  ]))
}