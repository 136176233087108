
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import NForm from '@/uikit/forms/NForm.vue';
import NTabs from '@/uikit/tabs/NTabs.vue';
import MultisidebarHeader from '@/components/multisidebar/MultisidebarHeader.vue';
import { MultisidebarItem } from '@/store/multisidebar/types';
import { ModelAclResult } from '@/store/acl/types';
import { configModule } from '@/store/config';
import { Action } from '@/uikit';
import { actionsModule } from '@/store/data/ActionsModule';
import UserForm from '@/pages/users/form/UserForm.vue';
import UserFaces from '@/pages/users/form/UserFaces.vue';
import MultisidebarSaveActions from '@/components/multisidebar/MultisidebarSaveActions.vue';
import { ItemViewModel } from '@/definitions/view-models';
import { User } from '@/api';
import { actionHandler } from '@/store/data/ActionHandler';
import { ItemsActionName } from '@/definitions/app/item.actions.name';

const Tabs = {
  Information: 'information',
  Photos: 'photos'
};

@Options({
  name: 'UsersSidebar',
  components: {
    MultisidebarSaveActions,
    UserFaces,
    UserForm,
    NForm,
    NTabs,
    MultisidebarHeader
  }
})
export default class UsersSidebar extends Vue {
  @Prop({ type: Object, required: true })
  readonly sidebarItem!: MultisidebarItem<ItemViewModel<User>>;

  @Prop({ type: Object, required: true })
  readonly modelAcl!: ModelAclResult;

  activeTab = Tabs.Information;

  get item() {
    return this.sidebarItem.model.item;
  }

  get isNew() {
    return this.sidebarItem.model.isNew;
  }

  get tabItems() {
    const infoTab = {
      name: Tabs.Information,
      label: this.$t(`common.${Tabs.Information}`, 'f')
    };
    const photoTab = {
      name: Tabs.Photos,
      label: this.$t(`common.${Tabs.Photos}`, 'f')
    };

    return this.isAdUser ? [infoTab] : [infoTab, photoTab];
  }

  get isAdUser() {
    return configModule.config.services.ffsecurity.active_directory && this.item?.ad_user;
  }

  get headerActions(): Action[] {
    return actionsModule
      .getItemActions(this.modelAcl as any, null, {
        hasChanges: this.sidebarItem.model?.hasChanges,
        hasActive: true,
        currentActive: this.item?.active,
        hasDelete: !this.isAdUser
      })
      .map(actionsModule.computeActionByName);
  }

  actionHandler(actionName: ItemsActionName, payload: any) {
    actionHandler.run(actionName, payload);
  }
}
