import { ApiRequestOptions } from '@/api/core/ApiRequestOptions';
import { request } from '@/api/core/request';
import { isStringWithValue } from '@/common/utils';
import NLoadingCircle from '@/uikit/loading/NLoadingCircle.vue';
import { nextTick, onBeforeUnmount, ref, Ref, unref, VNode, watch } from 'vue';

type State = {
  readonly content: () => VNode;
  readonly dispose: () => void;
};

const pass = () => {};

export function useVmsSidebarCamerasTabListItemScreenshotViewModel(screenshot: Ref<string>) {
  const state = ref<State>(createScreenshotDefaultState());
  const apply = (next: State) => (state.value = (unref(state).dispose(), next));

  const unwatch = watch(
    screenshot,
    (screenshot) => nextTick(() => isStringWithValue(screenshot) && apply(createScreenshotDownloadInProgressState(screenshot))),
    { immediate: true }
  );

  onBeforeUnmount(() => (unwatch(), unref(state).dispose()));

  function createScreenshotDefaultState(): State {
    function content(): VNode {
      return <div class="vms-sidebar-cameras-tab-list-item-screenshot"></div>;
    }

    return { content, dispose: pass };
  }

  function createScreenshotDownloadCompleteState(screenshot: Blob): State {
    const src = URL.createObjectURL(screenshot);

    function content(): VNode {
      return <img class="vms-sidebar-cameras-tab-list-item-screenshot vms-sidebar-cameras-tab-list-item-screenshot_complete" src={src} />;
    }

    function dispose(): void {
      URL.revokeObjectURL(src);
    }

    return { content, dispose };
  }

  function createScreenshotDownloadInProgressState(screenshot: string): State {
    const response = request(computeApiRequestOptionsFromScreenshotUrl(screenshot));
    response.then((screenshot) => apply(createScreenshotDownloadCompleteState(screenshot as Blob))).catch(() => apply(createScreenshotDefaultState()));

    function content(): VNode {
      return (
        <div class="vms-sidebar-cameras-tab-list-item-screenshot vms-sidebar-cameras-tab-list-item-screenshot_in-progress">
          <NLoadingCircle class="vms-sidebar-cameras-tab-list-item-screenshot__progress-bar" />
        </div>
      );
    }

    function dispose(): void {
      response.cancel();
    }

    return { content, dispose };
  }

  return () => unref(state).content();
}

function computeApiRequestOptionsFromScreenshotUrl(screenshot: string): ApiRequestOptions {
  const parsed = new URL(screenshot);
  const params = [...parsed.searchParams].reduce<Record<string, unknown>>((params, [key, value]) => (Reflect.set(params, key, value), params), {});
  return { method: 'GET', path: parsed.pathname, query: params, responseType: 'blob' };
}
