
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import NTextareaAutosize from '@/uikit/textarea/NTextareaAutosize.vue';
import NTooltip from '@/uikit/hint/NTooltip.vue';

@Options({
  name: 'WebhooksFiltersEditorJson',
  components: { NTooltip, NTextareaAutosize },
  emits: ['update:modelValue']
})
export default class WebhooksFiltersEditorJson extends Vue {
  @Prop({ type: Object, default: () => ({}) })
  readonly modelValue!: Record<string, any>;

  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean;

  private isInvalid = false;
  get modelValueString() {
    return this.modelValue && typeof this.modelValue === 'object' ? JSON.stringify(this.modelValue, null, '\t') : '{}';
  }

  dispatchModelValueUpdate(value: string) {
    this.isInvalid = false;
    try {
      const jsonValue = JSON.parse(value);
      this.$emit('update:modelValue', jsonValue);
    } catch (e) {
      this.isInvalid = true;
    }
  }
}
