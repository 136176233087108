import { IFormLayout } from '@/uikit/forms/NForm.vue';
import { getNumberValidator } from '@/uikit/forms/validators';
import { NInputNumber } from '@/uikit';

const layout: IFormLayout = [
  [
    {
      path: 'latitude',
      classes: 'n-form-w-150 n-form-pad-10',
      i18n_label: 'ds.latitude',
      component: NInputNumber,
      props: { units: '°', textAlign: 'right', step: 0.1, min: -90, max: 90 },
      validators: [getNumberValidator({ required: false, min: -90, max: 90, float: true })]
    },
    {
      path: 'longitude',
      classes: 'n-form-w-150 n-form-pad-10',
      i18n_label: 'ds.longitude',
      component: NInputNumber,
      props: { units: '°', textAlign: 'right', step: 0.1, min: -180, max: 180 },
      validators: [getNumberValidator({ required: false, min: -180, max: 180, float: true })]
    },
    {
      path: 'azimuth',
      classes: 'n-form-w-150 n-form-pad-10',
      i18n_label: 'ds.azimuth',
      component: NInputNumber,
      props: { units: '°', textAlign: 'right', step: 0.1, min: 0, max: 359.9 },
      validators: [getNumberValidator({ required: false, min: 0, max: 359.9, float: true })]
    }
  ]
];

export default layout;
