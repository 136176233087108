import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NForm = _resolveComponent("NForm")!

  return (_openBlock(), _createBlock(_component_NForm, {
    layout: _ctx.layout,
    model: _ctx.item,
    ref: "itemForm"
  }, null, 8, ["layout", "model"]))
}