import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "card-form-first-objects" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NThumbnail = _resolveComponent("NThumbnail")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, i) => {
      return (_openBlock(), _createElementBlock(_Fragment, null, [
        (item?.thumbnail)
          ? (_openBlock(), _createBlock(_component_NThumbnail, {
              key: i,
              class: "card-form-first-objects__item",
              modelValue: item.thumbnail,
              onClick: ($event: any) => (_ctx.showFullscreen(item))
            }, null, 8, ["modelValue", "onClick"]))
          : _createCommentVNode("", true)
      ], 64))
    }), 256))
  ]))
}