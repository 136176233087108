
import { defineComponent, nextTick, onBeforeUnmount, PropType, reactive, toRefs } from 'vue';
import { NButton, NButtonType, NModalWindow } from '@/uikit';
import { createVideoStream, disposeVideoStream, getCameras, getIsUserAgentSupportVideoRecording } from '@/components/common/media-devices-helpers';
import { NButtonTypes } from '@/uikit/buttons/types';

export default defineComponent({
  name: 'CameraShotAttach',
  components: { NButton, NModalWindow },
  props: {
    icon: { type: String, default: 'photo' },
    type: { type: String as PropType<NButtonType>, default: NButtonTypes.Secondary },
    i18n_label: { type: String, default: '' }
  },
  setup(props, { emit }) {
    const state = reactive({
      stream: null as null | MediaStream,
      video: null as null | HTMLVideoElement,
      canvas: null as null | HTMLCanvasElement,
      context: null as null | CanvasRenderingContext2D,
      evaluating: false,
      isCameraDialogEnabled: false,
      hasCamera: true,
      cameraShot: null as null | Blob
    });

    getCameras().then((v) => (state.hasCamera = Boolean(v.length)));

    async function openCameraDialog() {
      try {
        if (getIsUserAgentSupportVideoRecording()) {
          state.stream = await createVideoStream();
          state.isCameraDialogEnabled = true;
          await nextTick();
          if (state.video && state.canvas) {
            state.video.srcObject = state.stream;
            state.context = state.canvas.getContext('2d');
            state.video.addEventListener('loadedmetadata', handleMetadataLoad);
          }
        }
      } catch (e) {
        console.warn(e);
      }
    }

    function handleMetadataLoad() {
      if (state.canvas && state.video) {
        state.canvas.width = state.video.videoWidth;
        state.canvas.height = state.video.videoHeight;
      }
    }

    function closeCameraDialog() {
      state.evaluating = false;
      state.isCameraDialogEnabled = false;
      closeStream();
    }

    function closeStream() {
      state.stream && disposeVideoStream(state.stream);
      state.stream = null;
    }

    onBeforeUnmount(destroy);
    function destroy() {
      closeStream();
      state.video && state.video.removeEventListener('loadedmetadata', handleMetadataLoad);
    }

    function capture() {
      let video = state.video;
      let canvas = state.canvas;
      let context = state.context;
      if (video && context && canvas) {
        context.fillRect(0, 0, canvas.width, canvas.height);
        context.drawImage(video, 0, 0, canvas.width, canvas.height);
        canvas.toBlob((blob) => {
          state.cameraShot = blob;
          state.evaluating = true;
        });
      }
    }

    function getShotAsFile() {
      if (state.cameraShot) {
        let filename = Math.random().toString().slice(2, 8) + '.png',
          file = new File([state.cameraShot], filename, { type: state.cameraShot.type, lastModified: Date.now() });
        return file;
      }
    }

    function attach() {
      emit('attach', getShotAsFile());
      closeCameraDialog();
    }

    return {
      ...toRefs(state),
      openCameraDialog,
      closeCameraDialog,
      capture,
      attach
    };
  }
});
