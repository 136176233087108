import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "permissions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderBar = _resolveComponent("HeaderBar")!
  const _component_GroupAccessPermissions = _resolveComponent("GroupAccessPermissions")!
  const _component_GroupCommonPermissions = _resolveComponent("GroupCommonPermissions")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HeaderBar, {
      "search-text": _ctx.searchText,
      "onUpdate:search-text": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchText) = $event))
    }, {
      count: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.totalCount) + " " + _toDisplayString(_ctx.$t('common.total')) + ", " + _toDisplayString(_ctx.totalCount - _ctx.grantedCount) + " " + _toDisplayString(_ctx.$t('common.off')), 1)
      ]),
      _: 1
    }, 8, ["search-text"]),
    _createVNode(_component_GroupAccessPermissions, {
      groups: _ctx.access,
      "is-blocked": _ctx.isBlocked,
      "is-granted": _ctx.isGranted,
      disabled: _ctx.disabled,
      onToggle: _ctx.toggle
    }, null, 8, ["groups", "is-blocked", "is-granted", "disabled", "onToggle"]),
    _createVNode(_component_GroupCommonPermissions, {
      groups: _ctx.common,
      "is-blocked": _ctx.isBlocked,
      "is-granted": _ctx.isGranted,
      disabled: _ctx.disabled,
      onToggle: _ctx.toggle
    }, null, 8, ["groups", "is-blocked", "is-granted", "disabled", "onToggle"])
  ]))
}