
import { nextTick, reactive } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';
import { languageModule } from '@/store/languages';
import NButtonGroup from '@/uikit/buttons/NButtonGroup.vue';
import NRadio from '@/uikit/radio/NRadio.vue';

@Options({
  name: 'DataSourceType',
  components: { NRadio, NButtonGroup }
})
export default class DataSourceType extends Vue {
  @Prop({ type: Boolean, default: false })
  readonly disabled?: boolean;

  @Prop({ type: String, required: true })
  readonly modelValue!: string;

  get model() {
    return this.modelValue;
  }

  set model(value) {
    this.$emit('update:modelValue', value);
  }

  get items() {
    return [
      { value: 'camera', label: this.$t('lines.camera') },
      { value: 'video_archive', label: this.$t('lines.video') }
    ];
  }
}
