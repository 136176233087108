
import { nextTick, reactive } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';

@Options({
  name: 'FileSize'
})
export default class FileSize extends Vue {
  @Prop({ type: Number, required: true })
  readonly modelValue!: number;

  get formattedFileSize() {
    // TODO: использовать функцию из фильтров после мерженга ветки с видео плеером
    return Math.round(this.modelValue / 1024 / 1024) + ' Mb';
  }
}
