import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "user-roles" }
const _hoisted_2 = {
  key: 0,
  class: "user-roles__add"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NTable = _resolveComponent("NTable")!
  const _component_NSelect = _resolveComponent("NSelect")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_NTable, {
      columns: _ctx.columns,
      content: _ctx.embeddedUserGroups,
      hover: false,
      onAction: _ctx.handleTableAction
    }, null, 8, ["columns", "content", "onAction"]),
    (_ctx.groupAddItems.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (!_ctx.item.ad_user)
            ? (_openBlock(), _createBlock(_component_NSelect, {
                key: 0,
                "model-value": [],
                placeholder: _ctx.$t('common.add_role', 'f'),
                items: _ctx.groupAddItems,
                "fill-value-on-select": false,
                selectonly: "",
                "onUpdate:modelValue": _ctx.addGroup
              }, null, 8, ["placeholder", "items", "onUpdate:modelValue"]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}