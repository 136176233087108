import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LinesROI = _resolveComponent("LinesROI")!
  const _component_NModalWindow = _resolveComponent("NModalWindow")!

  return (_openBlock(), _createBlock(_component_NModalWindow, {
    "has-margins": false,
    layout: "",
    customSize: "90vw",
    "class-name": "lines-roi-modal"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_LinesROI, {
        model: _ctx.model,
        disabled: _ctx.disabled
      }, null, 8, ["model", "disabled"])
    ]),
    _: 1
  }))
}