
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Counter } from '@/api';
import CounterROI from './CounterROI.vue';
import NModalWindow from '@/uikit/modal-window/NModalWindow.vue';
import NButton from '@/uikit/buttons/NButton.vue';

@Options({
  name: 'CounterROIModal',
  components: { NButton, NModalWindow, CounterROI }
})
export default class CounterROIModal extends Vue {
  @Prop({ type: Object, required: true })
  readonly model!: Counter;

  @Prop({ type: Number, required: true })
  readonly cameraStartIndex!: number;

  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean;

  cameraIndex = this.cameraStartIndex;

  get cameraId() {
    return this.model.cameras[this.cameraIndex];
  }

  get itemsCount() {
    return this.model.cameras.length;
  }

  get hasArrows() {
    return this.itemsCount > 1;
  }

  mounted() {
    this.bindArrowsHandler();
  }

  beforeUnmount() {
    this.unbindArrowsHandler();
  }

  next() {
    this.cameraIndex = this.cameraIndex === this.itemsCount - 1 ? 0 : this.cameraIndex + 1;
  }

  prev() {
    this.cameraIndex = this.cameraIndex === 0 ? this.itemsCount - 1 : this.cameraIndex - 1;
  }

  bindArrowsHandler() {
    document.addEventListener('keyup', this.handleArrow.bind(this));
  }

  unbindArrowsHandler() {
    document.removeEventListener('keyup', this.handleArrow.bind(this));
  }

  handleArrow(e: KeyboardEvent) {
    if (e.key === 'ArrowLeft') this.prev();
    if (e.key === 'ArrowRight') this.next();
  }
}
