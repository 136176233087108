import { AppContext, render } from 'vue';
import { func } from 'vue-types';
import { CameraLookupWindow } from './index';
import { CameraLookupAuthenticator, CameraLookupModel, CameraLookupSource } from './types';

export function createCameraLookupDialog(context: AppContext | null) {
  function renderCameraLookupDialog(authenticator: CameraLookupAuthenticator, source: CameraLookupSource): Promise<CameraLookupModel> {
    return new Promise(function (resolve) {
      const container = document.body.appendChild(document.createElement('div'));
      const component = <CameraLookupWindow authenticator={authenticator} onClose={destroy} onSelect={handleSelectEvent} source={source} />;
      render(((component.appContext = context), component), container);

      function destroy(): void {
        render(null, document.body.removeChild(container));
      }

      function handleSelectEvent(camera: CameraLookupModel): void {
        resolve(camera), destroy();
      }
    });
  }

  return renderCameraLookupDialog;
}

export function createIgnoredCaseStringMatcher(substring: string): (str: string) => boolean {
  const regexp = new RegExp(substring, 'gi');
  return (str) => regexp.test(str);
}

export function defineAuthenticatorProp() {
  return func<CameraLookupAuthenticator>().isRequired;
}

export function defineSourceProp() {
  return func<CameraLookupSource>().isRequired;
}
