import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "vms-sidebar-cameras-tab-filters-camera-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NSelect = _resolveComponent("NSelect")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_NSelect, {
      items: _ctx.items,
      "model-value": _ctx.modelValue,
      "onUpdate:modelValue": _ctx.dispatchUpdateModelValueEvent,
      selectonly: ""
    }, null, 8, ["items", "model-value", "onUpdate:modelValue"])
  ]))
}