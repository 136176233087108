import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "lines-roi" }
const _hoisted_2 = { class: "lines-roi__actions" }
const _hoisted_3 = { class: "lines-roi__grow" }
const _hoisted_4 = { class: "lines-roi__content" }
const _hoisted_5 = { class: "lines-roi__desc label-m" }
const _hoisted_6 = { class: "lines-roi__desc label-m" }
const _hoisted_7 = {
  key: 1,
  class: "lines-roi__no-camera"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButton = _resolveComponent("NButton")!
  const _component_ImageLoadError = _resolveComponent("ImageLoadError")!
  const _component_NLoadingCircle = _resolveComponent("NLoadingCircle")!
  const _component_NDraw = _resolveComponent("NDraw")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.cameraName)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.cameraName), 1),
            _createVNode(_component_NButton, {
              onAction: _cache[0] || (_cache[0] = ($event: any) => (_ctx.loadCameraImage(true))),
              icon: "refresh",
              disabled: _ctx.loading,
              type: "secondary"
            }, null, 8, ["disabled"]),
            (_ctx.hasFullscreen)
              ? (_openBlock(), _createBlock(_component_NButton, {
                  key: 0,
                  onAction: _ctx.dispatchShowModal,
                  icon: "fullscreen",
                  type: "secondary"
                }, null, 8, ["onAction"]))
              : _createCommentVNode("", true),
            _createVNode(_component_NButton, {
              onAction: _ctx.clearRect,
              disabled: _ctx.disabled,
              type: "secondary"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('counters.clear')), 1)
              ]),
              _: 1
            }, 8, ["onAction", "disabled"])
          ]),
          _createElementVNode("div", _hoisted_4, [
            (_ctx.error)
              ? (_openBlock(), _createBlock(_component_ImageLoadError, {
                  key: 0,
                  class: "lines-roi__content-error",
                  onLoad: _ctx.loadCameraImage
                }, null, 8, ["onLoad"]))
              : (_ctx.loading)
                ? (_openBlock(), _createBlock(_component_NLoadingCircle, { key: 1 }))
                : (_ctx.loaded)
                  ? (_openBlock(), _createBlock(_component_NDraw, {
                      key: 2,
                      ref: "drawTool",
                      mode: "line",
                      onlyRect: false,
                      image: _ctx.imageDataUrl,
                      points: _ctx.points,
                      disabled: _ctx.disabled,
                      onChange: _cache[1] || (_cache[1] = (v) => (_ctx.points = v))
                    }, null, 8, ["image", "points", "disabled"]))
                  : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('lines.roi_desc_1')), 1),
          _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('lines.roi_desc_2')), 1)
        ], 64))
      : (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.$t('lines.no_camera')), 1))
  ]))
}