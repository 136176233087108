
import NSelect from '@/uikit/select/NSelect.vue';
import type { ISelectItem } from '@/uikit/select/types';
import { defineComponent } from 'vue';
import { string } from 'vue-types';

export default defineComponent({
  name: 'VmsSidebarCamerasTabFiltersStatus',
  components: { NSelect },
  props: {
    modelValue: string().isRequired
  },
  emits: ['update:modelValue'],
  computed: {
    items(): ISelectItem[] {
      return [
        { value: '', label: this.$t('external_vms.any_status') },
        { value: 'True', label: this.$t('external_vms.added') },
        { value: 'False', label: this.$t('external_vms.not_added') }
      ];
    }
  },
  methods: {
    dispatchUpdateModelValueEvent(modelValue: string): void {
      this.$emit('update:modelValue', modelValue);
    }
  }
});
