import { IFormLayoutItem } from '@/uikit/forms/NForm.vue';
import NInput from '@/uikit/input/NInput.vue';
import GroupADSelect from './GroupADSelect.vue';
import { Group } from '@/api';

export const nameFromItem: IFormLayoutItem = {
  path: 'name',
  classes: 'n-form-w-3 n-form-pad-10',
  i18n_label: 'common.name',
  component: NInput
};

export const adGroupFromItem: IFormLayoutItem = {
  classes: 'n-form-w-3 n-form-pad-10 n-form-block-start',
  i18n_label: 'common.ad_group',
  component: GroupADSelect,
  props: (item: Group) => ({ item })
};
