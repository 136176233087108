
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import FiltersSmall from '@/components/common/filter/FiltersSmall.vue';
import FiltersBig from '@/components/common/filter/FiltersBig.vue';
import NButtonGroup from '@/uikit/buttons/NButtonGroup.vue';
import { EditorSection, EditorSections } from '@/pages/webhooks/components/types';
import NForm, { IFormLayout } from '@/uikit/forms/NForm.vue';
import { cloneDeep, difference } from 'lodash';
import NModalWindow from '@/uikit/modal-window/NModalWindow.vue';
import { NButton } from '@/uikit';
import WebhooksFiltersDivider from '@/pages/webhooks/components/WebhooksFiltersDivider.vue';
import { isSectionAvailable } from '@/pages/webhooks/components/utils';

@Options({
  name: 'WebhooksFiltersEditorVisual',
  components: { WebhooksFiltersDivider, NButton, NModalWindow, NForm, NButtonGroup, FiltersBig, FiltersSmall },
  emits: ['reset', 'close', 'update:modelValue']
})
export default class WebhooksFiltersEditorVisual extends Vue {
  @Prop({ type: Object, default: () => ({}) })
  readonly modelValue!: Record<string, any>;

  @Prop({ type: Object, required: true })
  readonly formLayout!: Record<EditorSection, IFormLayout>;

  private model: Record<string, any> = {};

  get activeSections() {
    return Object.keys(this.model).filter(isSectionAvailable);
  }

  get sectionItems() {
    return Object.values(EditorSections)
      .filter(isSectionAvailable)
      .map((v) => {
        return {
          value: v,
          label: this.$t(`webhooks.section_${v}`, 'f')
        };
      });
  }

  @Watch('modelValue', { deep: true, immediate: true })
  syncModelValue() {
    this.model = cloneDeep(this.modelValue);
  }

  toggleActiveSection(sections: EditorSection[]) {
    const [sectionOff] = difference(this.activeSections, sections);
    const [sectionOn] = difference(sections, this.activeSections);
    if (sectionOff) delete this.model[sectionOff];
    if (sectionOn) this.model[sectionOn] = {};
  }

  submit() {
    this.$emit('update:modelValue', this.model);
    this.$emit('close');
  }

  resetSection(v: EditorSection) {
    this.model[v] = {};
  }
}
