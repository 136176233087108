import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VideoPlayer = _resolveComponent("VideoPlayer")!
  const _component_CameraScreenshot = _resolveComponent("CameraScreenshot")!
  const _component_NButton = _resolveComponent("NButton")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.item.active)
      ? (_openBlock(), _createBlock(_component_VideoPlayer, {
          key: 0,
          class: "video-processing-player__video",
          model: "videos",
          cameraId: _ctx.item.id,
          "time-from": 0,
          "show-controls": false,
          "show-timeline": false,
          "show-bottom-bar": false
        }, null, 8, ["cameraId"]))
      : (_openBlock(), _createBlock(_component_CameraScreenshot, {
          key: 1,
          "model-value": _ctx.item.screenshot,
          width: "520",
          displayWidth: "520px"
        }, null, 8, ["model-value"])),
    _createVNode(_component_NButton, {
      class: "video-processing-player__navigate-button",
      onAction: _ctx.navigateToVideoEvents,
      type: "link"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('ds.open_video_events')), 1)
      ]),
      _: 1
    }, 8, ["onAction"])
  ]))
}