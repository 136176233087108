import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "camera-shot-attach" }
const _hoisted_2 = { class: "camera-shot-attach__content" }
const _hoisted_3 = {
  class: "camera-shot-attach__video",
  ref: "canvas"
}
const _hoisted_4 = {
  class: "camera-shot-attach__video",
  autoplay: "",
  ref: "video"
}
const _hoisted_5 = { class: "camera-shot-attach__actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NModalWindow = _resolveComponent("NModalWindow")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_NButton, {
      icon: _ctx.icon,
      type: _ctx.type,
      onClick: _ctx.openCameraDialog,
      disabled: !_ctx.hasCamera
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.i18n_label ? _ctx.$t(_ctx.i18n_label) : ''), 1)
      ]),
      _: 1
    }, 8, ["icon", "type", "onClick", "disabled"]),
    (_ctx.isCameraDialogEnabled)
      ? (_openBlock(), _createBlock(_component_NModalWindow, {
          key: 0,
          onClose: _ctx.closeCameraDialog,
          "has-margins": false
        }, {
          content: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _withDirectives(_createElementVNode("canvas", _hoisted_3, null, 512), [
                [_vShow, _ctx.evaluating]
              ]),
              _withDirectives(_createElementVNode("video", _hoisted_4, null, 512), [
                [_vShow, !_ctx.evaluating]
              ])
            ])
          ]),
          footer: _withCtx(() => [
            _createElementVNode("div", _hoisted_5, [
              (_ctx.evaluating)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createVNode(_component_NButton, {
                      icon: "upload",
                      onClick: _ctx.attach
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('common.photo_upload')), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick"]),
                    _createVNode(_component_NButton, {
                      type: "secondary",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.evaluating = false))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('common.redo_picture')), 1)
                      ]),
                      _: 1
                    })
                  ], 64))
                : (_openBlock(), _createBlock(_component_NButton, {
                    key: 1,
                    icon: "photo",
                    onClick: _ctx.capture
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('common.take_picture')), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"]))
            ])
          ]),
          _: 1
        }, 8, ["onClose"]))
      : _createCommentVNode("", true)
  ]))
}