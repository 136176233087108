
import { CameraGroup } from '@/api';
import { ISelectItem } from '@/uikit';
import NForm, { IFormLayout } from '@/uikit/forms/NForm.vue';
import NSelect from '@/uikit/select/NSelect.vue';
import { computed, defineComponent, reactive, toRefs, unref } from 'vue';
import { arrayOf, integer, object } from 'vue-types';

export default defineComponent({
  name: 'VmsSidebarCamerasTabGroupDialogForm',
  components: { NForm },
  props: {
    groups: arrayOf(object<CameraGroup>()).isRequired,
    id: integer()
  },
  emits: ['update:id'],
  setup: (props, { emit }) => {
    const { id } = toRefs(props);
    const model = reactive({
      id: computed({
        get: () => unref(id),
        set: (id) => emit('update:id', id)
      })
    });
    return { model };
  },
  computed: {
    items(): ISelectItem[] {
      return this.groups.map(({ id, name }) => ({ label: name, value: id }));
    },
    layout(): IFormLayout {
      return [
        {
          classes: 'label-m',
          component: NSelect,
          label: this.$t('common.camera_group', 'f'),
          path: 'id',
          props: { items: this.items },
          validators: [{ name: 'required' }]
        }
      ];
    }
  },
  methods: {
    validate(): boolean {
      const form = this.$refs.form;
      const isValid: boolean = form.validate();
      return !isValid && form.displayErrors(), isValid;
    }
  }
});
