
import { CameraGroup } from '@/api';
import NButton from '@/uikit/buttons/NButton.vue';
import NModalWindow from '@/uikit/modal-window/NModalWindow.vue';
import { defineComponent, ref } from 'vue';
import { arrayOf, object } from 'vue-types';
import { VmsSidebarCamerasTabGroupDialogForm } from './components';

type VmsSidebarCamerasTabGroupDialogState = {
  id: number | undefined;
};

type VmsSidebarCamerasTabGroupDialogFormInstance = InstanceType<typeof VmsSidebarCamerasTabGroupDialogForm>;

export default defineComponent({
  name: 'VmsSidebarCamerasTabGroupDialog',
  components: {
    NButton,
    NModalWindow,
    VmsSidebarCamerasTabGroupDialogForm
  },
  props: {
    groups: arrayOf(object<CameraGroup>()).isRequired
  },
  emits: ['close', 'done'],
  data: (): VmsSidebarCamerasTabGroupDialogState => ({ id: undefined }),
  setup: () => ({ form: ref<VmsSidebarCamerasTabGroupDialogFormInstance | null>(null) }),
  methods: {
    dispatchCloseEvent(): void {
      this.$emit('close');
    },
    dispatchDoneEvent(): void {
      this.$emit('done', this.id);
    },
    submit(): void {
      this.form?.validate() && this.dispatchDoneEvent();
    }
  }
});
