
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import NIcon from '@/uikit/icons/NIcon.vue';
import NCheckbox from '@/uikit/checkbox/NCheckbox.vue';
import NDropdown from '@/uikit/dropdown/NDropdown.vue';
import NDropdownItem from '@/uikit/dropdown/NDropdownItem.vue';
import CameraScreenshot from '@/components/data-source/CameraScreenshot.vue';
import NButton from '@/uikit/buttons/NButton.vue';

interface CameraTransformParams {
  mirror: boolean;
  flip: boolean;
  rotateClockwise: boolean;
  rotateCounterclockwise: boolean;
}

interface CameraTransformTransformation {
  value: string;
  params1: CameraTransformParams;
  params2: CameraTransformParams;
}

function getDefaultParams(): CameraTransformParams {
  return {
    mirror: false,
    flip: false,
    rotateClockwise: false,
    rotateCounterclockwise: false
  };
}

@Options({
  name: 'CameraTransform',
  components: { NButton, CameraScreenshot, NDropdownItem, NDropdown, NCheckbox, NIcon }
})
export default class CameraTransform extends Vue {
  @Prop({ type: String, required: true })
  readonly modelValue!: string;

  @Prop({ type: String, default: '' })
  readonly screenshotUrl!: string;

  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean;

  params: CameraTransformParams = getDefaultParams();

  transformations: CameraTransformTransformation[] = [
    {
      value: '',
      params1: { mirror: false, flip: false, rotateClockwise: false, rotateCounterclockwise: false },
      params2: { mirror: false, flip: false, rotateClockwise: true, rotateCounterclockwise: true }
    },
    {
      value: 'FlipHorizontal',
      params1: { mirror: true, flip: false, rotateClockwise: false, rotateCounterclockwise: false },
      params2: { mirror: true, flip: false, rotateClockwise: true, rotateCounterclockwise: true }
    },
    {
      value: 'Rotate180',
      params1: { mirror: true, flip: true, rotateClockwise: false, rotateCounterclockwise: false },
      params2: { mirror: true, flip: true, rotateClockwise: true, rotateCounterclockwise: true }
    },
    {
      value: 'FlipVertical',
      params1: { mirror: false, flip: true, rotateClockwise: false, rotateCounterclockwise: false },
      params2: { mirror: false, flip: true, rotateClockwise: true, rotateCounterclockwise: true }
    },
    {
      value: 'Transpose',
      params1: { mirror: true, flip: false, rotateClockwise: false, rotateCounterclockwise: true },
      params2: { mirror: false, flip: true, rotateClockwise: true, rotateCounterclockwise: false }
    },
    {
      value: 'Rotate90',
      params1: { mirror: false, flip: false, rotateClockwise: true, rotateCounterclockwise: false },
      params2: { mirror: true, flip: true, rotateClockwise: false, rotateCounterclockwise: true }
    },
    {
      value: 'Transverse',
      params1: { mirror: true, flip: false, rotateClockwise: true, rotateCounterclockwise: false },
      params2: { mirror: false, flip: true, rotateClockwise: false, rotateCounterclockwise: true }
    },
    {
      value: 'Rotate270',
      params1: { mirror: false, flip: false, rotateClockwise: false, rotateCounterclockwise: true },
      params2: { mirror: true, flip: true, rotateClockwise: true, rotateCounterclockwise: false }
    }
  ];

  helperItems = this.transformations.map((item) => ({ ...item, transform: this.calcTransform(item.params1) }));

  get transform(): string {
    return this.calcTransform(this.params);
  }

  get transformation(): CameraTransformTransformation {
    return (
      this.transformations.find((transformation: CameraTransformTransformation) => {
        return this.compareTransform(transformation.params1, this.params) || this.compareTransform(transformation.params2, this.params);
      }) || this.transformations[0]
    );
  }

  compareTransform(params1: CameraTransformParams, params2: CameraTransformParams): boolean {
    return (
      params1.mirror === params2.mirror &&
      params1.flip === params2.flip &&
      params1.rotateClockwise === params2.rotateClockwise &&
      params1.rotateCounterclockwise === params2.rotateCounterclockwise
    );
  }

  calcTransform(params: CameraTransformParams): string {
    let t = '';
    if (params.mirror) t += ' scaleX(-1)';
    if (params.flip) t += ' scaleY(-1)';
    if (params.rotateClockwise) t += ' rotate(90deg)';
    if (params.rotateCounterclockwise) t += ' rotate(-90deg)';
    return t.trim();
  }

  optimizeParams() {
    this.params = { ...this.transformation.params1 };
  }

  rotateLeft() {
    if (this.params.rotateCounterclockwise) {
      this.params.rotateCounterclockwise = false;
      this.params.mirror = !this.params.mirror;
      this.params.flip = !this.params.flip;
    } else {
      this.params.rotateCounterclockwise = true;
    }
    this.optimizeParams();
  }

  rotateRight() {
    if (this.params.rotateClockwise) {
      this.params.rotateClockwise = false;
      this.params.mirror = !this.params.mirror;
      this.params.flip = !this.params.flip;
    } else {
      this.params.rotateClockwise = true;
    }
    this.optimizeParams();
  }

  mirrorHandle() {
    this.params.mirror = !this.params.mirror;
    this.optimizeParams();
  }

  flipHandle() {
    this.params.flip = !this.params.flip;
    this.optimizeParams();
  }

  selectTransform(transformation: CameraTransformTransformation) {
    this.params = { ...transformation.params1 };
  }

  @Watch('modelValue', { immediate: true })
  modelValueWatcher() {
    const newTransformation = this.transformations.find((transformation: CameraTransformTransformation) => {
      return transformation.value === this.modelValue;
    });

    if (newTransformation) {
      newTransformation.value !== this.transformation?.value && (this.params = { ...newTransformation.params1 });
    } else {
      this.params = getDefaultParams();
    }
  }

  @Watch('transformation')
  transformationWatcher() {
    this.$emit('update:modelValue', this.transformation.value);
  }
}
