
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import NButton from '@/uikit/buttons/NButton.vue';

@Options({
  name: 'ImageLoadError',
  components: { NButton },
  emits: ['load']
})
export default class ImageLoadError extends Vue {
  @Prop({ type: String, default: 'errors.camera_screenshot_load' })
  readonly i18nMessage!: string;

  @Prop({ type: Number, default: 60 })
  readonly iconSize!: number;
}
