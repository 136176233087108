import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButton = _resolveComponent("NButton")!
  const _component_MultisidebarActions = _resolveComponent("MultisidebarActions")!

  return (_ctx.saveAvailable && _ctx.sidebarItem?.model.hasChanges)
    ? (_openBlock(), _createBlock(_component_MultisidebarActions, { key: 0 }, {
        default: _withCtx(() => [
          _createVNode(_component_NButton, {
            class: "multisidebar-actions__save",
            onAction: _ctx.save,
            size: "big",
            loading: _ctx.sidebarItem.model.loading
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('common.save', 'f')), 1)
            ]),
            _: 1
          }, 8, ["onAction", "loading"]),
          _createVNode(_component_NButton, {
            onAction: _ctx.discard,
            type: "link",
            size: "big",
            disabled: _ctx.sidebarItem.model.loading
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('common.discard', 'f')), 1)
            ]),
            _: 1
          }, 8, ["onAction", "disabled"])
        ]),
        warning: _withCtx(() => [
          _renderSlot(_ctx.$slots, "warning")
        ]),
        _: 3
      }))
    : _createCommentVNode("", true)
}