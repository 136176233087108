import { EditorSection, EditorSections } from './types';
import { dataAssetsModule } from '@/store/application/data.assets.module';
import { configModule } from '@/store/config';

export function isSectionAvailable(section: EditorSection) {
  switch (section) {
    case EditorSections.FaceEvents:
      return dataAssetsModule.isObjectAvailable('face');
    case EditorSections.BodyEvents:
      return dataAssetsModule.isObjectAvailable('body');
    case EditorSections.CarEvents:
      return dataAssetsModule.isObjectAvailable('car');
    case EditorSections.HumanEpisodes:
      return dataAssetsModule.availableEpisodeTypesMap['human'];
    case EditorSections.CarEpisodes:
      return dataAssetsModule.availableEpisodeTypesMap['car'];
    case EditorSections.Counters:
      return configModule.config.services.ffsecurity.counters;
    default:
      return true;
  }
}
