import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "label-mono-xs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButton = _resolveComponent("NButton")!
  const _component_MultisidebarHeader = _resolveComponent("MultisidebarHeader")!
  const _component_NIcon = _resolveComponent("NIcon")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_MultisidebarHeader, {
      label: _ctx.$t('audit_logs.details', 'f'),
      onAction: _ctx.handleAction
    }, {
      beforeActions: _withCtx(() => [
        _createVNode(_component_NButton, {
          type: "secondary",
          icon: "copy",
          onClick: _ctx.copyText
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('audit_logs.copy', 'f')), 1)
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      _: 1
    }, 8, ["label", "onAction"]),
    _createElementVNode("pre", _hoisted_1, _toDisplayString(_ctx.itemContent), 1),
    (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
      _createElementVNode("div", {
        class: _normalizeClass([{ 'audit-logs_visible_noty': _ctx.visibleNoty }, "audit-logs__notification heading-m"])
      }, [
        _createVNode(_component_NIcon, { name: "info" }),
        _createTextVNode(_toDisplayString(_ctx.$t('audit_logs.clipboard_success', 'f')), 1)
      ], 2)
    ]))
  ]))
}