import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NTable = _resolveComponent("NTable")!

  return (_openBlock(), _createBlock(_component_NTable, {
    columns: _ctx.columns,
    content: _ctx.streams,
    selected: _ctx.selected,
    "selections-schema": _ctx.selectionsSchema,
    "onUpdate:selected": _ctx.select,
    class: "vms-sidebar-cameras-tab-list-item-stream-table",
    selections: ""
  }, null, 8, ["columns", "content", "selected", "selections-schema", "onUpdate:selected"]))
}