import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "multisidebar-content__main" }
const _hoisted_2 = {
  key: 0,
  class: "multisidebar-content__warning"
}
const _hoisted_3 = {
  key: 1,
  class: "multisidebar-content__warning"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MultisidebarHeader = _resolveComponent("MultisidebarHeader")!
  const _component_NTabs = _resolveComponent("NTabs")!
  const _component_LinesForm = _resolveComponent("LinesForm")!
  const _component_LinesROI = _resolveComponent("LinesROI")!
  const _component_LinesROIModal = _resolveComponent("LinesROIModal")!
  const _component_LinesBI = _resolveComponent("LinesBI")!
  const _component_MultisidebarSaveActions = _resolveComponent("MultisidebarSaveActions")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_MultisidebarHeader, {
      label: _ctx.currentItem.name,
      inactivated: !_ctx.currentItem.active,
      changed: _ctx.moduleItem.hasChanges,
      "i18n-inactivated": "common.user_inactive",
      actions: _ctx.headerActions,
      onAction: _cache[0] || (_cache[0] = (v) => _ctx.actionHandler(v, _ctx.sidebarItem))
    }, null, 8, ["label", "inactivated", "changed", "actions"]),
    _createVNode(_component_NTabs, {
      items: _ctx.tabItems,
      modelValue: _ctx.sidebarSharedState.activeTab,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.sidebarSharedState.activeTab) = $event)),
      class: "multisidebar-content__tabs control-m"
    }, null, 8, ["items", "modelValue"]),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.sidebarSharedState.activeTab === _ctx.TabNames.Information)
        ? (_openBlock(), _createBlock(_component_LinesForm, {
            key: 0,
            ref: "form",
            model: _ctx.currentItem,
            disabled: _ctx.disabled
          }, null, 8, ["model", "disabled"]))
        : (_ctx.sidebarSharedState.activeTab === _ctx.TabNames.Line)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              (_openBlock(), _createBlock(_component_LinesROI, {
                model: _ctx.currentItem,
                onShowModal: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isShowModal = true)),
                hasFullscreen: "",
                key: _ctx.currentItem.id,
                disabled: _ctx.disabled
              }, null, 8, ["model", "disabled"])),
              (_ctx.isShowModal)
                ? (_openBlock(), _createBlock(_component_LinesROIModal, {
                    key: 0,
                    model: _ctx.currentItem,
                    onClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.isShowModal = false)),
                    disabled: _ctx.disabled
                  }, null, 8, ["model", "disabled"]))
                : _createCommentVNode("", true)
            ], 64))
          : (_ctx.sidebarSharedState.activeTab === _ctx.TabNames.BI)
            ? (_openBlock(), _createBlock(_component_LinesBI, {
                key: 2,
                ref: "form",
                model: _ctx.currentItem,
                disabled: _ctx.disabled
              }, null, 8, ["model", "disabled"]))
            : _createCommentVNode("", true)
    ]),
    _createVNode(_component_MultisidebarSaveActions, {
      "sidebar-item": _ctx.sidebarItem,
      "model-acl": _ctx.modelAcl
    }, {
      warning: _withCtx(() => [
        (_ctx.displayDataSourceSettingsWarning)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.$t('lines.data_source_settings_warning')), 1))
          : _createCommentVNode("", true),
        (_ctx.displayDataSourceLineWarning)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.$t('lines.data_source_line_warning')), 1))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["sidebar-item", "model-acl"])
  ], 64))
}