import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NHint = _resolveComponent("NHint")!

  return (_openBlock(), _createBlock(_component_NHint, { class: "vms-sidebar-cameras-tab-list-item-stream-table-status__hint" }, {
    content: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.content), 1)
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass([{ 'vms-sidebar-cameras-tab-list-item-stream-table-status_added': _ctx.added }, "vms-sidebar-cameras-tab-list-item-stream-table-status"])
      }, null, 2)
    ]),
    _: 1
  }))
}