import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "multisidebar-content__main" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MultisidebarHeader = _resolveComponent("MultisidebarHeader")!
  const _component_NTabs = _resolveComponent("NTabs")!
  const _component_UserForm = _resolveComponent("UserForm")!
  const _component_UserFaces = _resolveComponent("UserFaces")!
  const _component_MultisidebarSaveActions = _resolveComponent("MultisidebarSaveActions")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_MultisidebarHeader, {
      label: _ctx.item.real_name,
      "sub-label": _ctx.item.name,
      inactivated: !_ctx.item.active,
      changed: _ctx.sidebarItem.model.hasChanges,
      actions: _ctx.headerActions,
      "model-acl": _ctx.modelAcl,
      "i18n-inactivated": "common.user_inactive",
      onAction: _cache[0] || (_cache[0] = (v) => _ctx.actionHandler(v, _ctx.sidebarItem))
    }, null, 8, ["label", "sub-label", "inactivated", "changed", "actions", "model-acl"]),
    _createVNode(_component_NTabs, {
      items: _ctx.tabItems,
      modelValue: _ctx.activeTab,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.activeTab) = $event)),
      class: "multisidebar-content__tabs control-m"
    }, null, 8, ["items", "modelValue"]),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.activeTab === 'information')
        ? (_openBlock(), _createBlock(_component_UserForm, {
            key: 0,
            "is-new": _ctx.isNew,
            item: _ctx.item,
            disabled: !_ctx.modelAcl.update
          }, null, 8, ["is-new", "item", "disabled"]))
        : (_openBlock(), _createBlock(_component_UserFaces, {
            key: 1,
            "is-new": _ctx.isNew,
            item: _ctx.item
          }, null, 8, ["is-new", "item"]))
    ]),
    _createVNode(_component_MultisidebarSaveActions, {
      "model-acl": _ctx.modelAcl,
      "sidebar-item": _ctx.sidebarItem
    }, null, 8, ["model-acl", "sidebar-item"])
  ]))
}