import { Camera } from '@/api';

export const ConnectionTypes = {
  Stream: 'stream',
  Onvif: 'onvif',
  ExternalDetector: 'external_detector',
  ExternalVMS: 'external_vms'
};

export function getConnectionTypeByItem(item: Camera) {
  if (item.onvif_camera) return ConnectionTypes.Onvif;
  if (item.external_vms) return ConnectionTypes.ExternalVMS;
  return ConnectionTypes.Stream;
}
