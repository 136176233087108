import { itemViewModelLoader } from '@/store/multisidebar/helpers';
import { PermissionsModelNamesMap, saveItemAndPermissions } from '@/components/permissions/permission-helpers';

export default async function (...args: any[]) {
  // @ts-ignore
  const defaultItem = await itemViewModelLoader(...args);
  const originSave = defaultItem.model.save;
  defaultItem.model.save = () => saveItemAndPermissions(defaultItem.model, PermissionsModelNamesMap.WatchLists, originSave.bind(defaultItem.model));
  return defaultItem;
}
