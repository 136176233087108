import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "vms-sidebar-cameras-tab-list-item" }
const _hoisted_2 = ["textContent"]
const _hoisted_3 = { class: "label-2xs vms-sidebar-cameras-tab-list-item__id" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButton = _resolveComponent("NButton")!
  const _component_VmsSidebarCamerasTabListItemScreenshot = _resolveComponent("VmsSidebarCamerasTabListItemScreenshot")!
  const _component_VmsSidebarCamerasTabListItemStreamTable = _resolveComponent("VmsSidebarCamerasTabListItemStreamTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "heading-s vms-sidebar-cameras-tab-list-item__name",
      textContent: _toDisplayString(_ctx.camera.name)
    }, null, 8, _hoisted_2),
    _createElementVNode("div", _hoisted_3, [
      _createTextVNode(" ID " + _toDisplayString(_ctx.camera.id) + "  ", 1),
      _createVNode(_component_NButton, {
        "icon-size": 12,
        onClick: _ctx.copyCameraIdToClipboard,
        icon: "copy",
        type: "link"
      }, null, 8, ["onClick"])
    ]),
    _createVNode(_component_VmsSidebarCamerasTabListItemScreenshot, {
      screenshot: _ctx.camera.screenshot,
      class: "vms-sidebar-cameras-tab-list-item__screenshot"
    }, null, 8, ["screenshot"]),
    _createVNode(_component_VmsSidebarCamerasTabListItemStreamTable, {
      added: _ctx.camera.added,
      streams: _ctx.camera.streams,
      onSelect: _ctx.dispatchSelectEvent,
      class: "vms-sidebar-cameras-tab-list-item__table"
    }, null, 8, ["added", "streams", "onSelect"])
  ]))
}