import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "group-camera-groups" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderBar = _resolveComponent("HeaderBar")!
  const _component_NTable = _resolveComponent("NTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.content)
      ? (_openBlock(), _createBlock(_component_HeaderBar, {
          key: 0,
          "search-text": _ctx.searchText,
          "onUpdate:search-text": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchText) = $event))
        }, {
          count: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.cameraGroups.length) + " " + _toDisplayString(_ctx.$t('common.total')) + ", " + _toDisplayString(_ctx.offCount) + " " + _toDisplayString(_ctx.$t('common.off')), 1)
          ]),
          _: 1
        }, 8, ["search-text"]))
      : _createCommentVNode("", true),
    _createVNode(_component_NTable, {
      columns: _ctx.columns,
      header: _ctx.header,
      content: _ctx.cameraGroups,
      disabled: _ctx.disabled,
      onAction: _ctx.actionHandler
    }, null, 8, ["columns", "header", "content", "disabled", "onAction"])
  ]))
}