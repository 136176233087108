import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "multisidebar-content__main" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MultisidebarHeader = _resolveComponent("MultisidebarHeader")!
  const _component_NTabs = _resolveComponent("NTabs")!
  const _component_NForm = _resolveComponent("NForm")!
  const _component_VMSCleanupForm = _resolveComponent("VMSCleanupForm")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_MultisidebarSaveActions = _resolveComponent("MultisidebarSaveActions")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_MultisidebarHeader, {
      label: _ctx.item.name,
      actions: _ctx.headerActions,
      item: _ctx.item,
      inactivated: _ctx.isInactive,
      "i18n-inactivated-label": "ds.inactivated",
      onAction: _ctx.actionHandler
    }, null, 8, ["label", "actions", "item", "inactivated", "onAction"]),
    (!_ctx.isExternalDetector)
      ? (_openBlock(), _createBlock(_component_NTabs, {
          key: 0,
          items: _ctx.tabItems,
          modelValue: _ctx.sidebarSharedState.activeTab,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.sidebarSharedState.activeTab) = $event)),
          class: "multisidebar-content__tabs control-m"
        }, null, 8, ["items", "modelValue"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.sidebarSharedState.activeTab !== 'vms-settings')
        ? (_openBlock(), _createBlock(_component_NForm, {
            key: 0,
            ref: "form",
            layout: _ctx.formLayout,
            model: _ctx.item,
            state: _ctx.sidebarSharedState,
            disabled: _ctx.disabled
          }, null, 8, ["layout", "model", "state", "disabled"]))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createVNode(_component_VMSCleanupForm, {
              "model-value": _ctx.item.vms_cleanup_settings,
              type: "camera"
            }, null, 8, ["model-value"]),
            _createVNode(_component_NButton, {
              class: "data-sources-sidebar__display-settings-button",
              type: "link",
              onClick: _ctx.toggleDisplayDefaultSettings
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('settings.display_default_settings' )), 1)
              ]),
              _: 1
            }, 8, ["onClick"]),
            (_ctx.displayDefaultSettings)
              ? (_openBlock(), _createBlock(_component_VMSCleanupForm, {
                  key: 0,
                  "model-value": _ctx.settingsItemModule.item?.vms_cleanup_settings,
                  disabled: true,
                  type: "settings"
                }, null, 8, ["model-value"]))
              : _createCommentVNode("", true)
          ], 64))
    ]),
    _createVNode(_component_MultisidebarSaveActions, {
      "model-acl": _ctx.modelAcl,
      "sidebar-item": _ctx.sidebarItem
    }, null, 8, ["model-acl", "sidebar-item"])
  ]))
}