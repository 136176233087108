
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import NSelect from '@/uikit/select/NSelect.vue';
import { Camera, ExternalVmsService } from '@/api';
import { dataServiceRepository } from '@/api/common';
import { VmsPluginCamerasResponse } from '@/api/models/VmsPluginCamerasResponse';

function responseToOption(v: VmsPluginCamerasResponse) {
  return { label: v.name, value: String(v.id) };
}

@Options({
  name: 'ExternalVmsCameraSelect',
  components: { NSelect },
  emits: []
})
export default class ExternalVmsCameraSelect extends Vue {
  @Prop({ type: Number, default: null })
  readonly modelValue!: number | null;

  @Prop({ type: Object, required: true })
  readonly model!: Camera;

  get hasExternalVms() {
    return Boolean(this.model.external_vms && (this.model.external_vms as unknown as boolean) !== true);
  }

  async loadOptions(idIn?: number) {
    if (this.hasExternalVms) {
      const params: any[] = [undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [idIn]];
      let response = await ExternalVmsService.externalVmsCamerasList(this.model.external_vms!, ...params);
      return response.results?.map(responseToOption) || [];
    }
    return [];
  }

  async searchOptions(term = '') {
    if (this.hasExternalVms) {
      const params: any[] = [undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, term];
      let response = await ExternalVmsService.externalVmsCamerasList(this.model.external_vms!, ...params);
      return response.results?.map(responseToOption) || [];
    }
    return [];
  }

  updateValue(value: number) {
    this.model.url = '';
    this.$emit('update:modelValue', value);
  }
}
