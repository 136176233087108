import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NTextareaAutosize = _resolveComponent("NTextareaAutosize")!
  const _component_NTooltip = _resolveComponent("NTooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_NTextareaAutosize, {
      "model-value": _ctx.modelValueString,
      "onUpdate:modelValue": _ctx.dispatchModelValueUpdate,
      ref: "area",
      rows: "18",
      disabled: _ctx.disabled
    }, null, 8, ["model-value", "onUpdate:modelValue", "disabled"]),
    (_ctx.isInvalid)
      ? (_openBlock(), _createBlock(_component_NTooltip, {
          key: 0,
          reference: _ctx.$refs.area.$el,
          placement: "left-start",
          mode: "manual"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('errors.json_invalid', 'f')), 1)
          ]),
          _: 1
        }, 8, ["reference"]))
      : _createCommentVNode("", true)
  ], 64))
}