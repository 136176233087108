
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import NSelect from '@/uikit/select/NSelect.vue';
import NButton from '@/uikit/buttons/NButton.vue';
import { Camera, ExternalVmsService } from '@/api';
import { actionHandler } from '@/store/data/ActionHandler';
import { ItemsActionNames } from '@/definitions/app/item.actions.name';
import { MultisidebarItemTypes } from '@/store/multisidebar/types';

@Options({
  name: 'ExternalVmsSelect',
  components: { NSelect, NButton },
  emits: []
})
export default class ExternalVmsSelect extends Vue {
  @Prop({ type: Number })
  modelValue?: number | null;

  @Prop({ type: Object, required: true })
  readonly model!: Camera;

  protected options: { label: string; value: number }[] = [];

  get hasRealVms() {
    return Boolean(this.modelValue && (this.modelValue as unknown as boolean) !== true);
  }

  async initOptions() {
    let items = await ExternalVmsService.externalVmsList();
    this.options = items.results?.map((v) => ({ label: v.verbose_name || '', value: v.id })) || [];
  }

  updateValue(value: number | undefined) {
    this.model.url = '';
    this.model.external_vms_camera_id = null;
    this.$emit('update:modelValue', value === undefined ? true : value);
  }

  showVms() {
    actionHandler.run(ItemsActionNames.ShowItem, { type: MultisidebarItemTypes.ExternalVms, rawItem: this.model.external_vms });
  }

  mounted() {
    this.initOptions();
  }
}
