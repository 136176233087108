
import { defineComponent } from 'vue';
import { bool } from 'vue-types';

export default defineComponent({
  name: 'CameraLookupLink',
  props: {
    running: bool().def(false)
  },
  emits: ['click'],
  computed: {
    text(): string {
      return this.$t(`ds.${this.running ? 'discovering' : 'discover_again'}`);
    }
  },
  methods: {
    dispatchClickEvent(): void {
      this.$emit('click');
    }
  }
});
