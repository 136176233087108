import { computed, reactive, ToRefs, unref } from 'vue';
import { VmsSidebarCamerasTabFilters } from '../../types';
import { VmsSidebarCamerasTabFiltersEvents } from './types';

export function useVmsSidebarCamerasTabFiltersViewModel(
  props: ToRefs<VmsSidebarCamerasTabFilters>,
  emit: (event: VmsSidebarCamerasTabFiltersEvents, ...args: unknown[]) => void
) {
  const added = computed({
    get: () => unref(props.added),
    set: (added) => emit('update:added', added)
  });

  const groupContains = computed({
    get: () => unref(props.groupContains),
    set: (groupContains) => emit('update:groupContains', groupContains)
  });

  const nameContains = computed({
    get: () => unref(props.nameContains),
    set: (nameContains) => emit('update:nameContains', nameContains)
  });

  const model = reactive({ added, groupContains, nameContains });

  return { model };
}
