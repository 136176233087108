import { IFormLayout } from '@/uikit/forms/NForm.vue';
import GroupPermissions from './GroupPermissions.vue';

const layout: IFormLayout = [
  {
    path: 'permissions',
    classes: 'n-form-w-6 form-pad',
    component: GroupPermissions
  }
];

export default layout;
