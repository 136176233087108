
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import MultisidebarHeader from '@/components/multisidebar/MultisidebarHeader.vue';
import MultisidebarSaveActions from '@/components/multisidebar/MultisidebarSaveActions.vue';
import NTabs from '@/uikit/tabs/NTabs.vue';
import LinesForm from '@/pages/lines/LinesForm.vue';
import LinesROI from '@/pages/lines/LinesROI.vue';
import { MultisidebarItem, MultisidebarSharedState } from '@/store/multisidebar/types';
import { ItemViewModel } from '@/definitions/view-models';
import { ModelAclResult } from '@/store/acl/types';
import { actionsModule } from '@/store/data/ActionsModule';
import { ItemsActionName, ItemsActionNames } from '@/definitions/app/item.actions.name';
import { actionHandler } from '@/store/data/ActionHandler';
import { Line } from '@/api/models/Line';
import LinesROIModal from '@/pages/lines/LinesROIModal.vue';
import { CamerasService, Detectors } from '@/api';
import { dataModule } from '@/store/data';
import LinesBI from '@/pages/lines/LinesBI.vue';

const TabNames = {
  Information: 'information',
  Line: 'line',
  BI: 'bi'
} as const;

@Options({
  name: 'LinesSidebar',
  components: { LinesBI, LinesROIModal, LinesROI, LinesForm, NTabs, MultisidebarSaveActions, MultisidebarHeader }
})
export default class LinesSidebar extends Vue {
  @Prop({ type: Object, required: true })
  readonly sidebarItem!: MultisidebarItem<ItemViewModel<Line>>;

  @Prop({ type: Object, required: true })
  readonly modelAcl!: ModelAclResult;

  readonly TabNames = TabNames;

  @Prop({ type: Object, required: true })
  readonly sidebarSharedState!: MultisidebarSharedState;

  isShowModal = false;

  get moduleItem() {
    return this.sidebarItem.model;
  }

  get currentItem() {
    return this.sidebarItem.model.item;
  }

  get displayDataSourceLineWarning() {
    let result = false;
    const item = this.sidebarItem.model.item;
    const cameraId = item?.camera;
    const videoArchiveId = item?.video_archive;
    if (!cameraId && !videoArchiveId) return result;
    return !((item?.points?.length || 0) >= 2);
  }

  get displayDataSourceSettingsWarning() {
    let result = false;
    const cameraId = this.sidebarItem.model.item?.camera;
    const videoArchiveId = this.sidebarItem.model.item?.video_archive;
    if (!cameraId && !videoArchiveId) return result;

    const source = dataModule.camerasModule.items.find((v) => v.id === cameraId) || dataModule.videosModule.items.find((v) => v.id === videoArchiveId);
    const detectors = source?.stream_settings?.detectors;
    const countDetectors = Object.keys(detectors || {}).filter((v) => detectors?.[v as keyof Detectors]).length;
    const enabledSendHistoryDetectors = ['face', 'body', 'car'].filter((v) => {
      const detector = detectors && detectors[v as keyof Detectors];
      return detector?.track_send_history;
    });
    result = !countDetectors || countDetectors !== enabledSendHistoryDetectors.length;
    return result;
  }

  get disabled() {
    return !(this.moduleItem.isNew ? this.modelAcl.add : this.modelAcl.update);
  }

  get headerActions() {
    return actionsModule
      .getItemActions(this.modelAcl, null, {
        hasActive: true,
        hasDelete: true,
        hasRestart: !!this.currentItem?.camera,
        currentActive: this.currentItem?.active,
        hasLineFilterEvent: true
      })
      .map(actionsModule.computeActionByName);
  }

  get tabItems() {
    return [
      {
        name: this.TabNames.Information,
        label: this.$t('lines.information', 'f')
      },
      {
        name: this.TabNames.Line,
        label: this.$t('lines.line', 'f')
      },
      {
        name: this.TabNames.BI,
        label: this.$t('lines.bi', 'f')
      }
    ];
  }

  actionHandler(action: ItemsActionName, payload?: any) {
    if (action === ItemsActionNames.Restart) {
      const { camera } = this.currentItem!;
      camera && CamerasService.camerasRestartCreate(camera);
    } else {
      actionHandler.run(action, payload);
    }
  }

  validateForm() {
    if (this.sidebarSharedState.activeTab === TabNames.Line) {
      return true;
    }
    return this.$refs.form?.validate();
  }

  mounted() {
    actionHandler.getIsItemFormValid = this.validateForm.bind(this);
  }
}
