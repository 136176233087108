import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "acknowledge-info" }
const _hoisted_2 = {
  key: 0,
  class: "acknowledge-info__date"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButton = _resolveComponent("NButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.item.acknowledged_date)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.acknowledgedDate), 1))
      : _createCommentVNode("", true),
    (_ctx.item.acknowledged_by)
      ? (_openBlock(), _createBlock(_component_NButton, {
          key: 1,
          type: "link",
          onClick: _ctx.navigateToUser
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.acknowledgedBy), 1)
          ]),
          _: 1
        }, 8, ["onClick"]))
      : _createCommentVNode("", true)
  ]))
}