import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "cards-duplicate-dialog" }
const _hoisted_2 = { class: "cards-duplicate-dialog__duplicate" }
const _hoisted_3 = { class: "cards-duplicate-dialog__cards" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ThumbnailItem = _resolveComponent("ThumbnailItem")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NTextDelimiter = _resolveComponent("NTextDelimiter")!
  const _component_CardItemTile = _resolveComponent("CardItemTile")!
  const _component_NModalWindow = _resolveComponent("NModalWindow")!

  return (_openBlock(), _createBlock(_component_NModalWindow, {
    label: _ctx.$t('cards.duplicate', 'f'),
    onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close'))),
    size: "medium"
  }, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('cards.duplicate_card', 'f')), 1),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_ThumbnailItem, {
            item: { thumbnail: _ctx.image }
          }, null, 8, ["item"]),
          _createVNode(_component_NButton, {
            type: "secondary",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('addToCard', _ctx.currentCardId)))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t(_ctx.currentCardId ? 'cards.ignore_this_warning' : 'cards.create_new_card', 'f')), 1)
            ]),
            _: 1
          })
        ]),
        _createVNode(_component_NTextDelimiter, {
          label: _ctx.$t('cards.similar_cards'),
          class: "cards-duplicate-dialog__delimiter"
        }, null, 8, ["label"]),
        _createElementVNode("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cards, (item) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "cards-duplicate-dialog__card",
              key: item.id
            }, [
              _createVNode(_component_CardItemTile, {
                type: _ctx.cardType,
                item: item,
                "show-short-comment": "",
                selectable: false,
                hoverable: false
              }, null, 8, ["type", "item"]),
              _createVNode(_component_NButton, {
                type: "secondary",
                onClick: ($event: any) => (_ctx.$emit('addToCard', item.id))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('cards.add_photo_to_existing_card', 'f')), 1)
                ]),
                _: 2
              }, 1032, ["onClick"])
            ]))
          }), 128))
        ])
      ])
    ]),
    _: 1
  }, 8, ["label"]))
}