import { IFormLayout } from '@/uikit/forms/NForm.vue';
import GroupCameraGroups from './GroupCameraGroups.vue';

const layout: IFormLayout = [
  {
    path: 'id',
    classes: 'n-form-w-6 form-pad',
    component: GroupCameraGroups
  }
];

export default layout;
