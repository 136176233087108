
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { VideoArchive } from '@/api';
import VideoPlayer from '@/components/video-player/VideoPlayer.vue';
import CameraScreenshot from '@/components/data-source/CameraScreenshot.vue';
import NButton from '@/uikit/buttons/NButton.vue';
import { RouterModule } from '@/store/router';
import { ObjectsTypesMap } from '@/store/application/data.assets';

@Options({
  name: 'VideoProcessingPlayer',
  components: { NButton, CameraScreenshot, VideoPlayer }
})
export default class VideoProcessingPlayer extends Vue {
  @Prop({ type: Object, required: true })
  readonly item!: VideoArchive;

  navigateToVideoEvents() {
    RouterModule.navigateToEvents(ObjectsTypesMap.Faces, { video_archive: this.item.id });
  }
}
