import { CameraGroup } from '@/api';
import { AppContext, render } from 'vue';
import VmsSidebarCamerasTabGroupDialog from './VmsSidebarCamerasTabGroupDialog.vue';

export function createVmsSidebarCamerasTabGroupDialog(context: AppContext | null) {
  function renderVmsSidebarCamerasTabGroupDialog(groups: CameraGroup[]): Promise<CameraGroup['id']> {
    return new Promise(function (resolve) {
      const container = document.body.appendChild(document.createElement('div'));
      const component = <VmsSidebarCamerasTabGroupDialog groups={groups} onClose={destroy} onDone={handleDoneEvent} />;
      render(((component.appContext = context), component), container);

      function destroy(): void {
        render(null, document.body.removeChild(container));
      }

      function handleDoneEvent(id: CameraGroup['id']): void {
        resolve(id), destroy();
      }
    });
  }

  return renderVmsSidebarCamerasTabGroupDialog;
}
