
import NCheckbox from '@/uikit/checkbox/NCheckbox.vue';
import NText from '@/uikit/text/NText.vue';
import NTable, { ITableColumn } from '@/uikit/table/NTable.vue';
import NTableColumnCell from '@/uikit/table/NTableColumnCell.vue';
import { defineComponent } from 'vue';
import { arrayOf, func, object } from 'vue-types';
import { AccessPermissionAction, AccessPermissionGroup, PermissionBlockPredicate, PermissionGrantPredicate } from './types';

export default defineComponent({
  name: 'GroupAccessPermissions',
  components: { NTable },
  props: {
    groups: arrayOf(object<AccessPermissionGroup>()).isRequired,
    isBlocked: func<PermissionBlockPredicate>().isRequired,
    isGranted: func<PermissionGrantPredicate>().isRequired
  },
  emits: ['toggle'],
  computed: {
    columns(): ITableColumn[] {
      return [
        this.createTargetColumn(),
        this.createActionColumn('view'),
        this.createActionColumn('change'),
        this.createActionColumn('add'),
        this.createActionColumn('delete')
      ];
    }
  },
  methods: {
    createActionColumn(action: AccessPermissionAction): ITableColumn {
      const column = {
        width: '75px',
        header: {
          classes: ['align-center', 'bottom-border'],
          component: NTableColumnCell,
          props: { i18n_label: `common.column_${action}` }
        },
        content: {
          classes: ['align-center'],
          component: NCheckbox,
          props: (group: AccessPermissionGroup) => ({
            disabled: this.isBlocked(group[action]),
            modelValue: this.isGranted(group[action]),
            'onUpdate:modelValue': () => this.$emit('toggle', group[action])
          })
        }
      };
      return column;
    },
    createTargetColumn(): ITableColumn {
      const column = {
        header: {
          classes: ['bottom-border'],
          component: NTableColumnCell,
          props: { i18n_label: 'common.column_name' }
        },
        content: {
          component: NText,
          path: 'target'
        }
      };
      return column;
    }
  }
});
