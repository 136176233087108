import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "camera-lookup-authentication-window__content" }
const _hoisted_2 = { class: "camera-lookup-authentication-window__footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CameraLookupAuthenticationForm = _resolveComponent("CameraLookupAuthenticationForm")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NModalWindow = _resolveComponent("NModalWindow")!

  return (_openBlock(), _createBlock(_component_NModalWindow, {
    "insert-into-body": false,
    onClose: _ctx.dispatchCloseEvent,
    "custom-size": "570px"
  }, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_CameraLookupAuthenticationForm, {
          ref: "form",
          login: _ctx.login,
          "onUpdate:login": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.login) = $event)),
          password: _ctx.password,
          "onUpdate:password": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.password) = $event))
        }, null, 8, ["login", "password"])
      ])
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_NButton, {
          label: _ctx.$t('ds.login_to_camera'),
          loading: _ctx.running,
          onClick: _ctx.submit
        }, null, 8, ["label", "loading", "onClick"])
      ])
    ]),
    _: 1
  }, 8, ["onClose"]))
}