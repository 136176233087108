import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "camera-lookup-sub-form" }
const _hoisted_2 = { class: "camera-lookup-sub-form__address label-m" }
const _hoisted_3 = { class: "camera-lookup-sub-form__profile label-m" }
const _hoisted_4 = { class: "camera-lookup-sub-form__select" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NInput = _resolveComponent("NInput")!
  const _component_NSelect = _resolveComponent("NSelect")!
  const _component_NLoadingCircle = _resolveComponent("NLoadingCircle")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('ds.camera_ip_address')), 1),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('ds.stream_profile')), 1),
    _createVNode(_component_NInput, {
      "model-value": _ctx.modelValue,
      class: "camera-lookup-sub-form__input",
      disabled: "",
      readonly: ""
    }, null, 8, ["model-value"]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_NSelect, {
        disabled: _ctx.isSelectDisabled,
        items: _ctx.streams,
        modelValue: _ctx.token,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.token) = $event))
      }, null, 8, ["disabled", "items", "modelValue"]),
      (_ctx.running)
        ? (_openBlock(), _createBlock(_component_NLoadingCircle, {
            key: 0,
            class: "camera-lookup-sub-form__select-running"
          }))
        : _createCommentVNode("", true)
    ])
  ]))
}