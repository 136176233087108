import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "camera-lookup" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CameraLookupLink = _resolveComponent("CameraLookupLink")!
  const _component_CameraLookupSearch = _resolveComponent("CameraLookupSearch")!
  const _component_CameraLookupSummary = _resolveComponent("CameraLookupSummary")!
  const _component_CameraLookupTable = _resolveComponent("CameraLookupTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_CameraLookupLink, {
      running: _ctx.running,
      onClick: _ctx.lookup,
      class: "camera-lookup__link"
    }, null, 8, ["running", "onClick"]),
    _createVNode(_component_CameraLookupSearch, {
      class: "camera-lookup__search",
      search: _ctx.search,
      "onUpdate:search": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event))
    }, null, 8, ["search"]),
    _createVNode(_component_CameraLookupSummary, {
      summary: _ctx.cameras.length,
      class: "camera-lookup__summary"
    }, null, 8, ["summary"]),
    _createVNode(_component_CameraLookupTable, {
      cameras: _ctx.queriedCameras,
      onSelect: _ctx.handleCameraSelection,
      class: "camera-lookup__table"
    }, null, 8, ["cameras", "onSelect"])
  ]))
}