import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NThumbnail = _resolveComponent("NThumbnail")!

  return (_openBlock(), _createBlock(_component_NThumbnail, {
    "model-value": _ctx.face.thumbnail,
    actions: _ctx.actions,
    "default-action": _ctx.defaultAction
  }, null, 8, ["model-value", "actions", "default-action"]))
}