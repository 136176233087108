
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { MultisidebarItem } from '@/store/multisidebar/types';
import { ItemViewModel } from '@/definitions/view-models';
import { AuditLog } from '@/api';
import { ModelAclResult } from '@/store/acl/types';
import MultisidebarHeader from '@/components/multisidebar/MultisidebarHeader.vue';
import NButton from '@/uikit/buttons/NButton.vue';
import NIcon from '@/uikit/icons/NIcon.vue';
import { copyTextToClipboard } from '@/uikit/helpers';
import { ItemsActionName } from '@/definitions/app/item.actions.name';
import { actionHandler } from '@/store/data/ActionHandler';

@Options({
  name: 'AuditLogsSidebar',
  components: { NIcon, NButton, MultisidebarHeader },
  emits: []
})
export default class AuditLogsSidebar extends Vue {
  @Prop({ type: Object, required: true })
  readonly sidebarItem!: MultisidebarItem<ItemViewModel<AuditLog>>;

  @Prop({ type: Object, required: true })
  readonly modelAcl!: ModelAclResult;

  visibleNoty = false;

  get itemContent() {
    return JSON.stringify(this.sidebarItem.model.item, undefined, 2);
  }

  copyText() {
    copyTextToClipboard(this.itemContent).then(() => {
      this.visibleNoty = true;
      setTimeout(() => {
        this.visibleNoty = false;
      }, 3000);
    });
  }

  handleAction(v: ItemsActionName) {
    actionHandler.run(v, this.sidebarItem);
  }
}
