
import { BatchUploadService, PaginatedUploadListList } from '@/api';
import BatchLoaderModalResult from '@/pages/cards/content/BatchLoaderModalResult.vue';
import { getBatchLoaderFormSchema } from '@/pages/cards/forms/batch-loader-form-schema.ts';
import { dataAssetsModule, DataAssetsModule } from '@/store/application/data.assets.module';
import { batchUploaderModule, defaultFormState } from '@/store/cards/BatchUploaderModule';
import { BatchUploaderFormItem } from '@/store/cards/types.ts';
import NButton from '@/uikit/buttons/NButton.vue';
import NButtonGroup from '@/uikit/buttons/NButtonGroup.vue';
import NForm from '@/uikit/forms/NForm.vue';
import NLoadingProgress from '@/uikit/loading/NLoadingProgress.vue';
import NModalWindow from '@/uikit/modal-window/NModalWindow.vue';
import { NAttachment, NAttachmentFileBlob, NAttachments } from '@/uikit/attachments';
import { reactive } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { ActionsModule } from '@/store/data/ActionsModule';
import { actionHandler } from '@/store/data/ActionHandler';
import { ItemsActionNames } from '@/definitions/app/item.actions.name';
import { dialogModule } from '@/store/dialogs/dialogModule';

@Options({
  components: {
    BatchLoaderModalResult,
    NAttachments,
    NButton,
    NButtonGroup,
    NForm,
    NLoadingProgress,
    NModalWindow
  }
})
export default class BatchLoaderModal extends Vue {
  private form: BatchUploaderFormItem = defaultFormState;
  private objectType: string = 'faces';
  private logs: PaginatedUploadListList = {};
  private files: File[] = [];

  get batchModule() {
    return reactive(batchUploaderModule(this.objectType));
  }

  get schema() {
    return getBatchLoaderFormSchema();
  }

  get dataAssetsModule(): DataAssetsModule {
    return dataAssetsModule;
  }

  get resultItems() {
    return this.batchModule.items;
  }

  get totalStatistics() {
    return this.batchModule.total;
  }

  get cardType() {
    return this.dataAssetsModule.getCardTypeByObjectType(this.objectType);
  }

  get loading() {
    return this.batchModule.loading;
  }

  get finished() {
    return this.batchModule.finished;
  }

  get attachDisabled() {
    if (this.batchModule.paused) return false;
    return this.batchModule.loading;
  }

  get hasToShowConfirm() {
    return this.batchModule.items.length && !this.batchModule.finished;
  }

  async setObjectType(v: string) {
    let result = this.hasToShowConfirm ? await this.showConfirm() : true;
    if (result) {
      this.objectType = v;
    }
  }

  uploadHandler(e: Event) {
    if (this.attachDisabled) {
      console.log('For attach files pause current process!');
      return;
    }
    const target = e.target as HTMLInputElement;
    if (target && target.files) {
      this.batchModule.addFiles(Array.from(target.files));
      target.value = '';
    }
  }

  startingTimeout = false;

  uploadDNDHandler(attachments: NAttachment[]) {
    const dndResult: File[] = [];
    dndResult.push(...attachments.map((attachment) => attachment.file as NAttachmentFileBlob));

    if (!this.startingTimeout) {
      this.startingTimeout = true;
      setTimeout(() => {
        this.startingTimeout = false;
        if (this.attachDisabled) {
          console.log('For attach files pause current process!');
          return;
        }
        this.batchModule.addFiles(dndResult);
      }, 500);
    }
  }

  batchUpload() {
    this.batchModule.paused = false;
    this.batchModule.formState = this.form;
    this.batchModule.upload();
  }
  pauseUpload() {
    this.batchModule.pause(true);
  }
  async showLogs() {
    this.logs = await BatchUploadService.batchUploadList();
  }

  async openCard(id: number) {
    const sidebarType = dataAssetsModule.getCardTypeByObjectType(this.objectType);
    let result = this.hasToShowConfirm ? await this.showConfirm() : true;
    if (result) {
      this.$emit('close');
      await actionHandler.run(ItemsActionNames.ShowItem, { type: 'cards_' + sidebarType, rawItem: id });
    }
  }

  async showConfirm(): Promise<boolean> {
    const result = await dialogModule.createBatchUploaderConfirm();
    return result;
  }

  async close() {
    let result = this.hasToShowConfirm ? await this.showConfirm() : true;
    if (result) {
      this.$emit('close');
    }
  }
}
