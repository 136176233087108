
import { defineComponent, toRefs } from 'vue';
import { CameraLookupLink, CameraLookupSearch, CameraLookupSummary, CameraLookupTable } from './components';
import { useCameraLookupViewModel } from './setup';
import { defineAuthenticatorProp, defineSourceProp } from './utils';

export default defineComponent({
  name: 'CameraLookup',
  components: {
    CameraLookupLink,
    CameraLookupSearch,
    CameraLookupSummary,
    CameraLookupTable
  },
  props: {
    authenticator: defineAuthenticatorProp(),
    source: defineSourceProp()
  },
  emits: ['select'],
  setup: (props, { emit }) => {
    const { authenticator, source } = toRefs(props);
    return useCameraLookupViewModel(authenticator, source, (camera) => emit('select', camera));
  }
});
