import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bbox-intersection-point" }
const _hoisted_2 = { class: "bbox-intersection-point__row" }
const _hoisted_3 = { class: "bbox-intersection-point__row" }
const _hoisted_4 = { class: "bbox-intersection-point__row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NRadio = _resolveComponent("NRadio")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_NRadio, {
        selectValue: "top left",
        disabled: _ctx.disabled,
        modelValue: _ctx.model,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model) = $event))
      }, null, 8, ["disabled", "modelValue"]),
      _createVNode(_component_NRadio, {
        selectValue: "top center",
        disabled: _ctx.disabled,
        modelValue: _ctx.model,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model) = $event))
      }, null, 8, ["disabled", "modelValue"]),
      _createVNode(_component_NRadio, {
        selectValue: "top right",
        disabled: _ctx.disabled,
        modelValue: _ctx.model,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model) = $event))
      }, null, 8, ["disabled", "modelValue"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_NRadio, {
        selectValue: "middle left",
        disabled: _ctx.disabled,
        modelValue: _ctx.model,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.model) = $event))
      }, null, 8, ["disabled", "modelValue"]),
      _createVNode(_component_NRadio, {
        selectValue: "middle center",
        disabled: _ctx.disabled,
        modelValue: _ctx.model,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.model) = $event))
      }, null, 8, ["disabled", "modelValue"]),
      _createVNode(_component_NRadio, {
        selectValue: "middle right",
        disabled: _ctx.disabled,
        modelValue: _ctx.model,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.model) = $event))
      }, null, 8, ["disabled", "modelValue"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_NRadio, {
        selectValue: "bottom left",
        disabled: _ctx.disabled,
        modelValue: _ctx.model,
        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.model) = $event))
      }, null, 8, ["disabled", "modelValue"]),
      _createVNode(_component_NRadio, {
        selectValue: "bottom center",
        disabled: _ctx.disabled,
        modelValue: _ctx.model,
        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.model) = $event))
      }, null, 8, ["disabled", "modelValue"]),
      _createVNode(_component_NRadio, {
        selectValue: "bottom right",
        disabled: _ctx.disabled,
        modelValue: _ctx.model,
        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.model) = $event))
      }, null, 8, ["disabled", "modelValue"])
    ])
  ]))
}