import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "object-item" }
const _hoisted_2 = { class: "object-item__thumbnail" }
const _hoisted_3 = { class: "object-item__information" }
const _hoisted_4 = { class: "object-item__information-content" }
const _hoisted_5 = { class: "object-item__name heading-s" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NThumbnail = _resolveComponent("NThumbnail")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_NThumbnail, {
        modelValue: _ctx.item.thumbnail,
        actions: _ctx.computedActions,
        onAction: _cache[0] || (_cache[0] = (v) => _ctx.$emit('action', v, _ctx.item))
      }, null, 8, ["modelValue", "actions"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.item.id), 1)
      ])
    ])
  ]))
}