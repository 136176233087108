
import { nextTick, reactive } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';
import NRadio from '@/uikit/radio/NRadio.vue';

@Options({
  name: 'BBoxIntersectionPoint',
  components: { NRadio }
})
export default class BBoxIntersectionPoint extends Vue {
  @Prop({ type: Boolean, default: false })
  readonly disabled?: boolean;

  @Prop({ type: String, required: true })
  readonly modelValue!: string;

  get model() {
    return this.modelValue;
  }

  set model(value: string) {
    this.$emit('update:modelValue', value);
  }
}
