import { CameraGroup, WatchList } from '@/api';
import { getViewModelByName } from '@/api/common';
import { ItemViewModel } from '@/definitions/view-models';
import { CameraGroupPermissions } from '@/api/models/CameraGroupPermissions';
import { WatchListPermissions } from '@/api/models/WatchListPermissions';

export const PermissionsModelNamesMap = {
  WatchLists: 'permissions_watch_lists',
  CameraGroups: 'permissions_camera_groups'
} as const;

export type PermissionModelName = typeof PermissionsModelNamesMap[keyof typeof PermissionsModelNamesMap];
const PermissionsField = 'permissions';

/**
 * Prevent permission sending on common entity endpoint
 * Save entity it's self
 * And then save permissions on special entity's permissions endpoint
 * todo: refactor or remove this huck when we get a time
 */
export async function saveItemAndPermissions(
  itemVM: ItemViewModel<CameraGroup | WatchList>,
  modelName: PermissionModelName,
  itemSave: () => Promise<CameraGroup | WatchList>
) {
  if (!itemVM.item) return;
  const changes = itemVM.changes;
  if (changes.includes(PermissionsField) && typeof itemVM.item.permissions !== 'undefined') {
    const permissions = itemVM.item.permissions;
    // setVmPermissions(itemVM, null); // todo: prevent blinking on permissions tab, uncomment if you get 400 error
    await itemSave();
    setVmPermissions(itemVM, permissions);
    const permissionsVM = getViewModelByName(modelName) as ItemViewModel<any>;
    permissionsVM.setItemsState({ id: itemVM.item.id, permissions });
    const permissionsPayload = await permissionsVM.save();
    setVmPermissions(itemVM, permissionsPayload.permissions);
  } else if (changes.length > 0) {
    delete itemVM.item[PermissionsField];
    await itemSave();
  }
  return itemVM.item;
}

export async function loadAndSyncPermissions(itemVM: ItemViewModel<CameraGroup | WatchList>, modelName: PermissionModelName) {
  if (!itemVM?.isNew && itemVM.item) {
    const permissionVM = getViewModelByName(modelName) as ItemViewModel<CameraGroupPermissions | WatchListPermissions>;
    await permissionVM.get(itemVM.item.id);
    permissionVM.item?.permissions && setVmPermissions(itemVM, permissionVM.item.permissions);
  }
}

function setVmPermissions(vm: ItemViewModel<CameraGroup | WatchList>, permissions: Record<string, any> | null) {
  if (permissions) {
    vm.item && (vm.item.permissions = { ...permissions });
    vm.originalItem && (vm.originalItem.permissions = { ...permissions });
  } else {
    vm.item && delete vm.item.permissions;
    vm.originalItem && delete vm.originalItem.permissions;
  }
}
