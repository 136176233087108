import { ISelectItem, NInputNumber, NSelect } from '@/uikit';
import NCheckbox from '@/uikit/checkbox/NCheckbox.vue';
import { IFormContext, IFormModel } from '@/uikit/forms/NForm.vue';
import { getRequiredValidator } from '@/uikit/forms/validators';
import NInput from '@/uikit/input/NInput.vue';
import { IFormLayoutBlocks } from '@/uikit/forms/NFormBlocks.vue';
import { getItemCommonFields } from '@/components/common/get-item-common-fields';
import { NewItemIdStart } from '@/store/multisidebar';
import ObjectThresholds from '@/components/common/ObjectThresholds.vue';
import { WatchList } from '@/api';
import { dataModule } from '@/store/data';

const cameraGroupInformation: IFormLayoutBlocks = [
  {
    layout: [
      {
        path: 'name',
        classes: 'label-m n-form-w-6 n-form-pad-10 camera-group-form-pad',
        i18n_label: 'common.name',
        component: NInput,
        validators: [getRequiredValidator()]
      },
      {
        path: 'comment',
        classes: 'label-m n-form-w-6 n-form-pad-10',
        i18n_label: 'common.comment',
        component: NInput
      },
      {
        classes: 'label-m n-form-w-6 n-form-pad-10',
        i18n_label: 'common.labels',
        component: NSelect,
        props: {
          multiple: true,
          allowCreate: true,
          keyField: '',
          i18n_placeholder: 'common.camera_groups_not_selected',
          loadItems: () => dataModule.cameraGroupsLabels.map((v) => ({ label: v, value: v }))
        },
        encode: function (this: IFormContext, model: IFormModel, value: ISelectItem[]) {
          let result: any = {};
          value.forEach((item) => {
            result[item.label] = 'true';
          });
          this.model.labels = result;
        },
        decode: function (this: IFormContext) {
          let value: ISelectItem[] = [];
          Object.keys(this.model?.labels || []).forEach((label, index) =>
            value.push({
              label: label,
              value: label
            })
          );
          return value;
        }
      },
      [
        {
          path: 'deduplicate',
          classes: 'label-m n-form-w-4 n-form-vcenter n-form-pad-10',
          component: NCheckbox,
          props: { i18n_label: 'common.deduplicate_events_with_interval' }
        },
        {
          path: 'deduplicateDelay',
          classes: 'label-m n-form-w-2 n-form-pad-10',
          component: NInputNumber,
          validators: [getRequiredValidator()]
        }
      ],
      {
        component: ObjectThresholds,
        classes: 'n-form-w-6 n-form-pad-20 control-m',
        props: (item: WatchList) => ({ item })
      }
    ]
  },
  {
    layout: getItemCommonFields(),
    display: (model: any) => {
      return model.id > NewItemIdStart;
    }
  }
];

export default cameraGroupInformation;
