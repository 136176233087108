
import { BodyEvent, CarCard, CarCardAttachment, CarEvent, FaceEvent, HumanCard, HumanCardAttachment } from '@/api';
import BatchLoaderModal from '@/pages/cards/content/BatchLoaderModal.vue';
import { getCardFormSchema } from '@/pages/cards/content/card-form-schema';
import { aclModule } from '@/store/acl';
import { CardsMultiToSingle, CardType } from '@/store/application/data.assets';
import { AttachmentModule } from '@/store/data/AttachmentModule';
import { NForm } from '@/uikit';
import NButton from '@/uikit/buttons/NButton.vue';
import { NAttachment, NAttachmentAttachDoneHandler, NAttachmentRemoveDoneHandler, NAttachments, NAttachmentsI18n } from '@/uikit/attachments';
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { getItemCommonFields } from '@/components/common/get-item-common-fields';
import { cardAssetsModule } from '@/store/cards/CardAssetsModule';
import { TabItemsMap } from '@/definitions/app/tab.items';
import EventItem from '@/components/events/EventItem.vue';
import NThumbnail from '@/uikit/thumbnail/NThumbnail.vue';

@Options({
  components: {
    NThumbnail,
    EventItem,
    BatchLoaderModal,
    NAttachments,
    NButton,
    NForm
  }
})
export default class CardForm extends Vue {
  @Prop({ type: Object, required: true })
  readonly item!: HumanCard | CarCard;

  @Prop({ type: Object, required: false })
  readonly event?: FaceEvent | BodyEvent | CarEvent;

  @Prop({ type: String, required: false })
  readonly objects?: string;

  @Prop({ type: Boolean, required: true })
  readonly disabled = false;

  @Prop({ type: String, required: true })
  readonly cardType!: CardType;

  attachments: NAttachment[] = [];
  batchLoader = false;

  get form() {
    return this.$refs.itemForm;
  }

  get infoFields() {
    return getItemCommonFields();
  }

  get attachmentModule() {
    return new AttachmentModule({
      url: `/${CardsMultiToSingle[this.cardType]}-card-attachments/`,
      source_id: this.item.id,
      source_form_field_name: 'card',
      source_filter_name: 'card'
    });
  }

  get attachmentLabels(): NAttachmentsI18n {
    return {
      attach_first_one: this.$t('common.attach_first_one'),
      attach_more: this.$t('common.attach_more'),
      cancel: this.$t('common.cancel', 'f'),
      files: this.$tm('common.files_mf', { count: this.attachments.length }),
      no_files: this.$t('common.no_files'),
      removing_confirmation: this.$t('common.removing_confirmation'),
      yes: this.$t('common.yes', 'f')
    };
  }

  get schema() {
    return getCardFormSchema(this.cardType);
  }

  get metaSchema() {
    const metaFormItems = cardAssetsModule.getMetaFormItemsByTab(TabItemsMap.Info, this.cardType);
    return metaFormItems;
  }

  get isCreated() {
    return this.item.id > 0;
  }

  get hasUploadPermission() {
    const object = CardsMultiToSingle[this.cardType];
    return aclModule.getAccess(`ffsecurity.change_${object}card`);
  }

  get hasBatchUploadPermissions() {
    return aclModule.getAccess('ffsecurity.batchupload_cards') && aclModule.getAccess('ffsecurity.add_uploadlist');
  }

  @Watch('item.id')
  changeItemHandler() {
    if (this.isCreated) this.attachmentModule.loadAttachments();
  }

  @Watch('attachmentModule.module.items', { immediate: true })
  handleAttachmentsChange(attachments: ReadonlyArray<CarCardAttachment | HumanCardAttachment>): void {
    this.attachments = attachments.map((attachment) => ({
      file: attachment.file,
      id: attachment.id,
      name: attachment.name ?? '',
      progress: 100,
      size: attachment.size,
      status: 'done'
    }));
  }

  mounted() {
    if (this.isCreated) this.attachmentModule.loadAttachments();
  }

  handleAttachmentAttach(attachment: NAttachment, done: NAttachmentAttachDoneHandler): void {
    this.attachmentModule.uploadAttachmentHandler(attachment).finally(done);
  }

  handleAttachmentRemove(attachment: NAttachment, done: NAttachmentRemoveDoneHandler): void {
    this.attachmentModule.deleteAttachmentHandler(attachment).finally(done);
  }
}
