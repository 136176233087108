import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "vms-sidebar-cameras-tab" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VmsSidebarCamerasTabFilters = _resolveComponent("VmsSidebarCamerasTabFilters")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_MultisidebarActions = _resolveComponent("MultisidebarActions")!
  const _component_VmsSidebarCamerasTabList = _resolveComponent("VmsSidebarCamerasTabList")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_VmsSidebarCamerasTabFilters, {
      "camera-groups": _ctx.cameraGroups,
      class: "vms-sidebar-cameras-tab__filters",
      added: _ctx.filters.added,
      "onUpdate:added": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filters.added) = $event)),
      "group-contains": _ctx.filters.groupContains,
      "onUpdate:group-contains": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.filters.groupContains) = $event)),
      "name-contains": _ctx.filters.nameContains,
      "onUpdate:name-contains": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.filters.nameContains) = $event))
    }, null, 8, ["camera-groups", "added", "group-contains", "name-contains"]),
    (_ctx.streams.length)
      ? (_openBlock(), _createBlock(_component_MultisidebarActions, { key: 0 }, {
          default: _withCtx(() => [
            _createVNode(_component_NButton, {
              label: _ctx.$t('external_vms.add_selected_cameras'),
              onClick: _ctx.createCameraFromSelectedStreams
            }, null, 8, ["label", "onClick"]),
            _createVNode(_component_NButton, {
              class: "vms-sidebar-cameras-tab__select-all",
              label: _ctx.$t('external_vms.select_all_streams'),
              onClick: _ctx.selectAll,
              icon: "select-all",
              type: "link"
            }, null, 8, ["label", "onClick"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_VmsSidebarCamerasTabList, {
      cameras: _ctx.cameras,
      onSelect: _ctx.createCameraFromSelectedStream,
      class: "vms-sidebar-cameras-tab__list"
    }, null, 8, ["cameras", "onSelect"])
  ]))
}