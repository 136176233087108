
import NInput from '@/uikit/input/NInput.vue';
import { defineComponent } from 'vue';
import { string } from 'vue-types';

export default defineComponent({
  name: 'CameraLookupSearch',
  components: { NInput },
  props: {
    search: defineSearchProp()
  },
  emits: ['update:search'],
  computed: {
    model: {
      get() {
        return this.search;
      },
      set(value: string) {
        this.$emit('update:search', value);
      }
    }
  }
});

function defineSearchProp() {
  return string().isRequired;
}
