import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CounterROI = _resolveComponent("CounterROI")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NModalWindow = _resolveComponent("NModalWindow")!

  return (_openBlock(), _createBlock(_component_NModalWindow, {
    "has-margins": false,
    layout: "",
    customSize: "90vw",
    "class-name": "counter-roi-modal"
  }, {
    default: _withCtx(() => [
      (_openBlock(), _createBlock(_component_CounterROI, {
        model: _ctx.model,
        cameraId: _ctx.cameraId,
        key: _ctx.cameraId,
        hasFullscreen: false,
        disabled: _ctx.disabled,
        class: "counter-roi-modal__item"
      }, null, 8, ["model", "cameraId", "disabled"])),
      (_ctx.hasArrows)
        ? (_openBlock(), _createBlock(_component_NButton, {
            key: 0,
            icon: "scroll-left",
            type: "link",
            class: "counter-roi-modal__arrow counter-roi-modal__arrow_left",
            onAction: _ctx.prev
          }, null, 8, ["onAction"]))
        : _createCommentVNode("", true),
      (_ctx.hasArrows)
        ? (_openBlock(), _createBlock(_component_NButton, {
            key: 1,
            icon: "scroll-right",
            type: "link",
            class: "counter-roi-modal__arrow counter-roi-modal__arrow_right",
            onAction: _ctx.next
          }, null, 8, ["onAction"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}