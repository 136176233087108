
import { defineComponent } from 'vue';
import { integer } from 'vue-types';

export default defineComponent({
  name: 'CameraLookupSummary',
  props: {
    summary: defineSummaryProp()
  }
});

function defineSummaryProp() {
  return integer().isRequired;
}
