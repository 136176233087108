/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GroupNameGuid } from '../models/GroupNameGuid';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';
export class AdGroupsService {
    /**
     * Get AD group-names and guids`
     * Use this method to get Active Directory groups and theirs guids
     * @returns GroupNameGuid 
     * @throws ApiError
     */
    public static adGroupsRetrieve(): CancelablePromise<GroupNameGuid[]> {
        return __request({
            method: 'GET',
            path: `/ad_groups/`,
        });
    }
}