import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "control-m ex-case-description__date" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["ex-case-description", _ctx.classes])
  }, [
    _createElementVNode("span", {
      class: "control-m ex-case-description__link",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.clickHandler && _ctx.clickHandler(...args)))
    }, _toDisplayString(_ctx.item.name), 1),
    _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.$filters.formatDate(_ctx.item.incident_date)), 1)
  ], 2))
}