
import { ExternalVms, ExternalVmsService, VmsPluginInfoRequest } from '@/api';
import MultisidebarHeader from '@/components/multisidebar/MultisidebarHeader.vue';
import MultisidebarSaveActions from '@/components/multisidebar/MultisidebarSaveActions.vue';
import ExternalVmsInfo from '@/pages/external-vms/components/ExternalVmsInfo.vue';
import { ItemViewModel } from '@/definitions/view-models';
import { ModelAclResult } from '@/store/acl/types';
import { actionHandler } from '@/store/data/ActionHandler';
import { actionsModule } from '@/store/data/ActionsModule';
import { MultisidebarItem, MultisidebarSharedState } from '@/store/multisidebar/types';
import NTabs from '@/uikit/tabs/NTabs.vue';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { VmsSidebarCamerasTab } from './components';
import ExternalVmsSentMessages from '@/pages/external-vms/components/ExternalVmsSentMessages.vue';
import ExternalVmsEvents from '@/pages/external-vms/components/ExternalVmsEvents.vue';
import { ItemsActionName } from '@/definitions/app/item.actions.name';
import { dataModule } from '@/store/data';
import { aclModule } from '@/store/acl';

export const ExternalVmsTabNames = {
  Info: 'info',
  Cameras: 'list_cameras',
  ReceivedMessages: 'receive_events',
  SendingMessages: 'send_events'
};

@Options({
  name: 'ExternalVmsSidebar',
  components: {
    MultisidebarSaveActions,
    NTabs,
    MultisidebarHeader,
    VmsSidebarCamerasTab
  },
  emits: ['action']
})
export default class ExternalVmsSidebar extends Vue {
  @Prop({ type: Object, required: true })
  readonly sidebarItem!: MultisidebarItem<ItemViewModel<ExternalVms>>;

  @Prop({ type: Object, required: true })
  readonly modelAcl!: ModelAclResult;

  @Prop({ type: Object, required: true })
  readonly sidebarSharedState!: MultisidebarSharedState;

  protected info: VmsPluginInfoRequest | null = null;

  get currentItem() {
    return this.module.item;
  }

  get currentItemConfig() {
    if (this.info && this.currentItem?.name && this.currentItem?.version) {
      return this.info.vms[this.currentItem.name].find((v: ExternalVms) => v.version === this.currentItem?.version);
    }
    return null;
  }

  get module(): ItemViewModel<ExternalVms> {
    return this.sidebarItem.model;
  }

  get headerActions() {
    return actionsModule
      .getItemActions(this.modelAcl, null, {
        hasChanges: this.module.hasChanges,
        hasDelete: !this.module.isNew
      })
      .map(actionsModule.computeActionByName);
  }

  get tabItems() {
    const tabs = [ExternalVmsTabNames.Info, ExternalVmsTabNames.Cameras];
    const hasEventViewAcl = aclModule.getAccess('ffsecurity.view_externalvmsevent');
    const hasSendEventStatusViewAcl = aclModule.getAccess('ffsecurity.view_externalvmssendeventstatus');

    if (hasEventViewAcl) tabs.push(ExternalVmsTabNames.ReceivedMessages);
    if (hasSendEventStatusViewAcl) tabs.push(ExternalVmsTabNames.SendingMessages);

    return tabs
      .filter((v) => v === ExternalVmsTabNames.Info || this.currentItemConfig?.features[v])
      .map((v) => ({
        name: v,
        label: this.$t(`external_vms.${v}`),
        disabled: this.module.isNew && v !== ExternalVmsTabNames.Info
      }));
  }

  get mainComponent() {
    switch (this.sidebarSharedState.activeTab) {
      case ExternalVmsTabNames.Cameras:
        return VmsSidebarCamerasTab;
      case ExternalVmsTabNames.SendingMessages:
        return ExternalVmsSentMessages;
      case ExternalVmsTabNames.ReceivedMessages:
        return ExternalVmsEvents;
      case ExternalVmsTabNames.Info:
      default:
        return ExternalVmsInfo;
    }
  }

  actionHandler(actionName: ItemsActionName, payload?: any) {
    actionHandler.run(actionName, payload);
  }

  async loadInfo() {
    this.info = await ExternalVmsService.externalVmsInfoRetrieve();
  }

  mounted() {
    this.loadInfo();
  }
}
