import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "object-thresholds" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NForm = _resolveComponent("NForm")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.togglerComponent), {
      label: _ctx.$t('common.confidence_threshold'),
      modelValue: _ctx.showConfidenceThresholds,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showConfidenceThresholds) = $event))
    }, null, 8, ["label", "modelValue"])),
    (_ctx.showConfidenceThresholds)
      ? (_openBlock(), _createBlock(_component_NForm, {
          key: 0,
          layout: _ctx.layout,
          model: _ctx.item
        }, null, 8, ["layout", "model"]))
      : _createCommentVNode("", true)
  ]))
}