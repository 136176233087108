import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "camera-lookup-window__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CameraLookup = _resolveComponent("CameraLookup")!
  const _component_NModalWindow = _resolveComponent("NModalWindow")!

  return (_openBlock(), _createBlock(_component_NModalWindow, {
    "insert-into-body": false,
    onClose: _ctx.dispatchCloseEvent,
    "custom-size": "1000px"
  }, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_CameraLookup, {
          authenticator: _ctx.authenticator,
          source: _ctx.source,
          onSelect: _ctx.dispatchSelectEvent
        }, null, 8, ["authenticator", "source", "onSelect"])
      ])
    ]),
    _: 1
  }, 8, ["onClose"]))
}