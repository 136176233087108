import { DefaultMultisidebarItemConfigKeys, MultisidebarItem, MultisidebarItemConfig, Optional } from './types';
import { markRaw } from 'vue';
import { ItemViewModel } from '@/definitions/view-models';
import {
  clusterAttributesLoader,
  episodeAttributesLoader,
  eventAttributesLoader,
  getModelAclByItemViewModel,
  itemViewModelLoader,
  itemViewModelWebsocketUpdateHandler
} from '@/store/multisidebar/helpers';
import { DefaultAclPrefix } from '@/definitions/common/base';
import { ActionsModuleOptionsNames } from '@/store/data/ActionsModule';
import EventsSidebar from '@/pages/events/EventsSidebar.vue';
import CardsSidebar from '@/pages/cards/CardsSidebar.vue';
import { cardAttributesLoader } from '@/store/cards/cardsAttributesLoader';
import ClustersSidebar from '@/pages/clusters/ClustersSidebar.vue';
import ExternalVmsSidebar from '@/pages/external-vms/ExternalVmsSidebar.vue';
import externalVmsAttributeLoader from '@/pages/external-vms/external-vms-attribute-loader';
import DataSourceSidebar from '@/pages/data-sources/DataSourceSidebar.vue';
import camerasAttributeLoader from '@/components/data-source/cameras-attribute-loader';
import camerasItemLoader from '@/components/data-source/cameras-item-loader';
import CounterSidebar from '@/pages/counters/CounterSidebar.vue';
import counterAttributeLoader from '@/pages/counters/attribute-loader';
import LinesSidebar from '@/pages/lines/LinesSidebar.vue';
import WebhooksSidebar from '@/pages/webhooks/WebhooksSidebar.vue';
import webhookAttributeLoader from '@/pages/webhooks/attribute-loader';
import UsersSidebar from '@/pages/users/UsersSidebar.vue';
import { userAttributesLoader, userItemLoader } from '@/pages/users/helpers';
import CameraGroupsSidebar from '@/pages/camera-groups/CameraGroupsSidebar.vue';
import cameraGroupItemLoader from '@/pages/camera-groups/camera-group-item-loader';
import WatchListsSidebar from '@/pages/watch-lists/WatchListsSidebar.vue';
import watchListItemLoader from '@/pages/watch-lists/watch-list-item-loader';
import GroupsSidebar from '@/pages/groups/GroupsSidebar.vue';
import groupItemLoader from '@/pages/groups/group-item-loader';
import { CameraGroup, User, WatchList } from '@/api';
import { ModelAclResult } from '@/store/acl/types';
import { aclModule } from '@/store/acl';
import { MultisidebarGroupNames, MultisidebarItemTypes } from '@/store/multisidebar/types';
import { episodeOrEventWebsocketUpdateHandler } from '@/pages/events/helpers';
import AuditLogsSidebar from '@/pages/audit-logs/AuditLogsSidebar.vue';

function getDefaultGlobalEventDelayTimeouts() {
  return {
    create: 100,
    update: -1,
    delete: 100
  };
}

const DefaultMultisidebarItemConfig: Pick<MultisidebarItemConfig<ItemViewModel<any>>, DefaultMultisidebarItemConfigKeys> = {
  itemLoader: itemViewModelLoader,
  aclPrefix: DefaultAclPrefix,
  getModelAcl: getModelAclByItemViewModel,
  websocketUpdateHandler: itemViewModelWebsocketUpdateHandler,
  globalEventDelayTimeouts: getDefaultGlobalEventDelayTimeouts()
};

const MultisidebarItemConfigs: Optional<MultisidebarItemConfig<any>, DefaultMultisidebarItemConfigKeys>[] = [
  {
    type: MultisidebarItemTypes.AuditLogs,
    groupName: MultisidebarGroupNames.AuditLogs,
    contentComponent: AuditLogsSidebar,
    itemAttributesLoader: (item) => ({ label: item.model.item?.id }),
    aclModelName: 'auditlog'
  },
  {
    type: MultisidebarItemTypes.EventsFaces,
    groupName: MultisidebarGroupNames.Events,
    groupActionsOptions: [ActionsModuleOptionsNames.hasSearch],
    contentComponent: EventsSidebar,
    itemAttributesLoader: eventAttributesLoader,
    websocketUpdateHandler: episodeOrEventWebsocketUpdateHandler,
    websocketUpdateProperties: ['eventUpdated', 'acknowledgedAll'],
    aclModelName: 'faceevent'
  },
  {
    type: MultisidebarItemTypes.EventsBodies,
    groupName: MultisidebarGroupNames.Events,
    contentComponent: EventsSidebar,
    itemAttributesLoader: eventAttributesLoader,
    websocketUpdateHandler: episodeOrEventWebsocketUpdateHandler,
    websocketUpdateProperties: ['eventUpdated', 'acknowledgedAll'],
    aclModelName: 'bodyevent'
  },
  {
    type: MultisidebarItemTypes.EventsCars,
    groupName: MultisidebarGroupNames.Events,
    contentComponent: EventsSidebar,
    itemAttributesLoader: eventAttributesLoader,
    websocketUpdateHandler: episodeOrEventWebsocketUpdateHandler,
    websocketUpdateProperties: ['eventUpdated', 'acknowledgedAll'],
    aclModelName: 'carevent'
  },
  {
    type: MultisidebarItemTypes.EpisodesHumans,
    groupName: MultisidebarGroupNames.Episode,
    contentComponent: EventsSidebar,
    itemAttributesLoader: episodeAttributesLoader,
    websocketUpdateHandler: episodeOrEventWebsocketUpdateHandler,
    websocketUpdateProperties: ['episode', 'episodeUpdated'],
    aclModelName: 'humanepisode'
  },
  {
    type: MultisidebarItemTypes.EpisodesCars,
    groupName: MultisidebarGroupNames.Episode,
    contentComponent: EventsSidebar,
    itemAttributesLoader: episodeAttributesLoader,
    websocketUpdateHandler: episodeOrEventWebsocketUpdateHandler,
    websocketUpdateProperties: ['episode', 'episodeUpdated'],
    aclModelName: 'carepisode'
  },
  {
    type: MultisidebarItemTypes.CardsHumans,
    groupName: MultisidebarGroupNames.Cards,
    groupActionsOptions: [ActionsModuleOptionsNames.hasDelete, ActionsModuleOptionsNames.hasActive, ActionsModuleOptionsNames.hasDelete],
    contentComponent: CardsSidebar,
    itemAttributesLoader: cardAttributesLoader,
    aclModelName: 'humancard'
  },
  {
    type: MultisidebarItemTypes.CardsCars,
    groupName: MultisidebarGroupNames.Cards,
    groupActionsOptions: [ActionsModuleOptionsNames.hasDelete, ActionsModuleOptionsNames.hasActive, ActionsModuleOptionsNames.hasDelete],
    contentComponent: CardsSidebar,
    itemAttributesLoader: cardAttributesLoader,
    aclModelName: 'carcard'
  },
  {
    type: MultisidebarItemTypes.ClustersFaces,
    groupName: MultisidebarGroupNames.Clusters,
    contentComponent: ClustersSidebar,
    groupActionsOptions: [ActionsModuleOptionsNames.hasDelete],
    itemAttributesLoader: clusterAttributesLoader,
    aclModelName: 'facecluster'
  },
  {
    type: MultisidebarItemTypes.ClustersBodies,
    groupName: MultisidebarGroupNames.Clusters,
    contentComponent: ClustersSidebar,
    groupActionsOptions: [ActionsModuleOptionsNames.hasDelete],
    itemAttributesLoader: clusterAttributesLoader,
    aclModelName: 'bodycluster'
  },
  {
    type: MultisidebarItemTypes.ClustersCars,
    groupName: MultisidebarGroupNames.Clusters,
    contentComponent: ClustersSidebar,
    groupActionsOptions: [ActionsModuleOptionsNames.hasDelete],
    itemAttributesLoader: clusterAttributesLoader,
    aclModelName: 'carcluster'
  },
  {
    type: MultisidebarItemTypes.ExternalVms,
    groupName: MultisidebarGroupNames.ExternalVms,
    groupActionsOptions: [ActionsModuleOptionsNames.hasDelete],
    contentComponent: ExternalVmsSidebar,
    itemAttributesLoader: externalVmsAttributeLoader,
    aclModelName: 'externalvms'
  },
  {
    type: MultisidebarItemTypes.Cameras,
    groupName: MultisidebarGroupNames.Cameras,
    contentComponent: DataSourceSidebar,
    itemAttributesLoader: camerasAttributeLoader,
    itemLoader: camerasItemLoader,
    aclModelName: 'camera',
    websocketUpdateProperties: ['cameraUpdated'],
    groupActionsOptions: [ActionsModuleOptionsNames.hasActive, ActionsModuleOptionsNames.hasCameraReset, ActionsModuleOptionsNames.hasDelete]
  },
  {
    /* todo: add change exclude fields in future version. List of fields for videos:
          'health_status',
          'finished',
          'progress',
          'face_count',
          'face_cluster_count',
          'body_count',
          'body_cluster_count',
          'processing_start_date',
          'source_len',
    */
    type: MultisidebarItemTypes.Videos,
    groupName: MultisidebarGroupNames.Videos,
    contentComponent: DataSourceSidebar,
    itemAttributesLoader: camerasAttributeLoader,
    aclModelName: 'videoarchive',
    websocketUpdateProperties: ['videoUpdated'],
    groupActionsOptions: [
      ActionsModuleOptionsNames.hasProcess,
      ActionsModuleOptionsNames.hasStopProcess,
      ActionsModuleOptionsNames.hasDeleteFile,
      ActionsModuleOptionsNames.hasDelete,
      ActionsModuleOptionsNames.hasCameraReset
    ]
  },
  {
    type: MultisidebarItemTypes.Counters,
    groupName: MultisidebarGroupNames.Counters,
    groupActionsOptions: [ActionsModuleOptionsNames.hasActive, ActionsModuleOptionsNames.hasDelete],
    contentComponent: CounterSidebar,
    itemAttributesLoader: counterAttributeLoader,
    aclModelName: 'counter'
  },
  {
    type: MultisidebarItemTypes.Lines,
    groupName: MultisidebarGroupNames.Lines,
    groupActionsOptions: [ActionsModuleOptionsNames.hasActive, ActionsModuleOptionsNames.hasDelete],
    contentComponent: LinesSidebar,
    itemAttributesLoader: (item) => ({ label: item.model.item?.name || '' }),
    aclModelName: 'line'
  },
  {
    type: MultisidebarItemTypes.Webhooks,
    groupName: MultisidebarGroupNames.Webhooks,
    contentComponent: WebhooksSidebar,
    itemAttributesLoader: webhookAttributeLoader,
    aclModelName: 'webhook',
    groupActionsOptions: [ActionsModuleOptionsNames.hasActive, ActionsModuleOptionsNames.hasDelete]
  },
  {
    type: MultisidebarItemTypes.Users,
    groupName: MultisidebarGroupNames.Users,
    contentComponent: UsersSidebar,
    itemLoader: userItemLoader,
    itemAttributesLoader: userAttributesLoader,
    aclModelName: 'user',
    groupActionsOptions: [ActionsModuleOptionsNames.hasDelete, ActionsModuleOptionsNames.hasActive]
  },
  {
    type: MultisidebarItemTypes.CameraGroups,
    groupName: MultisidebarGroupNames.CameraGroups,
    contentComponent: CameraGroupsSidebar,
    itemLoader: cameraGroupItemLoader,
    itemAttributesLoader: (item) => ({ label: item.model.item?.name }),
    aclModelName: 'cameragroup',
    getItemAcl: getCameraGroupItemAcl,
    groupActionsOptions: [ActionsModuleOptionsNames.hasActive],
    globalEventDelayTimeouts: { create: 1000, update: -1, delete: 100 }
  },
  {
    type: MultisidebarItemTypes.WatchLists,
    groupName: MultisidebarGroupNames.WatchLists,
    contentComponent: WatchListsSidebar,
    itemLoader: watchListItemLoader,
    itemAttributesLoader: (item) => ({ label: item.model.item?.name }),
    aclModelName: 'watchlist',
    getItemAcl: getWatchListItemAcl,
    groupActionsOptions: [ActionsModuleOptionsNames.hasActive, ActionsModuleOptionsNames.hasDelete, ActionsModuleOptionsNames.hasDeleteCard],
    globalEventDelayTimeouts: { create: 1000, update: -1, delete: 100 }
  },
  {
    type: MultisidebarItemTypes.Groups,
    groupName: MultisidebarGroupNames.Groups,
    contentComponent: GroupsSidebar,
    itemLoader: groupItemLoader,
    itemAttributesLoader: (item) => ({ label: item.model.item?.name }),
    aclPrefix: 'auth',
    aclModelName: 'group',
    groupActionsOptions: [ActionsModuleOptionsNames.hasDelete]
  }
];

function getWatchListItemAcl(item: MultisidebarItem<ItemViewModel<WatchList>>, modelAcl: ModelAclResult) {
  return getDefaultItemAcl(item.model.item?.id, 'watch_list_permissions', modelAcl);
}

function getCameraGroupItemAcl(item: MultisidebarItem<ItemViewModel<CameraGroup>>, modelAcl: ModelAclResult) {
  return getDefaultItemAcl(item.model.item?.id, 'camera_group_permissions', modelAcl);
}

function getDefaultItemAcl(id: number | undefined, propertyName: keyof User, modelAcl: ModelAclResult) {
  const maxAcl = { ...modelAcl, add: undefined };
  return id ? aclModule.getItemAclByUserProperty(id, propertyName, maxAcl) : maxAcl;
}

const EmbeddedMultisidebarItemConfigs: MultisidebarItemConfig<any>[] = MultisidebarItemConfigs.map((v) => ({ ...DefaultMultisidebarItemConfig, ...v }));

export const MultisidebarItemConfigsMap = Object.fromEntries(EmbeddedMultisidebarItemConfigs.map((item) => [item.type, markRaw(item)]));
export { EmbeddedMultisidebarItemConfigs as MultisidebarItemConfigs };
