
import { defineComponent, toRefs } from 'vue';
import { string } from 'vue-types';
import { useVmsSidebarCamerasTabListItemScreenshotViewModel } from './setup';

export default defineComponent({
  name: 'VmsSidebarCamerasTabListItemScreenshot',
  props: {
    screenshot: defineScreenshotProp()
  },
  setup: (props) => {
    const { screenshot } = toRefs(props);
    return useVmsSidebarCamerasTabListItemScreenshotViewModel(screenshot);
  }
});

function defineScreenshotProp() {
  return string().def('');
}
