
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import NFormBlocks from '@/uikit/forms/NFormBlocks.vue';
import { Line } from '@/api/models/Line';
import formLayoutBlocks from './schemes/form';
import { dataModule } from '@/store/data';

@Options({
  name: 'LinesForm',
  components: { NFormBlocks }
})
export default class LinesForm extends Vue {
  @Prop({ type: Object, required: true })
  readonly model!: Line;

  formLayoutBlocks = formLayoutBlocks;
  state = {
    sourceType: 'camera',
    cameraGroup: ''
  };

  validate() {
    return this.$refs.form.validateAndDisplayErrors();
  }

  loaded = false;
  async mounted() {
    await dataModule.camerasModule.firstLoadingPromise;
    await dataModule.videosModule.firstLoadingPromise;
    await dataModule.cameraGroupsModule.firstLoadingPromise;
    this.loaded = true;
  }
}
