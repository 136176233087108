import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "counter-roi-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CounterROI = _resolveComponent("CounterROI")!
  const _component_CounterROIModal = _resolveComponent("CounterROIModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cameras, (cameraId, index) => {
        return (_openBlock(), _createBlock(_component_CounterROI, {
          key: cameraId,
          model: _ctx.model,
          cameraId: cameraId,
          disabled: _ctx.disabled,
          onShowModal: ($event: any) => (_ctx.showModal(index))
        }, null, 8, ["model", "cameraId", "disabled", "onShowModal"]))
      }), 128))
    ]),
    (_ctx.modalEnabled)
      ? (_openBlock(), _createBlock(_component_CounterROIModal, {
          key: 0,
          model: _ctx.model,
          cameraStartIndex: _ctx.cameraStartIndex,
          disabled: _ctx.disabled,
          onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.modalEnabled = false))
        }, null, 8, ["model", "cameraStartIndex", "disabled"]))
      : _createCommentVNode("", true)
  ], 64))
}