import { IFormLayout, FormValidatorNames } from '@/uikit/forms/NForm.vue';
import NInput from '@/uikit/input/NInput.vue';
import NSelect from '@/uikit/select/NSelect.vue';
import NInputColor from '@/uikit/input-color/NInputColor.vue';
import NCheckbox from '@/uikit/checkbox/NCheckbox.vue';
import Permissions from '@/components/permissions/Permissions.vue';
import { configModule } from '@/store/config';
import { dataModule } from '@/store/data';
import { CameraGroup, WatchList } from '@/api';
import { getItemCommonFields } from '@/components/common/get-item-common-fields';
import NTextareaAutosize from '@/uikit/textarea/NTextareaAutosize.vue';
import { getRequiredValidator, getStringLengthValidator } from '@/uikit/forms/validators';
import ObjectThresholds from '@/components/common/ObjectThresholds.vue';

export function getInformationLayout(isNew = false) {
  return [...getTopLayout(), ...getBottomLayout(), ...(isNew ? [] : getItemCommonFields())];
}

export function getPermissionsLayout(): IFormLayout {
  return [
    {
      path: 'permissions',
      classes: 'n-form-w-6',
      component: Permissions,
      props: { disabledIds: [1] }
    }
  ];
}

function getTopLayout() {
  return [
    [
      {
        path: 'name',
        classes: 'grow-1 n-form-pad-10 control-m',
        i18n_label: 'common.name',
        component: NInput,
        validators: [getRequiredValidator(), getStringLengthValidator({ required: true, max: 256 })]
      },
      {
        path: 'color',
        classes: 'n-form-pad-10 control-m n-form-ml-1',
        i18n_label: 'common.color',
        component: NInputColor,
        validators: [{ name: FormValidatorNames.Required }]
      }
    ],
    {
      path: 'camera_groups',
      classes: 'n-form-w-6 n-form-pad-20 control-m',
      i18n_label: 'common.camera_groups',
      component: NSelect,
      props: {
        i18n_placeholder: 'common.camera_groups_not_selected',
        items: dataModule.cameraGroupsModule.items.map((v: CameraGroup) => ({ label: v.name, value: v.id })),
        multiple: true
      }
    },
    {
      path: 'comment',
      classes: 'n-form-w-6 n-form-pad-20 control-m',
      i18n_label: 'common.comment',
      component: NTextareaAutosize
    },
    {
      component: ObjectThresholds,
      classes: 'n-form-w-6 n-form-pad-20 control-m',
      props: (item: WatchList) => ({ item })
    }
  ];
}

function getBottomLayout() {
  const acknowledge = {
    path: 'acknowledge',
    classes: 'label-m n-form-w-4 n-form-vcenter n-form-block-start',
    component: NCheckbox,
    props: { i18n_label: 'common.require_event_acknowledgement' }
  };

  const ignoreEvents = {
    path: 'ignore_events',
    classes: 'label-m n-form-w-4 n-form-vcenter n-form-pad-10',
    component: NCheckbox,
    props: { i18n_label: 'common.dont_create_events' }
  };

  const notify = {
    path: 'notify',
    classes: 'label-m n-form-w-4 n-form-vcenter n-form-pad-10',
    component: NCheckbox,
    props: { i18n_label: 'common.enable_sound_alert' }
  };

  const sendEvents = {
    path: 'send_events_to_external_vms',
    classes: 'label-m n-form-w-4 n-form-vcenter n-form-pad-10',
    props: (item: WatchList) => ({ i18n_label: 'common.send_events_to_external_vms', disabled: item.id === -1 }),
    component: NCheckbox
  };

  const active = {
    path: 'active',
    classes: 'label-m n-form-w-4 n-form-vcenter n-form-block-start',
    props: (item: WatchList) => ({ i18n_label: 'common.watch_list_active', disabled: item.id === -1 }),
    component: NCheckbox
  };

  const hasEvents = !configModule.config.extra_options?.includes('hide_events') ?? true;
  const hasExternalVMS = configModule.config.external_vms.enabled;
  return hasEvents ? [acknowledge, ignoreEvents, notify, hasExternalVMS && sendEvents, active] : [acknowledge, notify, active];
}
