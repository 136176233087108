import { IFormContext, IFormLayout, IFormModel } from '@/uikit/forms/NForm.vue';
import NTextDelimiter from '@/uikit/text/NTextDelimiter.vue';
import { NButton, NInputNumber } from '@/uikit';
import { getNumberValidator } from '@/uikit/forms/validators';
import NCheckbox from '@/uikit/checkbox/NCheckbox.vue';
import NSwitch from '@/uikit/switch/NSwitch.vue';
import { CameraDetectorType, cameraResetModule, CameraResetType } from '@/store/camera/camera.reset.module';
import { ObjectsSingleToMulti } from '@/store/application/data.assets';
import ObjectThreshold from '@/components/common/ObjectThreshold.vue';

export default function getAnalyticsLayout(type: string, isCamera = false): IFormLayout {
  const commonFields = [
    [
      {
        name: type,
        classes: 'label-m n-form-w-3 n-form-label-horizontal-50 n-form-pad-10',
        component: NSwitch,
        encode: function (this: IFormContext, model: IFormModel, value: boolean) {
          cameraResetModule.toggleDetector(this.model, type as CameraDetectorType);
          this.state.activeTab = 'general';
        },
        decode: function (this: IFormContext) {
          return !!this.model.stream_settings.detectors[type];
        },
        props: (model: boolean) => ({ i18n_label: 'ds.enabled' })
      },
      {
        classes: 'label-m n-form-w-150 n-form-label-horizontal-50 n-form-pad-10',
        component: NButton,
        on: {
          action(this: any) {
            cameraResetModule.reset(this.model, `${type}_detector` as CameraResetType);
          }
        },
        props: (model: boolean) => ({ i18n_label: `ds.reset_${type}_detector`, type: 'link' })
      }
    ],
    {
      classes: 'label-m n-form-w-6 n-form-pad-10',
      component: NTextDelimiter,
      props: { i18n_label: 'ds.sections.filter' }
    },
    [
      {
        path: `stream_settings.detectors.${type}.filter_min_size`,
        i18n_label: 'ds.params.filter_min_size',
        i18n_tooltip: 'ds.params.filter_min_size_desc',
        classes: 'label-m n-form-w-150 n-form-label-horizontal-50 n-form-pad-10',
        validators: [getNumberValidator({ required: true, min: 0, max: 100000 })],
        component: NInputNumber,
        props: { i18n_units: 'ds.px', textAlign: 'right', min: 0 }
      },
      {
        path: `stream_settings.detectors.${type}.filter_max_size`,
        i18n_label: 'ds.params.filter_max_size',
        i18n_tooltip: 'ds.params.filter_max_size_desc',
        classes: 'label-m n-form-w-150 n-form-label-horizontal-0 n-form-pad-10',
        validators: [getNumberValidator({ required: true, min: 0, max: 100000 })],
        component: NInputNumber,
        props: { i18n_units: 'ds.px', textAlign: 'right', min: 0 }
      },
      {
        path: `stream_settings.detectors.${type}.filter_min_quality`,
        i18n_label: 'ds.params.filter_min_quality',
        i18n_tooltip: `ds.params.filter_min_quality_${ObjectsSingleToMulti[type]}_desc`,
        classes: 'label-m n-form-w-150 n-form-label-horizontal-50 n-form-pad-10',
        validators: [getNumberValidator({ required: true, min: 0, max: 1, float: true })],
        component: NInputNumber,
        props: { min: 0, max: 1, step: 0.05, textAlign: 'right' }
      }
    ],
    {
      classes: 'label-m n-form-w-6 n-form-pad-10',
      component: NTextDelimiter,
      props: { i18n_label: 'ds.sections.compression' }
    },
    {
      path: `stream_settings.detectors.${type}.jpeg_quality`,
      i18n_label: 'ds.params.jpeg_quality',
      i18n_tooltip: 'ds.params.jpeg_quality_desc',
      classes: 'label-m n-form-w-2 n-form-label-horizontal-100 n-form-pad-10',
      validators: [getNumberValidator({ required: true, min: 0, max: 100 })],
      component: NInputNumber,
      props: { min: 0, max: 100, step: 1, textAlign: 'right' }
    },
    {
      path: `stream_settings.detectors.${type}.fullframe_use_png`,
      classes: 'label-m n-form-w-6 n-form-pad-10',
      component: NCheckbox,
      props: {
        i18n_label: 'ds.params.fullframe_use_png'
      }
    },
    {
      classes: 'label-m n-form-w-6 n-form-pad-10',
      component: NTextDelimiter,
      props: { i18n_label: 'ds.sections.tracking' }
    },
    {
      path: `stream_settings.detectors.${type}.track_overlap_threshold`,
      i18n_label: 'ds.params.track_overlap_threshold',
      i18n_tooltip: 'ds.params.track_overlap_threshold_desc',
      classes: 'label-m n-form-w-3 n-form-label-horizontal-200 n-form-pad-10',
      validators: [getNumberValidator({ required: true, min: 0, max: 1, float: true })],
      component: NInputNumber,
      props: { min: 0, max: 1, step: 0.02, textAlign: 'right' }
    },
    {
      path: `stream_settings.detectors.${type}.track_max_duration_frames`,
      i18n_label: 'ds.params.track_max_duration_frames',
      i18n_tooltip: 'ds.params.track_max_duration_frames_desc',
      classes: 'label-m n-form-w-3 n-form-label-horizontal-200 n-form-pad-10',
      validators: [getNumberValidator({ required: true, min: 0, max: 1e6 })],
      component: NInputNumber,
      props: { min: 0, textAlign: 'right' }
    },
    {
      path: `stream_settings.detectors.${type}.track_miss_interval`,
      i18n_label: 'ds.params.track_miss_interval',
      i18n_tooltip: 'ds.params.track_miss_interval_desc',
      classes: 'label-m n-form-w-3 n-form-label-horizontal-200 n-form-pad-10',
      validators: [getNumberValidator({ required: true, min: 0, max: 1e6 })],
      component: NInputNumber,
      props: { min: 0, i18n_units: 'ds.sec', textAlign: 'right' }
    },
    {
      path: `stream_settings.detectors.${type}.track_send_history`,
      i18n_tooltip: 'ds.params.track_send_history_desc',
      classes: 'label-m n-form-w-full n-form-label-horizontal-100 n-form-pad-10',
      component: NCheckbox,
      props: {
        i18n_label: 'ds.params.track_send_history'
      }
    },
    {
      classes: 'label-m n-form-w-6 n-form-pad-10',
      component: NTextDelimiter,
      props: { i18n_label: 'ds.sections.rot' }
    },
    {
      path: `stream_settings.detectors.${type}.fullframe_crop_rot`,
      i18n_tooltip: 'ds.params.fullframe_crop_rot_desc',
      classes: 'label-m n-form-w-2 n-form-label-horizontal-100 n-form-pad-10',
      component: NCheckbox,
      props: {
        i18n_label: 'ds.params.fullframe_crop_rot'
      }
    },
    {
      classes: 'label-m n-form-w-6 n-form-pad-10',
      component: NTextDelimiter,
      props: { i18n_label: 'ds.sections.posting' }
    },
    {
      path: `stream_settings.detectors.${type}.overall_only`,
      i18n_tooltip: 'ds.params.overall_desc',
      classes: 'label-m n-form-label-horizontal-100 n-form-pad-10',
      component: NCheckbox,
      props: {
        i18n_label: 'ds.params.overall_only'
      }
    },
    {
      path: `stream_settings.detectors.${type}.realtime_post_interval`,
      i18n_label: 'ds.params.realtime_post_interval',
      i18n_tooltip: 'ds.params.realtime_post_interval_desc',
      classes: 'label-m n-form-w-2 n-form-label-horizontal-100 n-form-pad-10',
      validators: [getNumberValidator({ required: true, min: 0, max: 1e6, float: true })],
      component: NInputNumber,
      props: { min: 0, i18n_units: 'ds.sec', textAlign: 'right' }
    },
    [
      {
        path: `stream_settings.detectors.${type}.realtime_post_first_immediately`,
        i18n_tooltip: 'ds.params.realtime_post_first_immediately_desc',
        classes: 'label-m n-form-w-3 n-form-label-horizontal-100 n-form-pad-10',
        component: NCheckbox,
        props: {
          i18n_label: 'ds.params.realtime_post_first_immediately'
        }
      },
      {
        path: `stream_settings.detectors.${type}.realtime_post_every_interval`,
        i18n_tooltip: 'ds.params.realtime_post_every_interval_desc',
        classes: 'label-m n-form-w-3 n-form-label-horizontal-100 n-form-pad-10',
        component: NCheckbox,
        props: {
          i18n_label: 'ds.params.realtime_post_every_interval'
        }
      }
    ],
    [
      {
        path: `stream_settings.detectors.${type}.post_first_track_frame`,
        i18n_tooltip: 'ds.params.post_first_track_frame_desc',
        classes: 'label-m n-form-w-3 n-form-label-horizontal-100 n-form-pad-10',
        component: NCheckbox,
        props: {
          i18n_label: 'ds.params.post_first_track_frame'
        }
      },
      {
        path: `stream_settings.detectors.${type}.post_last_track_frame`,
        i18n_tooltip: 'ds.params.post_last_track_frame_desc',
        classes: 'label-m n-form-w-3 n-form-label-horizontal-100 n-form-pad-10',
        component: NCheckbox,
        props: {
          i18n_label: 'ds.params.post_last_track_frame'
        }
      }
    ]
    // [
    //   {
    //     path: `stream_settings.detectors.${type}.post_best_track_normalize`,
    //     classes: 'label-m n-form-w-3 n-form-label-horizontal-100 n-form-pad-10',
    //     component: NCheckbox,
    //     props: {
    //       i18n_label: 'ds.params.post_best_track_normalize'
    //     }
    //   },
    //   {
    //     path: `stream_settings.detectors.${type}.post_best_track_frame`,
    //     classes: 'label-m n-form-w-3 n-form-label-horizontal-100 n-form-pad-10',
    //     component: NCheckbox,
    //     props: {
    //       i18n_label: 'ds.params.post_best_track_frame'
    //     }
    //   }
    // ]
  ];

  const thresholdField = {
    path: `${type}_threshold`,
    i18n_tooltip: `ds.params.confidence_${type}_desc`,
    classes: 'label-m n-form-w-6 n-form-pad-10',
    component: ObjectThreshold,
    props: {
      objectType: type
    }
  };

  return isCamera ? [...commonFields, thresholdField] : commonFields;
}
