import { itemViewModelLoader } from '@/store/multisidebar/helpers';

export default async function (...args: any[]) {
  // @ts-ignore
  const defaultItem = await itemViewModelLoader(...args);
  const defaultSave = defaultItem.model.save;
  defaultItem.model.save = async function () {
    if (this.item.external_detector) {
      delete this.item.url;
    }
    return defaultSave.apply(defaultItem.model);
  };
  return defaultItem;
}
