import { AccessPermissionGroup, Plugin } from '../types';

export const PluginAccessPermissionGroups: Record<Plugin, AccessPermissionGroup[]> = {
  incidents: [
  ],
  puppeteer: [
    {
      add: 'ffsecurity_puppeteer.add_dailysearchevent',
      view: 'ffsecurity_puppeteer.view_dailysearchevent',
      change: 'ffsecurity_puppeteer.change_dailysearchevent',
      delete: 'ffsecurity_puppeteer.delete_dailysearchevent',
      target: 'dailysearchevent'
    },
    {
      add: 'ffsecurity_puppeteer.add_dailysearchsyncjob',
      view: 'ffsecurity_puppeteer.view_dailysearchsyncjob',
      change: 'ffsecurity_puppeteer.change_dailysearchsyncjob',
      delete: 'ffsecurity_puppeteer.delete_dailysearchsyncjob',
      target: 'dailysearchsyncjob'
    },
    {
      add: 'ffsecurity_puppeteer.add_remotemonitoringrecord',
      view: 'ffsecurity_puppeteer.view_remotemonitoringrecord',
      change: 'ffsecurity_puppeteer.change_remotemonitoringrecord',
      delete: 'ffsecurity_puppeteer.delete_remotemonitoringrecord',
      target: 'remotemonitoringrecord'
    },
    {
      add: 'ffsecurity_puppeteer.add_searchrequest',
      view: 'ffsecurity_puppeteer.view_searchrequest',
      change: 'ffsecurity_puppeteer.change_searchrequest',
      delete: 'ffsecurity_puppeteer.delete_searchrequest',
      target: 'searchrequest'
    }
  ]
};

export const Plugins: Plugin[] = ['incidents', 'puppeteer'];
