import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "vms-sidebar-cameras-tab-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VmsSidebarCamerasTabListItem = _resolveComponent("VmsSidebarCamerasTabListItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cameras, (camera) => {
      return (_openBlock(), _createBlock(_component_VmsSidebarCamerasTabListItem, {
        key: camera.id,
        camera: camera,
        onSelect: _ctx.dispatchSelectEvent,
        class: "vms-sidebar-cameras-tab-list__item"
      }, null, 8, ["camera", "onSelect"]))
    }), 128))
  ]))
}