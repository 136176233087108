import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "external-vms-select" }
const _hoisted_2 = { class: "external-vms-select__selector" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NSelect = _resolveComponent("NSelect")!
  const _component_NButton = _resolveComponent("NButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_NSelect, {
        "clear-icon": _ctx.hasRealVms,
        "model-value": _ctx.modelValue,
        items: _ctx.options,
        selectonly: "",
        "onUpdate:modelValue": _ctx.updateValue
      }, null, 8, ["clear-icon", "model-value", "items", "onUpdate:modelValue"])
    ]),
    (_ctx.hasRealVms)
      ? (_openBlock(), _createBlock(_component_NButton, {
          key: 0,
          type: "link",
          onAction: _ctx.showVms
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('external_vms.configure')), 1)
          ]),
          _: 1
        }, 8, ["onAction"]))
      : _createCommentVNode("", true)
  ]))
}