
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { NCheckbox, NSwitch } from '@/uikit';
import NConfidence from '@/uikit/confidence/NConfidence.vue';
import { ObjectType } from '@/store/application/data.assets';
import { settingsItemModule } from '@/store/data/SettingsItemModule';
import { CameraGroup, WatchList } from '@/api';
import { dataAssetsModule } from '@/store/application/data.assets.module';
import NForm from '@/uikit/forms/NForm.vue';

type Item = WatchList | CameraGroup;
type ItemThresholdField = `${ObjectType}_threshold`;
type SettingsThresholdField = `${ObjectType}_confidence_threshold`;

const DefaultFallbackThreshold = 0.75;
const SettingConfidenceThresholdsMap: Record<ItemThresholdField, SettingsThresholdField> = {
  face_threshold: 'face_confidence_threshold',
  body_threshold: 'body_confidence_threshold',
  car_threshold: 'car_confidence_threshold'
};

@Options({
  name: 'ObjectThresholds',
  components: { NForm, NConfidence, NCheckbox, NSwitch }
})
export default class ObjectThresholds extends Vue {
  @Prop({ type: Object, required: true })
  readonly item!: Item;

  @Prop({ type: Array, default: () => ['face', 'body', 'car'] })
  readonly objectTypes!: ObjectType[];

  @Prop({ type: String, default: 'switch' })
  readonly togglerType!: 'checkbox' | 'switch';

  get togglerComponent() {
    return this.togglerType === 'switch' ? 'NSwitch' : 'NCheckbox';
  }

  get layout() {
    const layoutItems = [
      {
        path: 'face_threshold',
        classes: 'label-m n-form-w-2 n-form-label-horizontal-100 n-form-pad-10',
        i18n_label: 'common.faces',
        component: NConfidence,
        i18n_tooltip: 'common.confidence_desc',
        props: { controls: true },
        hidden: () => !dataAssetsModule.isObjectAvailable('face')
      },
      {
        path: 'body_threshold',
        classes: 'label-m n-form-w-2 n-form-label-horizontal-100 n-form-pad-10',
        i18n_label: 'common.bodies',
        component: NConfidence,
        i18n_tooltip: 'common.confidence_desc',
        props: { controls: true },
        hidden: () => !dataAssetsModule.isObjectAvailable('body')
      },
      {
        path: 'car_threshold',
        classes: 'label-m n-form-w-2 n-form-label-horizontal-100 n-form-pad-10',
        i18n_label: 'common.cars',
        component: NConfidence,
        i18n_tooltip: 'common.confidence_desc',
        props: { controls: true },
        hidden: () => !dataAssetsModule.isObjectAvailable('car')
      }
    ];

    return layoutItems.filter((v) => this.fields.includes(v.path as ItemThresholdField));
  }

  get settings() {
    return settingsItemModule.item;
  }
  get fields() {
    return this.objectTypes.filter((v) => dataAssetsModule.availableObjectsMap[v]).map((v) => `${v}_threshold` as ItemThresholdField);
  }

  get showConfidenceThresholds() {
    return this.fields.some((v) => this.item[v] !== null && this.item[v] !== 0);
  }

  set showConfidenceThresholds(value: boolean) {
    for (let field of this.fields) {
      this.item[field] = value ? this.settings?.[SettingConfidenceThresholdsMap[field]] ?? DefaultFallbackThreshold : null;
    }
  }
}
