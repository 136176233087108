
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { MultisidebarItem, MultisidebarItemTypes, MultisidebarSharedState } from '@/store/multisidebar/types';
import { ModelAclResult } from '@/store/acl/types';
import { ItemViewModel, ListViewModel } from '@/definitions/view-models';
import { EnrichedCard } from '@/components/cards/enrich-cards';
import { cardAssetsModule } from '@/store/cards/CardAssetsModule';
import { TabItemsMap } from '@/definitions/app/tab.items';
import { CardType, CardTypesMap, ObjectsType, ObjectsTypesMap } from '@/store/application/data.assets';
import { dataAssetsModule } from '@/store/application/data.assets.module';
import { RemoteMonitoringRecord } from '@/api/models/RemoteMonitoringRecord';
import { Action, NForm, NTabs } from '@/uikit';
import { actionsModule } from '@/store/data/ActionsModule';
import { loadDataModule } from '@/store/data/LoadDataModule';
import { viewModelRepository } from '@/api/common';
import { AclModelNames, aclModule } from '@/store/acl';
import CaseCardsView from '@/pages/cards/content/CaseCardsView.vue';
import CardMonitoringStatus from '@/pages/cards/monitoring/CardMonitoringStatus.vue';
import ItemsView from '@/pages/cards/content/ItemsView.vue';
import CardForm from '@/pages/cards/content/CardForm.vue';
import MultisidebarHeader from '@/components/multisidebar/MultisidebarHeader.vue';
import DetectionDialog from '@/components/detection/DetectionDialog.vue';
import { monitoringModule } from '@/pages/cards/monitoring/MonitoringModule';
import { actionHandler } from '@/store/data/ActionHandler';
import { ItemsActionName, ItemsActionNames } from '@/definitions/app/item.actions.name';
import { globalEventModule } from '@/store/global-event';
import MultisidebarSaveActions from '@/components/multisidebar/MultisidebarSaveActions.vue';
import { BodyEvent, CarCard, CarEvent, FaceEvent, HumanCard } from '@/api';
import CardsDuplicateDialog from '@/components/cards/CardsDuplicateDialog.vue';
import { BboxConfig } from '@/uikit/bbox/types';
import { RouterModule } from '@/store/router';
import { cardPhotoUploaderModule } from '@/store/cards/CardPhotoUploaderModule';
import EventItem from '@/components/events/EventItem.vue';

type CardsDuplicate = {
  cards: HumanCard[] | CarCard[];
  thumbnail?: string;
  detection: BboxConfig[];
};

@Options({
  name: 'CardsSidebar',
  components: {
    EventItem,
    CardsDuplicateDialog,
    MultisidebarSaveActions,
    CaseCardsView,
    CardMonitoringStatus,
    ItemsView,
    CardForm,
    MultisidebarHeader,
    NTabs,
    NForm,
    DetectionDialog
  }
})
export default class CardsSidebar extends Vue {
  @Prop({ type: Object, required: true })
  readonly sidebarItem!: MultisidebarItem<ItemViewModel<HumanCard | CarCard>>;

  @Prop({ type: Object, required: true })
  readonly sidebarSharedState!: MultisidebarSharedState;

  @Prop({ type: Object })
  readonly modelAcl!: ModelAclResult;

  showDetectionDialog = false;
  file = null;

  faceObjectsVM: ListViewModel<any, any> | null = null;
  bodyObjectsVM: ListViewModel<any, any> | null = null;
  carObjectsVM: ListViewModel<any, any> | null = null;
  faceClusterEventsVM: ListViewModel<any, any> | null = null;
  bodyClusterEventsVM: ListViewModel<any, any> | null = null;
  carClusterEventsVM: ListViewModel<any, any> | null = null;
  participantsVM: ListViewModel<any, any> | null = null;

  items: EnrichedCard[] = [];

  get disabled() {
    return !(this.module.isNew ? this.modelAcl.add : this.modelAcl.update);
  }

  get TabItemsMap() {
    return TabItemsMap;
  }

  get tabsItems(): any[] {
    const items = cardAssetsModule.getCardTabsItems(this.cardType);
    return this.currentItem && this.currentItem.id > 0 ? items : items.slice(0, 1);
  }

  get currentItem() {
    return this.module.item;
  }

  get module(): ItemViewModel<HumanCard | CarCard> {
    return this.sidebarItem.model;
  }

  get cardType() {
    return this.sidebarItem.type.split('_')[1];
  }

  get itemsSortTypes() {
    return dataAssetsModule.getSortTypes({ id: true }).map((v) => ({ ...v, label: this.$t(v.i18n_label) }));
  }

  get isMetaTab(): boolean {
    return !!cardAssetsModule.getMetaTabs(this.cardType).find((v) => v.value === this.sidebarSharedState.activeTab);
  }

  get metaTabFormSchema() {
    return this.isMetaTab && this.sidebarSharedState.activeTab
      ? cardAssetsModule.getMetaFormItemsByTab(this.sidebarSharedState.activeTab, this.cardType)
      : null;
  }

  get monitoringModule() {
    return monitoringModule;
  }

  get isPuppeteerEnabled() {
    return this.cardType === CardTypesMap.Humans && this.monitoringModule.isPuppeteerEnabled;
  }

  get currentItemMonitoringRecord(): RemoteMonitoringRecord | null {
    let record = this.isPuppeteerEnabled && this.currentItem && this.monitoringModule.getItemsByCardId(this.currentItem.id)[0];
    return record || null;
  }

  get headerActions(): Action[] {
    return actionsModule
      .getItemActions(this.modelAcl, null, {
        hasSearch: true,
        hasCardFilterEvent: true,
        hasActive: true,
        hasDelete: true,
        currentActive: this.currentItem?.active,
        hasPuppeteer: true,
        hasChanges: this.module.hasChanges,
        hasInteractions: this.cardType === CardTypesMap.Humans,
        hasPuppeteerMonitoringRecord: !!this.currentItemMonitoringRecord
      })
      .map(actionsModule.computeActionByName);
  }

  get objectType() {
    const objectName = this.sidebarSharedState.activeTab?.split('_')[0] as string;
    return { [objectName]: dataAssetsModule.detectFeatures[objectName] };
  }

  get fromEventModel(): ItemViewModel<FaceEvent | BodyEvent | CarEvent> | undefined {
    const additionalDataFrom: MultisidebarItem<ItemViewModel<FaceEvent | BodyEvent | CarEvent>> = this.module.additionalData?.from;
    const isEvent = [MultisidebarItemTypes.EventsFaces, MultisidebarItemTypes.EventsBodies, MultisidebarItemTypes.EventsCars].includes(
      additionalDataFrom?.type as any
    );
    return isEvent ? additionalDataFrom.model : undefined;
  }

  get fromEventObjectType(): string | undefined {
    const additionalDataFrom: MultisidebarItem<ItemViewModel<FaceEvent | BodyEvent | CarEvent>> = this.module.additionalData?.from;
    return additionalDataFrom?.type ? additionalDataFrom.type.split('_')[1] : undefined;
  }

  @Watch('currentItem')
  loadCurrentItemModules() {
    const { id, face_cluster, body_cluster, car_cluster } = (this.currentItem || {}) as any;
    this.syncActiveTabById(id);
    if (!id || id <= 0) return;
    switch (this.cardType) {
      case CardTypesMap.Humans:
        this.faceObjectsVM = loadDataModule.getObjectsLVMByCardID({ id, type: 'faces', autoload: false });
        this.faceClusterEventsVM = loadDataModule.getClusterEventsLVMByClusterID({ id: face_cluster, type: 'faces', autoload: false });
        this.bodyObjectsVM = loadDataModule.getObjectsLVMByCardID({ id, type: 'bodies', autoload: false });
        this.bodyClusterEventsVM = loadDataModule.getClusterEventsLVMByClusterID({ id: body_cluster, type: 'bodies', autoload: false });
        this.participantsVM = viewModelRepository.getCaseParticipantsListViewModel();
        this.participantsVM.filter.current.limit = 1000;
        this.participantsVM.filter.current.ordering = '-id';
        this.participantsVM.filter.current.related_human_card = this.currentItem?.id;
        break;

      case CardTypesMap.Cars:
        this.carObjectsVM = loadDataModule.getObjectsLVMByCardID({ id, type: 'cars', autoload: false });
        this.carClusterEventsVM = loadDataModule.getClusterEventsLVMByClusterID({ id: car_cluster, type: 'cars', autoload: false });
        break;
    }
  }

  @Watch('currentItem.id')
  syncActiveTabById(v: number) {
    if (v < -1000) this.sidebarSharedState.activeTab = TabItemsMap.Info;
  }

  get itemsModule() {
    switch (this.sidebarSharedState.activeTab) {
      case TabItemsMap.FaceObjects:
        return this.faceObjectsVM;
      case TabItemsMap.BodyObjects:
        return this.bodyObjectsVM;
      case TabItemsMap.CarObjects:
        return this.carObjectsVM;
      case TabItemsMap.FaceClusterEvents:
        return this.faceClusterEventsVM;
      case TabItemsMap.BodyClusterEvents:
        return this.bodyClusterEventsVM;
      case TabItemsMap.CarClusterEvents:
        return this.carClusterEventsVM;
      case TabItemsMap.HumanParticipants:
        return this.participantsVM;
    }
    return null;
  }

  get objectsTypeByTab(): ObjectsType | undefined {
    switch (this.sidebarSharedState.activeTab) {
      case TabItemsMap.FaceObjects:
        return ObjectsTypesMap.Faces;
      case TabItemsMap.BodyObjects:
        return ObjectsTypesMap.Bodies;
      case TabItemsMap.CarObjects:
        return ObjectsTypesMap.Cars;
    }
    return undefined;
  }

  get tabModelAcl() {
    switch (this.sidebarSharedState.activeTab) {
      case TabItemsMap.FaceObjects:
        return aclModule.getModelAclByName(AclModelNames.FaceObject);
      case TabItemsMap.BodyObjects:
        return aclModule.getModelAclByName(AclModelNames.BodyObject);
      case TabItemsMap.CarObjects:
        return aclModule.getModelAclByName(AclModelNames.CarObject);
      case TabItemsMap.FaceClusterEvents:
        return aclModule.getModelAclByName(AclModelNames.FaceCluster);
      case TabItemsMap.BodyClusterEvents:
        return aclModule.getModelAclByName(AclModelNames.BodyCluster);
      case TabItemsMap.CarClusterEvents:
        return aclModule.getModelAclByName(AclModelNames.CarCluster);
    }
    return undefined;
  }

  get uploadFeature() {
    switch (this.sidebarSharedState.activeTab) {
      case TabItemsMap.FaceObjects:
      case TabItemsMap.BodyObjects:
      case TabItemsMap.CarObjects:
        return true;
    }
    return false;
  }

  sendUpdateEvent() {
    globalEventModule.sendUpdate(this.sidebarItem.type, this.sidebarItem.model.item);
  }

  async applyDetection(v: BboxConfig[]) {
    const tab = this.objectsTypeByTab;
    const result = await cardPhotoUploaderModule.uploadByDetection(this.file!, v[0], tab!, this.currentItem?.id, this.cardType as CardType);
    if (result) {
      this.showDetectionDialog = false;
      await this.actionHandler(ItemsActionNames.Upload);
      this.sendUpdateEvent();
    }
  }

  get cardsModule() {
    switch (this.cardType) {
      case CardTypesMap.Cars:
        return viewModelRepository.getCardsCarsListViewModel();
      case CardTypesMap.Humans:
      default:
        return viewModelRepository.getCardsHumansListViewModel();
    }
  }

  setFile(e: any) {
    const file = e.target.files && e.target.files[0];
    this.$refs.fileInput.value = null;
    this.actionHandler('detect', file);
  }

  async itemActionHandler(v: ItemsActionName, payload?: any) {
    switch (v) {
      case ItemsActionNames.Remove:
        await this.itemsModule?.delete(payload.id);
        await this.itemsModule?.get();
        this.sendUpdateEvent();
        break;
      default:
        this.actionHandler(v, payload);
    }
  }

  async actionHandler(v: ItemsActionName, payload?: any) {
    if (v === ItemsActionNames.Detect) {
      this.file = payload;
      this.showDetectionDialog = true;
      return;
    }

    if (v === ItemsActionNames.UploadFile) {
      this.$refs.fileInput?.click();
      return;
    }

    if (v === ItemsActionNames.ShowInteractions) {
      RouterModule.navigateToInteractions({ card: payload.model.item.id! });
      return;
    }

    if (v === ItemsActionNames.Upload) {
      await this.itemsModule?.get();
      globalEventModule.sendCreate(this.sidebarItem.type);
      return;
    }

    await actionHandler.run(v, payload);
  }

  validateForm() {
    const form: NForm | undefined = this.$refs.cardFormInstance?.form as any as NForm;
    const result = form ? form.validate() : true;
    if (!result) form.displayErrors();
    return result;
  }

  created() {
    this.loadCurrentItemModules();
    actionHandler.getIsItemFormValid = this.validateForm.bind(this);
  }
}
