
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import NText from '@/uikit/text/NText.vue';
import NButton from '@/uikit/buttons/NButton.vue';

@Options({
  name: 'WebhooksFiltersDivider',
  components: { NButton, NText },
  emits: ['reset']
})
export default class WebhooksFiltersDivider extends Vue {
  @Prop({ type: String, default: '' })
  readonly label!: string;

  @Prop({ type: Boolean, default: false })
  readonly isBig!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly hasReset!: boolean;

  get computedLabelClass() {
    return `webhooks-filters-divider__label ${this.isBig ? 'heading-xl' : 'label-m'}`;
  }
}
