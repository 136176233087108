import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "camera-transform" }
const _hoisted_2 = { class: "camera-transform__image" }
const _hoisted_3 = { class: "camera-transform__select-wrapper" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "camera-transform__form" }
const _hoisted_6 = { class: "camera-transform__option" }
const _hoisted_7 = { class: "camera-transform__option" }
const _hoisted_8 = { class: "camera-transform__option" }
const _hoisted_9 = { class: "camera-transform__option" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CameraScreenshot = _resolveComponent("CameraScreenshot")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NIcon = _resolveComponent("NIcon")!
  const _component_NDropdownItem = _resolveComponent("NDropdownItem")!
  const _component_NDropdown = _resolveComponent("NDropdown")!
  const _component_NCheckbox = _resolveComponent("NCheckbox")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.screenshotUrl)
        ? (_openBlock(), _createBlock(_component_CameraScreenshot, {
            key: 0,
            modelValue: _ctx.screenshotUrl,
            width: "267",
            displayWidth: "267px",
            style: _normalizeStyle({ transform: _ctx.transform })
          }, null, 8, ["modelValue", "style"]))
        : (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: "camera-transform__image_before",
            style: _normalizeStyle({ transform: _ctx.transform })
          }, null, 4)),
      _createVNode(_component_NButton, {
        type: "text",
        class: "camera-transform__mirror",
        icon: "ds-mirror",
        disabled: _ctx.disabled,
        onAction: _ctx.mirrorHandle
      }, null, 8, ["disabled", "onAction"]),
      _createVNode(_component_NButton, {
        type: "text",
        class: "camera-transform__flip",
        icon: "ds-mirror",
        disabled: _ctx.disabled,
        onAction: _ctx.flipHandle
      }, null, 8, ["disabled", "onAction"]),
      _createVNode(_component_NButton, {
        type: "text",
        class: "camera-transform__rotate-left",
        icon: "ds-rotate-left",
        disabled: _ctx.disabled,
        onAction: _ctx.rotateLeft
      }, null, 8, ["disabled", "onAction"]),
      _createVNode(_component_NButton, {
        type: "text",
        class: "camera-transform__rotate-right",
        icon: "ds-rotate-right",
        disabled: _ctx.disabled,
        onAction: _ctx.rotateRight
      }, null, 8, ["disabled", "onAction"]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_NDropdown, { disabled: _ctx.disabled }, {
          toggle: _withCtx(({ toggle }) => [
            _createElementVNode("div", {
              class: "camera-transform__select",
              onClick: toggle
            }, [
              _createVNode(_component_NIcon, {
                name: "ds-f",
                width: "11",
                height: "17",
                style: _normalizeStyle({ transform: _ctx.transform })
              }, null, 8, ["style"]),
              _createVNode(_component_NIcon, {
                name: "chevron-down",
                class: "camera-transform__chevron"
              })
            ], 8, _hoisted_4)
          ]),
          body: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.helperItems, (item) => {
              return (_openBlock(), _createBlock(_component_NDropdownItem, {
                key: item.value,
                select: true,
                onClick: ($event: any) => (_ctx.selectTransform(item))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_NIcon, {
                    name: "ds-f",
                    width: "9",
                    height: "20",
                    style: _normalizeStyle({ transform: item.transform })
                  }, null, 8, ["style"])
                ]),
                _: 2
              }, 1032, ["onClick"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["disabled"])
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_NCheckbox, {
          modelValue: _ctx.params.mirror,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.params.mirror) = $event)),
          disabled: _ctx.disabled
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('ds.mirror')), 1)
          ]),
          _: 1
        }, 8, ["modelValue", "disabled"])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_NCheckbox, {
          modelValue: _ctx.params.flip,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.params.flip) = $event)),
          disabled: _ctx.disabled
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('ds.flip')), 1)
          ]),
          _: 1
        }, 8, ["modelValue", "disabled"])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_NCheckbox, {
          modelValue: _ctx.params.rotateClockwise,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.params.rotateClockwise) = $event)),
          disabled: _ctx.disabled
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('ds.rotate_clockwise')), 1)
          ]),
          _: 1
        }, 8, ["modelValue", "disabled"])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createVNode(_component_NCheckbox, {
          modelValue: _ctx.params.rotateCounterclockwise,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.params.rotateCounterclockwise) = $event)),
          disabled: _ctx.disabled
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('ds.rotate_counterclockwise')), 1)
          ]),
          _: 1
        }, 8, ["modelValue", "disabled"])
      ])
    ])
  ]))
}