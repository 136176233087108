
import { Options, Vue } from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';
import { Line } from '@/api/models/Line';
import CameraName from '@/components/data-source/CameraName.vue';
import NButton from '@/uikit/buttons/NButton.vue';
import NLoadingCircle from '@/uikit/loading/NLoadingCircle.vue';
import { dataModule } from '@/store/data';
import { imageLoader } from '@/store/image-loader';
import NDraw from '@/uikit/draw/NDraw.vue';
import ImageLoadError from '@/components/common/ImageLoadError.vue';

@Options({
  name: 'LinesROI',
  components: { ImageLoadError, NDraw, NLoadingCircle, NButton, CameraName }
})
export default class LinesROI extends Vue {
  @Prop({ type: Object, required: true })
  readonly model!: Line;

  @Prop({ type: Boolean, default: false })
  readonly hasFullscreen!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean;

  loading = false;
  loaded = false;
  error = false;

  imageUrl = '';
  imageDataUrl = '';

  get cameraName() {
    if (this.model.camera !== null) {
      const camera = dataModule.camerasModule.items.find((item) => item.id === this.model.camera);
      if (camera) {
        this.imageUrl = camera.screenshot;
        return camera.name;
      }
    }
    if (this.model.video_archive !== null) {
      const video_archive = dataModule.videosModule.items.find((item) => item.id === this.model.video_archive);
      if (video_archive) {
        this.imageUrl = video_archive.screenshot;
        return video_archive.name;
      }
    }
    return '';
  }

  get points() {
    return this.model.points || [];
  }

  set points(points: number[][]) {
    this.model.points = points;
  }

  mounted() {
    this.loadCameraImage();
  }

  async loadCameraImage(resetCache = false) {
    try {
      this.error = false;
      this.loading = true;
      const image = await imageLoader.get(this.imageUrl, resetCache);
      this.imageDataUrl = image;
      this.loaded = true;
    } catch (e) {
      this.error = true;
    } finally {
      this.loading = false;
    }
  }

  clearRect() {
    this.$refs.drawTool?.clear();
  }

  dispatchShowModal() {
    this.$emit('showModal');
  }
}
