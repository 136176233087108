import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "image-load-error" }
const _hoisted_2 = {
  key: 0,
  class: "label-s"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButton = _resolveComponent("NButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_NButton, {
      type: "text",
      onAction: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('load'))),
      icon: "file-broken",
      "icon-size": _ctx.iconSize
    }, null, 8, ["icon-size"]),
    (_ctx.i18nMessage)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.$t(_ctx.i18nMessage, 'f')), 1))
      : _createCommentVNode("", true)
  ]))
}