
import CameraZonesHeader from '@/components/data-source/CameraZonesHeader.vue';
import NDraw from '@/uikit/draw/NDraw.vue';
import { pointsArrayToXGeometry, xGeometryToPointsArray } from '@/common/filters';
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { dataAssetsModule } from '@/store/application/data.assets.module';
import { ObjectsMultiToSingle, ObjectsSingleToMulti } from '@/store/application/data.assets';
import { imageLoader } from '@/store/image-loader';
import NLoadingCircle from '@/uikit/loading/NLoadingCircle.vue';
import NIcon from '@/uikit/icons/NIcon.vue';
import { DrawModes } from '@/uikit/draw/types';

const ColorsMap: Record<string, string> = {
  rot: 'rgb(0, 0, 0)',
  faces: 'rgb(238, 111, 45)',
  bodies: 'rgb(252, 232, 78)',
  cars: 'rgba(114, 251, 253)'
};

@Options({
  name: 'CameraZones',
  components: { NIcon, NLoadingCircle, CameraZonesHeader, NDraw }
})
export default class CameraZones extends Vue {
  @Prop({ type: Object, required: true })
  readonly item!: any;

  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean;

  protected tab = 'faces';
  protected image = '';
  protected loading = false;
  protected error = false;
  protected drawModes = DrawModes;

  @Watch('item.id', { immediate: true })
  itemWatcher() {
    this.load();
    this.setAvailableTab();
  }

  get description(): string {
    return this.$t(this.tab === 'rot' ? 'parameters.rot_desc' : 'parameters.roi_desc');
  }

  get imageUrl(): string {
    return this.item.screenshot;
  }

  get color(): string {
    return ColorsMap[this.tab];
  }

  get availableObjects() {
    const detectors = this.item.stream_settings?.detectors;
    return dataAssetsModule.availableObjects
      .map((v: string) => {
        return detectors?.[v] ? ObjectsSingleToMulti[v] : null;
      })
      .filter((v: any) => !!v);
  }

  get tabs() {
    return [{ name: 'rot', label: 'RoT' }, ...this.availableObjects.map((i) => ({ name: i, label: this.$t(`common.${i}`, 'f') }))];
  }

  get points(): number[][] | null {
    const { detectors, rot } = this.item.stream_settings;
    const detectorName = ObjectsMultiToSingle[this.tab];
    const geometry = this.tab !== 'rot' ? detectors[detectorName]?.roi : rot;
    return geometry ? xGeometryToPointsArray(geometry) : null;
  }

  set points(v: number[][] | null) {
    if (!v) return;
    const current = pointsArrayToXGeometry(v);
    const key = ObjectsMultiToSingle[this.tab];
    const detectors = this.item.stream_settings?.detectors;
    detectors[key] ? (detectors[key].roi = current) : (this.item.stream_settings.rot = current);
  }

  get rotPoints(): number[][] | null {
    return this.item.stream_settings.rot ? xGeometryToPointsArray(this.item.stream_settings.rot) : null;
  }

  async load(resetCache = false) {
    try {
      this.error = false;
      this.loading = true;
      this.image = await imageLoader.get(this.imageUrl, resetCache);
    } catch (e) {
      this.error = true;
    } finally {
      this.loading = false;
    }
  }

  changeActiveTab(value: string) {
    this.tab = value;
  }

  handleAction(actionName: string) {
    switch (actionName) {
      case 'clear':
        this.points = [];
        break;
    }
  }

  setAvailableTab() {
    if (this.tab === 'rot' || this.availableObjects.includes(this.tab)) return;
    this.tab = this.availableObjects[0] ?? 'rot';
  }
}
