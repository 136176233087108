
import {
  PointActionTypes,
  CanvasRect,
  Colors,
  SingleLine,
  ImageRect,
  DrawMode,
  DrawModes,
  PointAction,
  PointAsArray,
  Sizes,
  Size,
  Color,
  PolyLine
} from './types';
import { Options, Vue } from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';
import { getIntersectionPoint, getLineReversPoints, getMiddlePoint, isNearPoint, isPointOnLine, reduceLine } from './math-utils';
import { Debounce } from '@/common/debounce-decorator';
import isEqual from 'lodash/isEqual';
import { drawArrowHead, drawLine, drawPath, drawPointCircle, drawPointSquare, drawText } from '@/uikit/draw/draw-utils';

@Options({
  name: 'NDraw',
  emits: ['change']
})
export default class NDraw extends Vue {
  @Prop({ type: [String, Object], default: '' })
  readonly image!: string | Blob;

  @Prop({ type: String, default: DrawModes.Poly })
  readonly mode!: DrawMode;

  @Prop({ type: Array, required: false })
  readonly points?: PolyLine;

  @Prop({ type: Array, required: false })
  readonly excludePolygon?: PolyLine;

  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly crossedLines!: boolean;

  @Prop({ type: String, default: Colors.LineDefault })
  readonly color!: Color;

  @Prop({ type: Boolean, default: true })
  readonly fill!: boolean;

  @Ref('canvasContainer')
  canvasContainer: HTMLDivElement | null = null;

  loading = false;
  internalChange = false;
  internalPoints: PolyLine = [];
  middleLinePoint: PointAsArray | null = null;
  reversePointControls: SingleLine | null = null;

  imageBlob: Blob | null = null;

  imageRect: ImageRect = {
    width: 0,
    height: 0
  };

  canvasRect: CanvasRect = {
    width: 0,
    height: 0,
    x: 0,
    y: 0
  };

  canvas: HTMLCanvasElement | null = null;
  canvasContext: CanvasRenderingContext2D | null = null;
  pointsBeforeDrag: PolyLine | null = [];
  polygonPath: Path2D | null = null;
  isClosed = false;
  draggedStartMousePoint: Record<any, any> | null = null;
  draggedPointIndex: number | null = null;
  overPointIndex: number | boolean = 0;
  overAction: PointAction = {
    type: PointActionTypes.None,
    index: 0
  };
  overPoint: PointAsArray | null = null;

  @Watch('image')
  imageHandler() {
    this.resizeCanvas();
    this.loadImage();
  }

  @Watch('mode')
  modeHandler(v: string) {
    if (v === DrawModes.Rect) this.clear();
  }

  @Watch('points')
  pointsChangeHandler() {
    if (this.internalChange) {
      this.internalChange = false;
      return;
    }
    this.internalPoints = this.getPointsToInternal(this.points);
  }

  @Watch('internalPoints', { deep: true })
  internalPointsChangeHandler() {
    this.emitChange();
    this.nextTickDraw();
  }

  get isFillPolygonAvailable() {
    return this.mode !== DrawModes.Line;
  }

  get isActiveLineAvailable() {
    return this.mode !== DrawModes.Line;
  }

  get isMiddlePointAvailable() {
    return this.mode === DrawModes.Line || this.mode === DrawModes.Poly;
  }

  get isReversePointsAvailable() {
    return this.mode === DrawModes.Line;
  }

  @Debounce(100)
  emitChange() {
    const result = this.getInternalToPoints();
    this.internalChange = true;
    this.$emit('change', result);
  }

  setIsClosed() {
    const pointsLength = this.points?.length ?? 0;
    const isRectMode = this.mode === DrawModes.Rect;
    this.isClosed = this.mode !== DrawModes.Line && (isRectMode || pointsLength > 2);
  }

  getPointsToInternal(points: PolyLine | null | undefined): PolyLine {
    return (points || [])
      .map((v: any) => [v[0] / this.scaleFactor + this.offsets.x, v[1] / this.scaleFactor + this.offsets.y])
      .map((v: any) => [Math.round(v[0]), Math.round(v[1])]);
  }

  getInternalToPoints() {
    const computeXPoint = (v: any) => Math.round((v - this.offsets.x) * this.scaleFactor);
    const computeYPoint = (v: any) => Math.round((v - this.offsets.y) * this.scaleFactor);

    const result = this.internalPoints?.map((v) => [computeXPoint(v[0]), computeYPoint(v[1])]);
    return result.length ? result : null;
  }

  computeMaxMinPoint(point: PointAsArray): PointAsArray {
    return this.imageRect
      ? [
          Math.round(this.applyMaxMin(point[0], this.offsets.x, this.offsets.x + this.imageRect?.width / this.scaleFactor)),
          Math.round(this.applyMaxMin(point[1], this.offsets.y, this.offsets.y + this.imageRect.height / this.scaleFactor))
        ]
      : point;
  }

  applyMaxMin(value: number, min: number, max: number) {
    return value > max ? max : value < min ? min : value;
  }

  getNearCurrentPointIndex(point: PointAsArray) {
    for (let i = 0; i < this.internalPoints.length; i++) {
      const currentPoint = this.internalPoints[i];
      const isNear = isNearPoint(point, currentPoint);
      if (isNear) {
        return i;
      }
    }
    return null;
  }

  nextTickDraw() {
    this.$nextTick(this.draw);
  }

  draw() {
    if (!this.canvasContext) return;
    this.canvasContext.clearRect(0, 0, this.canvasRect.width, this.canvasRect.height);
    this.isFillPolygonAvailable && this.fillPolygon();
    this.fillExcludePolygon();
    for (let i = 0; i < this.internalPoints.length; i++) {
      const startPoint = this.internalPoints[i];
      const nextPoint = this.internalPoints[i + 1];
      if (startPoint && nextPoint) {
        this.drawLine(startPoint, nextPoint);
      } else {
        if (this.isClosed) this.drawLine(startPoint, this.internalPoints[0]);
        else if (this.overPoint && this.isActiveLineAvailable) this.drawActiveLine(startPoint, this.overPoint);
      }
    }

    for (let i = 0; i < this.internalPoints.length; i++) {
      const point = this.internalPoints[i];
      this.drawPointSquare(point, i, Sizes.PointSizeNormal);
    }

    this.middleLinePoint && this.drawPointSquare(this.middleLinePoint, 0, Sizes.PointSizeSmall);
    this.drawCrossDirectionLine();

    this.internalChange = false;
  }

  drawCrossDirectionLine() {
    if (this.reversePointControls) {
      const [pointA, pointB] = this.reversePointControls;
      this.drawPointCircle(pointA, Sizes.PointSizeBig, Colors.PointA);
      this.drawPointText('A', pointA);
      this.drawPointCircle(pointB, Sizes.PointSizeBig, Colors.PointB);
      this.drawPointText('B', pointB);

      const reduceDistance = Sizes.PointSizeBig / 2 + 1;
      const [linePointA, linePointB] = reduceLine(pointA, pointB, reduceDistance);

      this.drawLine(linePointA, linePointB);
      this.drawArrowHead(linePointA, linePointB);
    }
  }

  getPointFillColor(index: number) {
    let result: Color = Colors.White90;
    if (this.draggedPointIndex === index || (this.draggedPointIndex as number) < 0) {
      result = this.color || Colors.PointDraggedFill;
    } else if (this.overPointIndex === index) {
      result = Colors.PointOverFill;
    }
    return result;
  }

  drawPointSquare(point: PointAsArray, index: number, size: Size) {
    this.canvasContext && drawPointSquare(this.canvasContext, point, size, this.color, this.getPointFillColor(index));
  }

  drawPointText(text: string, point: PointAsArray) {
    this.canvasContext && drawText(this.canvasContext, text, point, Sizes.PointTextBig, { fillStyle: Colors.White90 });
  }

  drawPointCircle(point: PointAsArray, size: Size, fillStyle: Color, strokeStyle: Color = Colors.White90) {
    this.canvasContext && drawPointCircle(this.canvasContext, point, size, { fillStyle, strokeStyle });
  }

  drawLine(startPoint: PointAsArray, endPoint: PointAsArray) {
    this.canvasContext && drawLine(this.canvasContext, startPoint, endPoint, { strokeStyle: this.color });
  }

  drawArrowHead(startPoint: PointAsArray, endPoint: PointAsArray) {
    this.canvasContext && drawArrowHead(this.canvasContext, startPoint, endPoint, { strokeStyle: this.color, fillStyle: this.color });
  }

  drawActiveLine(startPoint: PointAsArray, endPoint: PointAsArray) {
    this.canvasContext && drawLine(this.canvasContext, startPoint, endPoint, { strokeStyle: Colors.LineActive, lineDash: [8, 12] });
  }

  fillExcludePolygon() {
    if (!this.excludePolygon || this.excludePolygon.length < 3) return;
    const ctx = this.canvasContext;
    if (!ctx) return;
    const internalExcludePolygon = this.getPointsToInternal(this.excludePolygon);
    const canvasFullSquare: PolyLine = [
      [0, 0],
      [this.canvasRect.width, 0],
      [this.canvasRect.width, this.canvasRect.height],
      [0, this.canvasRect.height]
    ];
    const polygonPath = drawPath(canvasFullSquare);
    drawPath(internalExcludePolygon, polygonPath);
    ctx.fillStyle = Colors.ExcludeFill;
    ctx.fill(polygonPath, 'evenodd');
  }

  fillPolygon() {
    const ctx = this.canvasContext;
    if (!ctx) return;
    this.polygonPath = null;
    if (this.internalPoints.length < 3) return;
    const polygonPath = drawPath(this.internalPoints);
    ctx.fillStyle = this.fill ? Colors.PolygonFill : Colors.Transparent;
    ctx.fill(polygonPath);
    this.polygonPath = polygonPath;
  }

  clear() {
    this.internalPoints = [];
    this.isClosed = this.mode === DrawModes.Rect;
    this.nextTickDraw();
  }

  createCanvas() {
    this.canvas = document.createElement('canvas');
    this.canvasContext = this.canvas.getContext('2d');
    this.canvasContainer?.appendChild(this.canvas);
  }

  resizeCanvas() {
    const sourceRect = this.canvasContainer?.getBoundingClientRect();
    if (!sourceRect || !this.canvas) return;
    this.canvasRect = { width: sourceRect.width, height: sourceRect.height, x: 0, y: 0 };
    this.canvas.width = this.canvasRect?.width;
    this.canvas.height = this.canvasRect.height;
  }

  resize() {
    this.resizeCanvas();
    this.updateInternalPoints();
    this.setReversePointControls();
    this.nextTickDraw();
  }

  isString(v: unknown): v is string {
    return typeof v === 'string';
  }

  isBlob(v: unknown): v is Blob {
    return v instanceof Blob;
  }

  loadImage() {
    const url: string | null = this.isString(this.image) ? this.image : null;
    const blob: Blob | null = this.isBlob(this.image) ? this.image : null;
    if (!url && !blob) return;

    const image = new Image();
    image.onload = () => {
      let imageRect = { width: 0, height: 0 };
      this.loading = false;
      imageRect.width = image.naturalWidth;
      imageRect.height = image.naturalHeight;
      image.onload = null;
      this.imageRect = imageRect;
      this.resize();
      url ? this.convertImageToBlob(image) : (this.imageBlob = blob);
    };
    this.loading = true;
    image.src = url || URL.createObjectURL(blob);
  }

  convertImageToBlob(image: HTMLImageElement) {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    canvas.width = image.naturalWidth;
    canvas.height = image.naturalHeight;
    context?.drawImage(image, 0, 0);
    canvas.toBlob(
      (v) => {
        this.imageBlob = v;
      },
      'image/jpeg',
      0.9
    );
  }

  updateInternalPoints() {
    this.internalPoints = this.getPointsToInternal(this.points);
  }

  mouseDoubleClick(e: MouseEvent) {
    const mousePoint: PointAsArray = [e.offsetX, e.offsetY],
      currentPointIndex = this.getNearCurrentPointIndex(mousePoint);

    if (currentPointIndex !== null && this.mode !== DrawModes.Rect) {
      this.internalPoints.splice(currentPointIndex, 1);
      this.setReversePointControls();
    }
  }

  getAction(point: PointAsArray) {
    const currentPointIndex: number | null = this.getNearCurrentPointIndex(point);
    const currentLinePointIndex: number | null = this.getOnLinePointIndex(point);
    const hasNearPoint = currentPointIndex !== null;
    const hasNearLine = currentLinePointIndex !== null;
    const isPointInPolygon =
      !hasNearPoint && !hasNearLine && this.isClosed && this.polygonPath && this.canvasContext?.isPointInPath(this.polygonPath, ...point);
    const hasNearMiddlePoint = this.middleLinePoint && isNearPoint(this.middleLinePoint, point, Sizes.PointSizeSmall);
    const hasNearReversePointControl =
      this.reversePointControls &&
      (isNearPoint(this.reversePointControls[0], point, Sizes.PointSizeBig) || isNearPoint(this.reversePointControls[1], point, Sizes.PointSizeBig));

    const result: PointAction = { type: PointActionTypes.None, index: null };
    switch (this.mode) {
      case DrawModes.Line:
        if (hasNearPoint) {
          result.type = PointActionTypes.Move;
          result.index = currentPointIndex;
        } else if (hasNearMiddlePoint) {
          result.type = PointActionTypes.AddPoint;
          result.index = (currentLinePointIndex as number) + 1;
        } else if (hasNearLine) {
          result.type = PointActionTypes.MoveAll;
          result.index = -1;
        } else if (hasNearReversePointControl) {
          result.type = PointActionTypes.ReversePoints;
        } else {
          result.type = PointActionTypes.AddPoint;
        }
        break;
      case DrawModes.Poly:
        if (hasNearPoint) {
          if (currentPointIndex === 0 && this.internalPoints.length > 2 && !this.isClosed) {
            result.type = PointActionTypes.Close;
            result.index = currentPointIndex;
          } else {
            result.type = PointActionTypes.Move;
            result.index = currentPointIndex;
          }
        } else if (hasNearMiddlePoint) {
          result.type = PointActionTypes.AddPoint;
          result.index = (currentLinePointIndex as number) + 1;
        } else if (hasNearLine || isPointInPolygon) {
          result.type = PointActionTypes.MoveAll;
          result.index = -1;
        } else {
          result.type = PointActionTypes.AddPoint;
        }
        break;
      default:
        if (hasNearPoint) {
          result.type = PointActionTypes.Move;
          result.index = currentPointIndex;
        } else if (isPointInPolygon || hasNearLine) {
          result.type = PointActionTypes.MoveAll;
          result.index = -1;
        } else {
          result.type = PointActionTypes.DrawRect;
        }
    }
    return result;
  }

  getRectPoints([x, y]: PointAsArray): PolyLine {
    return [
      [x, y],
      [x + 1, y],
      [x + 1, y + 1],
      [x, y + 1]
    ];
  }

  async reversePoints() {
    this.internalPoints.reverse();
    this.reversePointControls = getLineReversPoints(this.internalPoints);
  }

  addPoint(point: PointAsArray, afterIndex: number | null) {
    const isLineAfter = afterIndex === this.internalPoints.length;

    if (afterIndex === null || (isLineAfter && !this.isClosed)) {
      if (!this.crossedLines && this.isCrossOnAddPoint(point)) return;
      this.internalPoints.push(point);
    } else {
      this.internalPoints.splice(afterIndex, 0, point);
    }

    this.setReversePointControls();
  }

  getCrossedLinesByPoints(points: PolyLine) {
    let lines: any[] = [];
    for (let i = 0; i < points.length - 1; i++) {
      lines.push([points[i], points[i + 1]]);
    }

    const crossedLines: any[] = [];
    for (let i = 0; i < lines.length; i++) {
      for (let j = i + 1; j < lines.length; j++) {
        const intersectionPoint = getIntersectionPoint(
          lines[i][0][0],
          lines[i][0][1],
          lines[i][1][0],
          lines[i][1][1],
          lines[j][0][0],
          lines[j][0][1],
          lines[j][1][0],
          lines[j][1][1]
        );
        if (intersectionPoint) {
          const isConnectionPoint = points.find((point: any) => point[0] === intersectionPoint[0] && point[1] === intersectionPoint[1]);
          if (!isConnectionPoint) {
            crossedLines.push(lines[i]);
          }
        }
      }
    }
    return crossedLines;
  }

  isCrossOnAddPoint(point: PointAsArray) {
    const points = [...this.internalPoints, point];
    return this.getCrossedLinesByPoints(points).length;
  }

  isCrossOnMovePoint(point: PointAsArray) {
    const points = [...this.internalPoints];
    points[this.draggedPointIndex as number] = point;
    return this.getCrossedLinesByPoints(points).length;
  }

  getOnLinePointIndex(point: PointAsArray, closedFigure = true) {
    const nextPointReplace = closedFigure ? this.internalPoints[0] : null;
    for (let i = 0; i < this.internalPoints.length; i++) {
      const currentPoint = this.internalPoints[i],
        nextPoint = this.internalPoints[i + 1] || nextPointReplace;
      if (nextPoint && isPointOnLine(point, currentPoint, nextPoint)) return i;
    }
    return null;
  }

  setReversePointControls() {
    const reversePointControls = this.isReversePointsAvailable ? getLineReversPoints(this.internalPoints) : null;
    if (!isEqual(reversePointControls, this.reversePointControls)) {
      this.reversePointControls = reversePointControls;
      return true;
    }
    return false;
  }

  setMiddleLinePoints(point: PointAsArray) {
    const middleLinePoint = this.isMiddlePointAvailable ? this.getMiddleLinePoint(point) : null;
    if (!isEqual(middleLinePoint, this.middleLinePoint)) {
      this.middleLinePoint = middleLinePoint;
      return true;
    }
    return false;
  }

  mouseOut() {
    this.overPoint = null;
    this.nextTickDraw();
  }

  mouseDown(e: MouseEvent) {
    e.preventDefault();
    const mousePoint = this.computeMaxMinPoint([e.offsetX, e.offsetY]);
    const action = this.getAction(mousePoint);
    switch (action?.type) {
      case PointActionTypes.DrawRect:
        this.internalPoints = this.getRectPoints(mousePoint);
        this.draggedStartMousePoint = mousePoint;
        this.draggedPointIndex = 2;
        this.nextTickDraw();
        break;
      case PointActionTypes.Close:
        if (this.isCrossOnAddPoint(mousePoint)) return;
        this.isClosed = true;
        this.nextTickDraw();
        break;
      case PointActionTypes.MoveAll:
      case PointActionTypes.Move:
        this.pointsBeforeDrag = this.internalPoints.map((v) => [...v]);
        this.draggedStartMousePoint = mousePoint;
        this.draggedPointIndex = action.index as number;
        this.nextTickDraw();
        break;
      case PointActionTypes.AddPoint:
        this.addPoint(mousePoint, action.index);
        this.draggedPointIndex = action.index as number;
        break;
      case PointActionTypes.ReversePoints:
        this.reversePoints();
        break;
    }
  }

  mouseUp() {
    this.draggedPointIndex = null;
    this.nextTickDraw();
  }

  mouseMove(e: MouseEvent) {
    e.preventDefault();
    let redraw = this.mode === DrawModes.Poly && !this.isClosed;
    const mousePoint = this.computeMaxMinPoint([e.offsetX, e.offsetY]);
    this.overPoint = mousePoint;

    if (this.setReversePointControls()) {
      redraw = true;
    }
    if (this.setMiddleLinePoints(mousePoint)) {
      redraw = true;
    }

    if (this.draggedPointIndex !== null) {
      switch (this.mode) {
        case DrawModes.Poly:
        case DrawModes.Line:
          this.movePolyPoint(mousePoint);
          break;
        case DrawModes.Rect:
          this.moveRectPoint(mousePoint);
          break;
        default:
      }
      this.internalPointsChangeHandler();
    } else {
      const action = this.getAction(mousePoint);
      this.overAction = action;
      const previousIndex = this.overPointIndex;
      this.overPointIndex = action.type === PointActionTypes.Move && (action.index as number);
      if (previousIndex !== this.overPointIndex) redraw = true;
    }

    redraw && this.nextTickDraw();
  }

  moveAllPoints(point: PointAsArray) {
    if (!this.draggedStartMousePoint) return;
    const diffPoint = [point[0] - this.draggedStartMousePoint[0], point[1] - this.draggedStartMousePoint?.[1]];
    if (this.pointsBeforeDrag) {
      this.internalPoints = this.pointsBeforeDrag.map((v: any) => this.computeMaxMinPoint([v[0] + diffPoint[0], v[1] + diffPoint[1]]));
    }
  }

  movePolyPoint(point: any) {
    if ((this.draggedPointIndex as number) < 0) {
      this.moveAllPoints(point);
    } else {
      if (!this.crossedLines && this.isCrossOnMovePoint(point)) return;
      this.internalPoints[this.draggedPointIndex as number] = [...point] as PointAsArray;
    }
  }

  moveRectPoint(point: any) {
    if (this.draggedPointIndex === null) return;
    if (this.draggedPointIndex < 0) {
      this.moveAllPoints(point);
    } else {
      const currentIndex = this.draggedPointIndex;
      const prevIndex = currentIndex > 0 ? currentIndex - 1 : 3;
      const nextIndex = currentIndex < 3 ? currentIndex + 1 : 0;
      const currentPoint = this.internalPoints[currentIndex];
      const prevPoint = this.internalPoints[prevIndex];
      const nextPoint = this.internalPoints[nextIndex];
      const isPrevXEqual = Math.abs(prevPoint[0] - currentPoint[0]) < 1;
      this.internalPoints[currentIndex] = [...point] as PointAsArray;

      if (isPrevXEqual) {
        this.internalPoints[prevIndex] = [point[0], prevPoint[1]];
        this.internalPoints[nextIndex] = [nextPoint[0], point[1]];
      } else {
        this.internalPoints[prevIndex] = [prevPoint[0], point[1]];
        this.internalPoints[nextIndex] = [point[0], nextPoint[1]];
      }
    }
  }

  getMiddleLinePoint(mousePoint: PointAsArray) {
    if (this.internalPoints?.length < 2) return null;
    const pointAIndex = this.getOnLinePointIndex(mousePoint, this.isClosed);
    if (pointAIndex === null) return null;
    const pointA = this.internalPoints[pointAIndex];
    const pointB = this.internalPoints[pointAIndex + 1] ?? this.internalPoints[0];
    if (isNearPoint(pointA, mousePoint)) return null;
    if (isNearPoint(pointB, mousePoint)) return null;
    return getMiddlePoint(pointA, pointB);
  }

  get mouseStyle() {
    const action = this.overAction;
    let cursor = 'default';
    switch (action?.type) {
      case PointActionTypes.ReversePoints:
        cursor = 'pointer';
        break;
      case PointActionTypes.MoveAll:
      case PointActionTypes.Move:
        cursor = 'move';
        break;
      case PointActionTypes.DrawRect:
      case PointActionTypes.AddPoint:
      case PointActionTypes.Close:
        cursor = 'crosshair';
        break;
      default:
        break;
    }
    return { cursor };
  }

  get imageContainerStyle() {
    if (!this.imageBlob) return null;
    const url = URL.createObjectURL(this.imageBlob);
    return { backgroundImage: `url("${url}")` };
  }

  get classes() {
    return {
      'n-draw__content-disabled': this.disabled
    };
  }

  get offsets() {
    const { imageRect, canvasRect } = this,
      canCompute = imageRect && canvasRect;

    if (!canCompute) return { x: 0, y: 0 };

    const scaleFactor = this.scaleFactor,
      x = (canvasRect.width - imageRect.width / scaleFactor) / 2,
      y = (canvasRect.height - imageRect.height / scaleFactor) / 2;
    return { x, y };
  }

  get scaleFactor() {
    const { imageRect, canvasRect } = this,
      canCompute = imageRect && canvasRect;
    if (!canCompute) return 1;

    return Math.max(imageRect.width / canvasRect.width, imageRect.height / canvasRect.height);
  }

  @Watch('mode', { immediate: true })
  handleModeChange() {
    this.setIsClosed();
  }

  mounted() {
    this.createCanvas();
    this.resizeCanvas();
    this.loadImage();
    window.addEventListener('mouseup', this.mouseUp);
    window.addEventListener('resize', this.resize);
  }

  beforeDestroy() {
    window.removeEventListener('mouseup', this.mouseUp);
    window.removeEventListener('resize', this.resize);
  }
}
