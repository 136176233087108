/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CountResponse } from '../models/CountResponse';
import type { Line } from '../models/Line';
import type { LineAttributes } from '../models/LineAttributes';
import type { LineRequest } from '../models/LineRequest';
import type { PaginatedLineList } from '../models/PaginatedLineList';
import type { PatchedLineRequest } from '../models/PatchedLineRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';
export class LinesService {
    /**
     * List lines
     * @param active Select only active lines (`true`) or inactive (`false`) lines
     * @param cameraGroups Get lines from `cameras` or `video_archives` from these `camera_groups`
     * @param cameras Get lines for selected `cameras`
     * @param createdDateGt Select objects with `created_date` **greater** than this value
     * @param createdDateGte Select objects with `created_date` **greater than or equal** to this value
     * @param createdDateLastNDays Select objects with `created_date` in last N days
     * @param createdDateLt Select objects with `created_date` **less** than this value
     * @param createdDateLte Select objects with `created_date` **less than or equal** to this value
     * @param createdDateNthFullWeek Select objects with `created_date` in last Nth week (including Sunday and Saturday)
     * @param createdDateNthWorkWeek Select objects with `created_date` in last Nth week (only working days, i.e. excluding Sunday and Saturday)
     * @param idIn Select lines with provided ids
     * @param limit Number of results to return per page.
     * @param nameContains Select lines where the SQL condition `name ILIKE name_contains` is true
     * @param ordering Какое поле использовать для сортировки результатов. Available fields: `id`, `created_date`
     * @param page Pagination cursor value.
     * @param videoArchives Get lines for selected `video_archives`
     * @returns PaginatedLineList
     * @throws ApiError
     */
    public static linesList(
      active?: boolean,
      cameraGroups?: Array<string>,
      cameras?: Array<number>,
      createdDateGt?: string,
      createdDateGte?: string,
      createdDateLastNDays?: number,
      createdDateLt?: string,
      createdDateLte?: string,
      createdDateNthFullWeek?: number,
      createdDateNthWorkWeek?: number,
      idIn?: Array<number>,
      limit?: string,
      nameContains?: string,
      ordering?: string,
      page?: string,
      videoArchives?: Array<number>,
    ): CancelablePromise<PaginatedLineList> {
        return __request({
            method: 'GET',
            path: `/lines/`,
            query: {
                'active': active,
                'camera_groups': cameraGroups,
                'cameras': cameras,
                'created_date_gt': createdDateGt,
                'created_date_gte': createdDateGte,
                'created_date_last_n_days': createdDateLastNDays,
                'created_date_lt': createdDateLt,
                'created_date_lte': createdDateLte,
                'created_date_nth_full_week': createdDateNthFullWeek,
                'created_date_nth_work_week': createdDateNthWorkWeek,
                'id_in': idIn,
                'limit': limit,
                'name_contains': nameContains,
                'ordering': ordering,
                'page': page,
                'video_archives': videoArchives,
            },
        });
    }
    /**
     * Add new line
     * @param requestBody
     * @returns Line
     * @throws ApiError
     */
    public static linesCreate(
      requestBody: LineRequest,
    ): CancelablePromise<Line> {
        return __request({
            method: 'POST',
            path: `/lines/`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Retrieve line by ID
     * @param id A unique integer value identifying this Line.
     * @returns Line
     * @throws ApiError
     */
    public static linesRetrieve(
      id: number,
    ): CancelablePromise<Line> {
        return __request({
            method: 'GET',
            path: `/lines/${id}/`,
        });
    }
    /**
     * Update line properties
     * @param id A unique integer value identifying this Line.
     * @param requestBody
     * @returns Line
     * @throws ApiError
     */
    public static linesPartialUpdate(
      id: number,
      requestBody?: PatchedLineRequest,
    ): CancelablePromise<Line> {
        return __request({
            method: 'PATCH',
            path: `/lines/${id}/`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Delete line
     * @param id A unique integer value identifying this Line.
     * @returns void
     * @throws ApiError
     */
    public static linesDestroy(
      id: number,
    ): CancelablePromise<void> {
        return __request({
            method: 'DELETE',
            path: `/lines/${id}/`,
        });
    }
    /**
     * Lines attributes
     * This method retrieves all used attributes of lines.
     * @returns LineAttributes
     * @throws ApiError
     */
    public static linesAttributesRetrieve(): CancelablePromise<LineAttributes> {
        return __request({
            method: 'GET',
            path: `/lines/attributes/`,
        });
    }
    /**
     * Lines count
     * This method retrieves a count of lines.
     * @param active Select only active lines (`true`) or inactive (`false`) lines
     * @param cameraGroups Get lines from `cameras` or `video_archives` from these `camera_groups`
     * @param cameras Get lines for selected `cameras`
     * @param createdDateGt Select objects with `created_date` **greater** than this value
     * @param createdDateGte Select objects with `created_date` **greater than or equal** to this value
     * @param createdDateLastNDays Select objects with `created_date` in last N days
     * @param createdDateLt Select objects with `created_date` **less** than this value
     * @param createdDateLte Select objects with `created_date` **less than or equal** to this value
     * @param createdDateNthFullWeek Select objects with `created_date` in last Nth week (including Sunday and Saturday)
     * @param createdDateNthWorkWeek Select objects with `created_date` in last Nth week (only working days, i.e. excluding Sunday and Saturday)
     * @param idIn Select lines with provided ids
     * @param nameContains Select lines where the SQL condition `name ILIKE name_contains` is true
     * @param videoArchives Get lines for selected `video_archives`
     * @returns CountResponse
     * @throws ApiError
     */
    public static linesCountRetrieve(
      active?: boolean,
      cameraGroups?: Array<string>,
      cameras?: Array<number>,
      createdDateGt?: string,
      createdDateGte?: string,
      createdDateLastNDays?: number,
      createdDateLt?: string,
      createdDateLte?: string,
      createdDateNthFullWeek?: number,
      createdDateNthWorkWeek?: number,
      idIn?: Array<number>,
      nameContains?: string,
      videoArchives?: Array<number>,
    ): CancelablePromise<CountResponse> {
        return __request({
            method: 'GET',
            path: `/lines/count/`,
            query: {
                'active': active,
                'camera_groups': cameraGroups,
                'cameras': cameras,
                'created_date_gt': createdDateGt,
                'created_date_gte': createdDateGte,
                'created_date_last_n_days': createdDateLastNDays,
                'created_date_lt': createdDateLt,
                'created_date_lte': createdDateLte,
                'created_date_nth_full_week': createdDateNthFullWeek,
                'created_date_nth_work_week': createdDateNthWorkWeek,
                'id_in': idIn,
                'name_contains': nameContains,
                'video_archives': videoArchives,
            },
        });
    }
}