
import { configModule } from '@/store/config';
import { defineComponent, toRef } from 'vue';
import { arrayOf, bool, string } from 'vue-types';
import GroupAccessPermissions from './GroupAccessPermissions.vue';
import GroupCommonPermissions from './GroupCommonPermissions.vue';
import { useGroupPermissionsViewModel } from './setup';
import { Permission } from './types';
import HeaderBar from '@/components/common/HeaderBar.vue';

export default defineComponent({
  name: 'GroupPermissions',
  components: {
    HeaderBar,
    GroupAccessPermissions,
    GroupCommonPermissions
  },
  props: {
    modelValue: arrayOf(string<Permission>()).isRequired,
    disabled: bool().def(false)
  },
  setup: (props) => {
    const granted = toRef(props, 'modelValue');
    return useGroupPermissionsViewModel(granted, configModule.plugins);
  }
});
