import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NDateTimeLabel = _resolveComponent("NDateTimeLabel")!
  const _component_NTag = _resolveComponent("NTag")!
  const _component_NHint = _resolveComponent("NHint")!

  return (_openBlock(), _createBlock(_component_NHint, {
    placement: "bottom",
    class: "cards-sidebar__monitoring-hint",
    type: "light"
  }, {
    content: _withCtx(() => [
      _createVNode(_component_NDateTimeLabel, {
        size: "2xs",
        "model-value": _ctx.item.created_date
      }, null, 8, ["model-value"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_NTag, {
        type: "pink",
        "label-size": "2xs"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('remote_monitoring.monitoring')), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}