import { computed, getCurrentInstance, onMounted, ref, Ref, unref } from 'vue';
import { createCameraLookupAuthenticationDialog } from './components/utils';
import type { CameraLookupAuthenticator, CameraLookupModel, CameraLookupSource } from './types';
import { createIgnoredCaseStringMatcher } from './utils';

type CameraLookupModelPredicate = (camera: CameraLookupModel, matcher: (search: string) => boolean) => boolean;

const CAMERA_LOOKUP_MODEL_PREDICATES: CameraLookupModelPredicate[] = [
  ({ meta }, matcher) => matcher(meta.device.Hostname?.Name ?? ''),
  ({ meta }, matcher) => matcher(meta.host ?? ''),
  ({ meta }, matcher) => matcher(String(meta.port))
];

export function useCameraLookupViewModel(
  authenticator: Ref<CameraLookupAuthenticator>,
  source: Ref<CameraLookupSource>,
  onSelect: (camera: CameraLookupModel) => void
) {
  const cameras = ref<CameraLookupModel[]>([]);
  const dialog = createCameraLookupAuthenticationDialog(getCurrentInstance()?.appContext ?? null);
  const queriedCameras = computed(queryCamerasBySearchString);
  const running = ref<boolean>(false);
  const search = ref<string>('');

  onMounted(lookup);

  function handleCameraSelection(camera: CameraLookupModel): void {
    camera.need_auth ? dialog(unref(authenticator), camera.id).then(onSelect) : onSelect(camera);
  }

  function queryCamerasBySearchString(): CameraLookupModel[] {
    const matcher = createIgnoredCaseStringMatcher(unref(search));
    return unref(cameras).filter((camera) => CAMERA_LOOKUP_MODEL_PREDICATES.some((predicate) => predicate(camera, matcher)));
  }

  async function lookup(): Promise<void> {
    try {
      running.value = true;
      cameras.value = await unref(source)().catch(() => []);
    } finally {
      running.value = false;
    }
  }

  return {
    cameras,
    handleCameraSelection,
    lookup,
    queriedCameras,
    running,
    search,
    window
  };
}
