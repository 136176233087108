import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NFormBlocks = _resolveComponent("NFormBlocks")!

  return (_ctx.loaded)
    ? (_openBlock(), _createBlock(_component_NFormBlocks, {
        key: 0,
        ref: "form",
        blocks: _ctx.formLayoutBlocks,
        model: _ctx.model,
        state: _ctx.state
      }, null, 8, ["blocks", "model", "state"]))
    : _createCommentVNode("", true)
}