
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import NForm, { FormValidatorNames, IFormLayout } from '@/uikit/forms/NForm.vue';
import { ExternalVms, VmsPluginInfoRequest } from '@/api';
import { NInput, NSelect } from '@/uikit';
import { actionHandler } from '@/store/data/ActionHandler';
import externalVmsGetInfoScheme from './external-vms-get-info-scheme';
import { ExternalVmsConfig, ExternalVmsErrorPayload } from '../types';
import { multisidebarModule } from '@/store/multisidebar';
import { AxiosError } from 'axios';
import { capitalize, get, set } from 'lodash';

function convertServerErrorToMap(error: AxiosError<ExternalVmsErrorPayload>) {
  const param = error.response?.data?.original_error?.param;
  return param ? Object.fromEntries(param.map((v) => [v.loc.join('.'), capitalize(v.msg)])) : {};
}

@Options({
  name: 'ExternalVmsInfo',
  components: { NForm },
  emits: []
})
export default class ExternalVmsInfo extends Vue {
  @Prop({ type: Object, required: true })
  readonly item!: ExternalVms;

  @Prop({ type: Object, required: true })
  readonly info!: VmsPluginInfoRequest;

  @Prop({ type: Object, required: false })
  readonly config: ExternalVmsConfig | null = null;

  get module() {
    return multisidebarModule.currentItem?.model;
  }

  get isNew() {
    return this.module?.isNew;
  }

  get availableVms(): string[] {
    return this.info.vms ? Object.keys(this.info.vms) : [];
  }

  get availableVmsVersions(): string[] {
    if (this.availableVms && this.item.name) {
      return this.info.vms[this.item.name].map((v: ExternalVms) => v.version) || [];
    }
    return [];
  }

  get serverErrorsMap(): Record<string, string> {
    return this.module?.loadError ? convertServerErrorToMap(this.module.loadError) : {};
  }

  get layout(): IFormLayout {
    return [
      {
        path: 'verbose_name',
        classes: 'n-form-w-6 n-form-pad-10 label-m',
        i18n_label: 'external_vms.name',
        component: NInput,
        validators: [{ name: FormValidatorNames.Required, i18n_message: 'errors.required.name' }]
      },
      [
        {
          path: 'name',
          classes: 'n-form-w-6 label-m n-form-pad-10',
          i18n_label: 'external_vms.vms',
          component: NSelect,
          validators: [{ name: FormValidatorNames.Required }],
          props: {
            items: this.availableVms.map((v: string) => ({ label: v, value: v })),
            capitalizeLabels: true,
            disabled: !this.isNew
          }
        },
        {
          path: 'version',
          classes: 'n-form-w-6 label-m n-form-pad-10',
          i18n_label: 'external_vms.version',
          component: NSelect,
          validators: [{ name: FormValidatorNames.Required }],
          props: {
            items: this.availableVmsVersions.map((v: string) => ({ label: v, value: v })),
            disabled: !this.isNew
          }
        }
      ],
      ...externalVmsGetInfoScheme(this.config, this.serverErrorsMap)
    ];
  }

  @Watch('module.loadError')
  handleServerError(v: any) {
    if (v) {
      const form: NForm | undefined = this.$refs.form as any as NForm;
      form && form.displayErrors();
    }
  }

  @Watch('item.name')
  fillVersion() {
    this.item.version = this.availableVmsVersions.length ? this.availableVmsVersions[0] : '';
  }

  @Watch('item.name')
  @Watch('item.version')
  resetOptions() {
    if (this.isNew) {
      this.item.options = {};
    }
  }

  // Prevent VMS plugin required error.
  // It's fire when some field not appear in request.
  // VMS plugin expect all exists entity fields in request with empty string as initial value.
  @Watch('layout')
  fillOptionsFields(layout: IFormLayout) {
    for (let item of layout.flat()) {
      if (item.path?.startsWith('options.') && get(this.item, item.path) === undefined) {
        set(this.item, item.path, '');
      }
    }
  }

  async validateForm() {
    this.module && (this.module.loadError = null);
    await this.$nextTick();
    const form: NForm | undefined = this.$refs.form as any as NForm;
    const result = form ? form.validate() : true;
    if (!result) form.displayErrors();
    return result;
  }

  mounted() {
    actionHandler.getIsItemFormValid = this.validateForm.bind(this);
  }
}
