import { reactive } from 'vue';
import { UserFaceService } from '@/api';
import { UserFaceRequestWithThumbnail } from '@/pages/users/types';

class UserFacesModule {
  private items: UserFaceRequestWithThumbnail[] = [];

  public add(item: UserFaceRequestWithThumbnail) {
    this.items.push(item);
  }

  public remove(item: UserFaceRequestWithThumbnail) {
    this.items = this.items.filter((v) => v !== item);
  }

  public getUserFaces(userId: number) {
    return this.items.filter((v) => v.user === userId);
  }

  public async uploadAll(userId: number) {
    const faces = this.getUserFaces(userId);
    for (let face of faces) {
      delete face.thumbnail;
      await UserFaceService.userFaceCreate(face);
    }
    this.items = this.items.filter((v) => v.user !== userId);
    return true;
  }

  public replaceIds(previous: number, current: number) {
    this.items.forEach((v) => {
      if (v.user === previous) v.user = current;
    });
  }

  public static create() {
    return reactive(new this());
  }
}

export const userFacesModule = UserFacesModule.create();
