import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "n-draw" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["n-draw__content", _ctx.classes]),
      style: _normalizeStyle(_ctx.mouseStyle)
    }, [
      _createElementVNode("div", {
        class: "n-draw__image-container",
        style: _normalizeStyle(_ctx.imageContainerStyle)
      }, null, 4),
      _createElementVNode("div", {
        class: "n-draw__canvas",
        ref: "canvasContainer",
        onMousedown: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.mouseDown && _ctx.mouseDown(...args))),
        onDblclick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.mouseDoubleClick && _ctx.mouseDoubleClick(...args))),
        onMousemove: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.mouseMove && _ctx.mouseMove(...args))),
        onMouseout: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.mouseOut && _ctx.mouseOut(...args)))
      }, null, 544)
    ], 6)
  ]))
}