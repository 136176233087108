
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { dataModule } from '@/store/data';
import NSelect from '@/uikit/select/NSelect.vue';
import { Camera } from '@/api';
import log from 'loglevel';

@Options({
  name: 'CameraName',
  components: { NSelect }
})
export default class CameraName extends Vue {
  @Prop({ type: Number, required: true })
  readonly modelValue!: number;

  @Prop({ type: Boolean, default: false })
  readonly accent?: boolean;

  @Prop({ type: Boolean, default: false })
  readonly readonly?: boolean;

  @Prop({ type: Array, default: [] })
  readonly idIn?: number[];

  @Prop({ type: Number })
  readonly cameraGroup?: number;

  @Prop({ type: String, default: 'camera' })
  readonly sourceType?: string;

  get allItems() {
    if (this.sourceType === 'camera') {
      return dataModule.camerasModule.items as any[];
    }
    if (this.sourceType === 'video_archive') {
      return dataModule.videosModule.items as any[];
    }
    return [];
  }

  get items() {
    const result: any[] = this.allItems
      .filter((item) => (this.idIn?.length ? this.idIn.includes(item.id) : true))
      .filter((item) => {
        if (this.cameraGroup === undefined) return true;
        if (item.group === this.cameraGroup || item.camera_group === this.cameraGroup) return true;
        return false;
      })
      .map((item) => ({
        label: item.name,
        value: item.id
      }));
    return result;
  }
  get cameraName() {
    const dataItems = this.allItems;
    const camera = dataItems.find((item) => item.id === this.modelValue);
    if (camera) return camera.name;
    return '[camera:not found]';
  }

  @Watch('cameraGroup')
  handleCameraGroupChange() {
    if (this.cameraGroup !== undefined && this.items.length === 0) {
      this.updateValue(null);
    }
  }

  updateValue(value: number | null) {
    this.$emit('update:modelValue', value);
  }
}
