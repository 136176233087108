
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import FiltersSmall from '@/components/common/filter/FiltersSmall.vue';
import FiltersBig from '@/components/common/filter/FiltersBig.vue';
import { webhooksFiltersEditorVisualBuilder } from '@/pages/webhooks/components/webhooksFiltersEditorVisualBuilder';
import NButtonGroup from '@/uikit/buttons/NButtonGroup.vue';
import WebhooksFiltersEditorVisualBig from '@/pages/webhooks/components/WebhooksFiltersEditorVisualBig.vue';
import NThemeImage from '@/uikit/image/NThemeImage.vue';
import WebhooksFiltersDivider from '@/pages/webhooks/components/WebhooksFiltersDivider.vue';
import { isDefined } from '@/definitions/services/helpers';
import { isSectionAvailable } from '@/pages/webhooks/components/utils';

@Options({
  name: 'WebhooksFiltersEditorVisual',
  components: { WebhooksFiltersDivider, NThemeImage, WebhooksFiltersEditorVisualBig, NButtonGroup, FiltersBig, FiltersSmall },
  emits: ['reset', 'close']
})
export default class WebhooksFiltersEditorVisual extends Vue {
  @Prop({ type: Object, default: () => ({}) })
  readonly modelValue!: Record<string, any>;

  @Prop({ type: Boolean, default: false })
  readonly largeFiltersActive!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly hasChanges!: boolean;

  get activeSections() {
    return Object.keys(this.modelValue).filter(isSectionAvailable);
  }

  get smallFiltersLayout() {
    return webhooksFiltersEditorVisualBuilder({ small: true });
  }

  get largeFiltersLayout() {
    return webhooksFiltersEditorVisualBuilder();
  }

  @Watch('modelValue', { deep: true })
  handleModelValueUpdate(v: any) {
    let hasEmpty = false;

    function clearSection(section: any) {
      const validEntries = Object.entries(section).filter(([_, value]) => {
        if (Array.isArray(value) && !value.length) {
          hasEmpty = true;
          return false;
        }
        return true;
      });

      return Object.fromEntries(validEntries);
    }

    const clearedModelEntries = Object.entries(v).map(([sectionName, section]) => [sectionName, clearSection(section)]);

    this.$emit('update:modelValue', hasEmpty ? Object.fromEntries(clearedModelEntries) : v);
  }

  isSectionHasFilters(v: Record<string, any>) {
    if (Object.keys(v).length === 0) return false;
    return !Object.keys(v).every((key: string) => !isDefined(v[key]));
  }

  emitModelUpdate(payload: any) {
    this.$emit('update:modelValue', payload);
  }
}
