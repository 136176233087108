import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "object-threshold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NCheckbox = _resolveComponent("NCheckbox")!
  const _component_NConfidence = _resolveComponent("NConfidence")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_NCheckbox, {
      label: _ctx.$t('common.confidence_threshold'),
      modelValue: _ctx.showConfidenceThreshold,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showConfidenceThreshold) = $event))
    }, null, 8, ["label", "modelValue"]),
    (_ctx.showConfidenceThreshold)
      ? (_openBlock(), _createBlock(_component_NConfidence, {
          key: 0,
          class: "object-threshold__confidence",
          "model-value": _ctx.modelValue,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:modelValue', $event)))
        }, null, 8, ["model-value"]))
      : _createCommentVNode("", true)
  ]))
}