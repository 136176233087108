import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NSelect = _resolveComponent("NSelect")!

  return (_openBlock(), _createBlock(_component_NSelect, {
    "model-value": _ctx.modelValue,
    "search-items": _ctx.searchOptions,
    "load-items": _ctx.loadOptions,
    disabled: !_ctx.hasExternalVms,
    "onUpdate:modelValue": _ctx.updateValue
  }, null, 8, ["model-value", "search-items", "load-items", "disabled", "onUpdate:modelValue"]))
}