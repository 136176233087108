import { IFormLayout } from '@/uikit/forms/NForm.vue';
import CameraZones from '@/components/data-source/CameraZones.vue';

const layout: IFormLayout = [
  {
    classes: 'label-m n-form-pad-10',
    component: CameraZones,
    props: (item: any) => ({ item: item })
  }
];

export default layout;
