import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NSelect = _resolveComponent("NSelect")!

  return (_openBlock(), _createBlock(_component_NSelect, {
    items: _ctx.items,
    "model-value": _ctx.itemValue,
    "onUpdate:modelValue": _ctx.updateAdGroup,
    "key-field": "value",
    "clear-icon": ""
  }, null, 8, ["items", "model-value", "onUpdate:modelValue"]))
}