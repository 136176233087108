import { IFormLayout, IFormLayoutItem, IFormValidator } from '@/uikit/forms/NForm.vue';
import { ExternalVmsConfig, ExternalVmsOptions } from '@/pages/external-vms/types';
import { NInput } from '@/uikit';
import NTextareaAutosize from '@/uikit/textarea/NTextareaAutosize.vue';
import { getRequiredValidator, getStringLengthValidator } from '@/uikit/forms/validators';

function addServerValidator(item: IFormLayoutItem, errorsMap: Record<string, string>) {
  const message = item.path ? errorsMap[item.path] : '';
  const handler = () => !message;
  const validator = { message, handler };

  item.validators ? item.validators.push(validator) : (item.validators = [validator]);
  return item;
}

function getValidatorsByOptions(options: ExternalVmsOptions) {
  const validators: IFormValidator[] = [];
  if (options.required) validators.push(getRequiredValidator());
  if (options.min_length) validators.push(getStringLengthValidator({ min: options.min_length }));

  return validators;
}

function getComponentByOptions({ type }: ExternalVmsOptions) {
  switch (type) {
    case 'text':
    case 'password':
      return NInput;
    case 'textarea':
      return NTextareaAutosize;
    default:
      throw Error('Unknown VMS options type: ' + type);
  }
}

function getComponentPropsByOptions(options: ExternalVmsOptions) {
  const props: Record<string, any> = { disabled: !options.editable, modelValue: options.default || '' };

  switch (options.type) {
    case 'password':
      props.password = true;
      break;
    case 'textarea':
      props.minRows = 2;
      break;
  }

  return props;
}

function generateFormElementByOptions(options: ExternalVmsOptions): IFormLayoutItem {
  return {
    path: `options.${options.name}`,
    label: options.description,
    classes: 'label-m n-form-w-full n-form-pad-10',
    component: getComponentByOptions(options),
    props: getComponentPropsByOptions(options),
    validators: getValidatorsByOptions(options)
  };
}

export default function (item: ExternalVmsConfig | null, serverErrorsMap: Record<string, string>): IFormLayout {
  if (!item) return [];
  return item.options.map(generateFormElementByOptions).map((formItem) => addServerValidator(formItem, serverErrorsMap));
}
