
import { ExternalVms } from '@/api';
import NButton from '@/uikit/buttons/NButton.vue';
import { defineComponent, toRefs } from 'vue';
import { object } from 'vue-types';
import { VmsSidebarCamerasTabFilters, VmsSidebarCamerasTabList } from './components';
import { useVmsSidebarCamerasTabViewModel } from './setup';
import MultisidebarActions from '@/components/multisidebar/MultisidebarActions.vue';

export default defineComponent({
  name: 'VmsSidebarCamerasTab',
  components: {
    MultisidebarActions,
    NButton,
    VmsSidebarCamerasTabFilters,
    VmsSidebarCamerasTabList
  },
  props: {
    item: defineItemProp()
  },
  setup: (props) => {
    const { item } = toRefs(props);
    return useVmsSidebarCamerasTabViewModel(item);
  }
});

function defineItemProp() {
  return object<ExternalVms>().isRequired;
}
