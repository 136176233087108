import { AppContext, render } from 'vue';
import { CameraLookupAuthenticationWindow } from './index';
import type { CameraLookupAuthenticator, CameraLookupModel, CameraLookupModelId } from '../types';

export function createCameraLookupAuthenticationDialog(context: AppContext | null) {
  function renderCameraLookupAuthenticationWindow(authenticator: CameraLookupAuthenticator, id: CameraLookupModelId): Promise<CameraLookupModel> {
    return new Promise(function (resolve) {
      const container = document.body.appendChild(document.createElement('div'));
      const component = <CameraLookupAuthenticationWindow authenticator={authenticator} id={id} onClose={destroy} onDone={handleDoneEvent} />;
      render(((component.appContext = context), component), container);

      function destroy(): void {
        render(null, document.body.removeChild(container));
      }

      function handleDoneEvent(camera: CameraLookupModel): void {
        resolve(camera), destroy();
      }
    });
  }

  return renderCameraLookupAuthenticationWindow;
}
