import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "camera-zones-header" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButtonGroup = _resolveComponent("NButtonGroup")!
  const _component_NButton = _resolveComponent("NButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_NButtonGroup, {
      items: _ctx.items,
      "model-value": _ctx.activeTab,
      disabled: _ctx.disabled,
      "onUpdate:modelValue": _ctx.changeTab
    }, null, 8, ["items", "model-value", "disabled", "onUpdate:modelValue"]),
    _createElementVNode("div", null, [
      _createVNode(_component_NButton, {
        type: "secondary",
        icon: "eraser",
        disabled: _ctx.disabled,
        onAction: _cache[0] || (_cache[0] = ($event: any) => (_ctx.actionHandler('clear')))
      }, null, 8, ["disabled"])
    ])
  ]))
}