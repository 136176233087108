import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "vms-sidebar-cameras-tab-group-dialog__content" }
const _hoisted_2 = { class: "vms-sidebar-cameras-tab-group-dialog__footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VmsSidebarCamerasTabGroupDialogForm = _resolveComponent("VmsSidebarCamerasTabGroupDialogForm")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NModalWindow = _resolveComponent("NModalWindow")!

  return (_openBlock(), _createBlock(_component_NModalWindow, {
    "insert-into-body": false,
    onClose: _ctx.dispatchCloseEvent,
    "custom-size": "320px"
  }, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_VmsSidebarCamerasTabGroupDialogForm, {
          groups: _ctx.groups,
          ref: "form",
          id: _ctx.id,
          "onUpdate:id": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.id) = $event))
        }, null, 8, ["groups", "id"])
      ])
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_NButton, {
          label: _ctx.$t('common.column_add', 'f'),
          onClick: _ctx.submit
        }, null, 8, ["label", "onClick"])
      ])
    ]),
    _: 1
  }, 8, ["onClose"]))
}