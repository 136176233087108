import {
  BatchUploadService,
  BodyObjectRequest,
  CardsService,
  CarObjectRequest,
  DetectService,
  FaceObjectRequest,
  ObjectsService,
  Upload,
  UploadList
} from '@/api';
import { DetectResult, ObjectType } from '@/components/detection/types';
import { BatchUploaderFormItem } from '@/store/cards/types';
import { DataServiceRepository, viewModelRepository } from '@/api/common';
import { CardTypesMap, ObjectsMultiToSingle } from '@/store/application/data.assets';
import { dataAssetsModule } from '@/store/application/data.assets.module';
import { dataModule } from '@/store/data';
import { formatFileSize } from '@/common/filters';
import SimpleTimer from '@/components/video-player/utils/simple-timer';

export const defaultFormState: BatchUploaderFormItem = {
  filenameAsName: false,
  prefixName: '',
  suffixName: '',
  filenameAsComment: false,
  prefixComment: '',
  suffixComment: '',
  watch_lists: [1],
  photo_group: 'reject',
  parallel_upload: 5
};

const photoGroupAction: Record<any, any> = {
  ALL: 'all',
  BIGGEST: 'biggest',
  REJECT: 'reject'
};

export type BatchFileItem = {
  blob: File | null;
  src: string;
  fileName: string;
  size: string;
  cards: number[];
};
export type BatchStatistics = {
  statusCode?: string;
  errorCode?: string;
};

export type BatchItem = {
  started: boolean;
  finished: boolean;
  file: BatchFileItem;
  statistics: BatchStatistics;
  upload: () => Promise<void>;
};

const area = (bbox: Record<string, number>) => (bbox.bottom - bbox.top) * (bbox.right - bbox.left);
const biggest = (objects: any) => objects.reduce((acc: any, el: any) => (area(el.bbox) > area(acc.bbox) ? el : acc));

export class BatchUploaderModule {
  private batchUploaderFormItem: BatchUploaderFormItem = defaultFormState;
  private readonly objectType: string;
  private uploadList!: UploadList;

  loading = false;
  paused = false;
  finished = false;

  items: BatchItem[] = [];
  timer = new SimpleTimer();

  constructor(objectType: string) {
    this.objectType = objectType;
  }

  get dataService() {
    return DataServiceRepository;
  }

  get ObjectsFaces() {
    return viewModelRepository.getObjectsFacesItemViewModel();
  }

  get detectionObjectType() {
    return ObjectsMultiToSingle[this.objectType];
  }

  get cardType() {
    return dataAssetsModule.getCardTypeByObjectType(this.objectType);
  }

  get currentUserLogin() {
    return dataModule.currentUserModule.item?.name;
  }

  get nameForBatchUpload() {
    return this.currentUserLogin + '-' + new Date().getTime() * 1000 + Math.floor(Math.random() * 1000);
  }

  set formState(item: BatchUploaderFormItem) {
    this.batchUploaderFormItem = item;
  }

  get totalItems() {
    return this.items.length;
  }

  get totalUploaded() {
    return this.items.filter((item) => item.statistics.statusCode === 'success').length;
  }

  get totalErrors() {
    return this.items.filter((item) => !!item.statistics.errorCode).length;
  }

  get errorPercent() {
    return (this.totalErrors * 100) / this.totalItems;
  }

  get progress() {
    return (this.totalUploaded * 100) / this.totalItems;
  }

  get total() {
    return {
      uploaded: this.totalUploaded,
      errors: this.totalErrors,
      errorPercent: this.errorPercent,
      progress: this.progress
    };
  }

  addFiles(files: File[]) {
    this.finished = false;
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const _instance = this;
    files.forEach((file: File) => {
      const item: BatchItem = {
        started: false,
        finished: false,
        file: {
          blob: file,
          src: URL.createObjectURL(file),
          fileName: file!.name,
          size: formatFileSize(file!.size, true),
          cards: []
        },
        statistics: {
          statusCode: '',
          errorCode: ''
        },
        upload: async function () {
          try {
            this.started = true;
            const detectItem = this.file && (await _instance.detect(this));
            const filteredDetectItem = _instance.checkGroupPhoto(detectItem);
            await _instance.createCardsWithObjects(filteredDetectItem, this);
            this.statistics.statusCode = 'success';
          } catch (e) {
            this.statistics.errorCode = e.message;
          } finally {
            this.finished = true;
          }
        }
      };
      this.items.push(item);
    });
  }

  updateThreads() {
    let inWorkCount = 0;
    let allFinished = true;
    let threadCount = this.batchUploaderFormItem.parallel_upload || 0;
    this.items.forEach((item) => {
      if (item.started && !item.finished) {
        inWorkCount++;
      }
      allFinished = allFinished && item.finished;
    });

    if (inWorkCount < threadCount) {
      this.items
        .filter((item) => !item.started)
        .slice(0, threadCount - inWorkCount)
        .forEach((item) => {
          item.upload();
        });
    }
    if (allFinished) {
      this.loading = false;
      this.finished = true;
      this.timer.clear();
    }
  }

  async upload() {
    if (this.paused) {
      this.pause(false);
      return;
    }
    this.uploadList = await BatchUploadService.batchUploadCreate({ name: this.nameForBatchUpload });
    this.loading = true;
    this.timer.setInterval(this.updateThreads.bind(this), 500);
  }

  pause(enabled: boolean) {
    if (enabled) {
      this.timer.clear();
      this.loading = false;
      this.paused = true;
    } else {
      this.loading = true;
      this.paused = false;
      this.timer.setInterval(this.updateThreads.bind(this), 1000);
    }
  }

  async createCardsWithObjects(detect: any, item: any) {
    for (const detectItem of detect) {
      const detectIndex: number = detect.indexOf(detectItem);
      const card = await this.createCard(item.file.fileName, detectIndex);

      try {
        await this.addObjectToCard({ card: card!.id, source_photo: item.file.blob, detect_id: detectItem.id });
        item.file.cards.push(card!.id);
      } catch (e) {
        await this.deleteCard(card!.id);
      }
    }
  }

  async detect(item: BatchItem) {
    const file = item.file;
    let result = {
      objects: {}
    };
    let payload = { photo: file.blob, attributes: { [this.detectionObjectType]: {} } };
    try {
      result = (await DetectService.detectCreate(payload as any)) as DetectResult;
    } catch (e) {
      throw new Error('detection_error');
    }
    return Object.keys(result.objects).length !== 0 ? { ...result.objects } : null;
  }

  async createCard(fileName: string, detectIndex: number) {
    const requestBody = this.prepareFormData(fileName, detectIndex);
    switch (this.cardType) {
      case CardTypesMap.Humans:
        return CardsService.cardsHumansCreate(requestBody);
      case CardTypesMap.Cars:
        return CardsService.cardsCarsCreate(requestBody);
    }
  }

  async deleteCard(id: number) {
    switch (this.cardType) {
      case CardTypesMap.Humans:
        return CardsService.cardsHumansDestroy(id);
      case CardTypesMap.Cars:
        return CardsService.cardsCarsDestroy(id);
    }
  }

  async addObjectToCard(form: FaceObjectRequest | BodyObjectRequest | CarObjectRequest) {
    switch (this.detectionObjectType) {
      case ObjectType.Face:
        return ObjectsService.objectsFacesCreate(form);
      case ObjectType.Body:
        return ObjectsService.objectsBodiesCreate(form);
      case ObjectType.Car:
        return ObjectsService.objectsCarsCreate(form);
    }
  }

  private prepareFormData(fileName: string, detectIndex: number) {
    return {
      name: this.getName(fileName) + (detectIndex > 0 ? `_${detectIndex}` : ''),
      comment: this.getComment(fileName),
      watch_lists: this.batchUploaderFormItem.watch_lists,
      active: true,
      upload_list: this.uploadList.id
    };
  }

  private getPurifiedFileName(fileName: string) {
    return fileName.replace(/\.[^/.]+$/, '');
  }

  getComment(fileName: string) {
    return (
      this.batchUploaderFormItem.prefixComment +
      (this.batchUploaderFormItem.filenameAsComment ? this.getPurifiedFileName(fileName) : '') +
      this.batchUploaderFormItem.suffixComment
    );
  }

  getName(fileName: string) {
    return (
      this.batchUploaderFormItem.prefixName +
      (this.batchUploaderFormItem.filenameAsName ? this.getPurifiedFileName(fileName) : Math.round(Math.random() * 1e12).toString()) +
      this.batchUploaderFormItem.suffixName
    );
  }

  private checkGroupPhoto(detectionItem: any) {
    const { photo_group } = this.batchUploaderFormItem;
    const originalItems = detectionItem[this.detectionObjectType];
    const items = originalItems.filter(({ low_quality }: { low_quality: boolean }) => !low_quality);

    if (items.length === 0) {
      throw new Error('no_objects');
    } else if (photo_group === photoGroupAction.ALL || items.length === 1) {
      return items;
    } else if (photo_group === photoGroupAction.REJECT && items.length > 1) {
      throw new Error('detection_error');
    } else if (photo_group === photoGroupAction.BIGGEST) {
      return [biggest(items)];
    }
  }
}

export const batchUploaderModule = (type: string) => new BatchUploaderModule(type);
